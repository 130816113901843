import React from 'react';
import Colors from '../constants/ColorConstants';

const CopyIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}><path fillRule="evenodd" clipRule="evenodd" d="M6 2v.5H4.5v-1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1V11h.5V2H6zM3 14V5h7v9H3zM1.5 4.5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-10z" fill="currentColor"></path></svg>
);

export default CopyIcon