

import React from 'react';
import Colors from '../../constants/ColorConstants';

const USDTIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 50 50" {...props}>
        <g id="Icons-Crypto/Tether-USD-ERC20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Tether_Logo" transform="translate(0.184524, 0.275000)" fill-rule="nonzero">
                <circle id="Oval" fill="#26A17B" cx="24.6825397" cy="24.6825397" r="24.6825397" />
                <g id="Group" transform="translate(9.301587, 12.138889)" fill="#FFFFFF">
                    <path d="M18.0119048,14.8214286 L18.0119048,14.8174603 C17.8412698,14.8293651 16.9603175,14.8809524 15,14.8809524 C13.4325397,14.8809524 12.3333333,14.8373016 11.9444444,14.8174603 L11.9444444,14.8214286 C5.91666667,14.5555556 1.42063492,13.5079365 1.42063492,12.25 C1.42063492,10.9960317 5.92063492,9.94444444 11.9444444,9.67857143 L11.9444444,13.7777778 C12.3373016,13.8055556 13.468254,13.8730159 15.0277778,13.8730159 C16.9007937,13.8730159 17.8373016,13.7936508 18.0119048,13.7777778 L18.0119048,9.68253968 C24.0277778,9.95238095 28.5119048,11 28.5119048,12.2539683 C28.5119048,13.5079365 24.0238095,14.5555556 18.0119048,14.8253968 M18.0119048,9.25793651 L18.0119048,5.59126984 L26.4047619,5.59126984 L26.4047619,0 L3.55555556,0 L3.55555556,5.59126984 L11.9484127,5.59126984 L11.9484127,9.25793651 C5.12698413,9.57142857 0,10.9206349 0,12.5396825 C0,14.1587302 5.13095238,15.5079365 11.9484127,15.8253968 L11.9484127,27.5793651 L18.015873,27.5793651 L18.015873,15.8253968 C24.8253968,15.5119048 29.9444444,14.1626984 29.9444444,12.5436508 C29.9444444,10.9285714 24.8253968,9.57539683 18.015873,9.26190476" id="Shape" />
                </g>
            </g>
            <g id="ERC20" transform="translate(30.000000, 0.000000)">
                <circle id="Oval" fill="#2B2D31" cx="10" cy="10" r="10" />
                <path d="M14.1299449,10.8435355 L10.188772,16.25 L6.25,10.8434755 L10.188772,13.1102335 L14.1299449,10.8435355 Z M10.2278639,3.75 L14.1666667,10.1159434 L10.2278639,12.3837808 L10.213,12.369 L10.188772,12.3837808 L6.25,10.1159434 L10.1888028,3.75 L10.188,3.879 L10.2278639,3.75 Z" id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero" />
            </g>
        </g>
    </svg>
);

export default USDTIcon