// commonly used regular expressions.

export default {
  IFRAME               : /<iframe.+>.*<\/iframe>/,
  // IFRAME_SRC           : /<iframe.*src=['"]([\da-zA-Z/\-()':._!#$?&;=~,%]+)['"].*>.*<\/iframe>/,
  // DATA_HREF_SRC        : /data-href=['"]([\da-zA-Z/\-()':._!#$?&;=~,%]+\1)['"]/,
  SCRIPT               : /<script.+>.*<\/script>/,
  URL                  : /(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm,
  LINK_MARKDOWN        : /\[[^[]+\]\((.*?)\)/g,
  HTTP_PROTOCOL        : /http:\/\//,
  HTTPS_PROTOCOL       : /https:\/\//,
  CREATION_SUCCESS     : /CREATION_SUCCESS/,
  HASHTAG              : /^#([a-zA-Z.0-9]+)|(\s#([a-zA-Z.0-9]+))/,
  HASHTAGS             : /#([a-zA-Z.0-9]+)/g,
  LAST_CHAR_IS_HASHTAG : /^#(?!([a-zA-Z.0-9]+))|(\s#)$/,
  HASHTAG_AND_ONLY_NUM : /#[1-9]\d*\b/,
  LAST_CHAR_WHITESPACE : /\s$/igm,
  LAST_HASHTAG         : /\s(#\w+)\s$/ig,
  NO_COOKIE_YT_URL     : /youtube-nocookie/,
  CANONICAL_PAGE_NAME  : /\/([a-z]+)/,
  DOWNLOAD_URL_REGEX   : /\/files\/\d+\/download/,
  YOUTUBE_REGEX        : /^(http(s)?:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_]*)((&feature=(youtu.be))?)+$/i,
  MP4_URL              : /http?.*?\.mp4/,
  MOV_URL              : /http?.*?\.mov/,
  M4V_URL              : /http?.*?\.m4v/,
  EXPIRES              : /Expires/,
  EMAIL                : /\S+@\S+\.\S+/,
  WHITESPACE_IN_FRONT_OF_STRING : /(^\s*)/,
};
