
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from "query-string";
import PagePathConstants from '../constants/router/PagePathConstants';
import * as tagActionCreator from '../actions/tagActionCreator';
import * as orderActionCreator from '../actions/orderActionCreator';
import * as productActionCreator from '../actions/productActionCreator';
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors';
import RDSButton from '../components/common/RDSButton';
import OrderSuccessIcon from '../icons/OrderSuccessIcon';
import * as FBUtil from '../utils/FbUtil';


class OrderStatusPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            orderState: '',
            successfullyText: 'Thank you for your order! We will send an email to %email to follow up on the order status, please pay attention to answer it.',
            failedText: 'Fail to pay! please try again later.',
        }
        this.backToHome = this.backToHome.bind(this);
    }

    componentDidMount() {
        const { location, orderActions } = this.props;
        const queryParams = queryString.parse(location.search);
        const { state, email } = queryParams;
        this.setState({
            orderState: state,
            successfullyText: this.state.successfullyText.replaceAll('%email', email)
        }, () => {
            if (state === 'success') {
                // 购买成功
                const orderId = queryParams?.orderId;
                orderActions && orderActions.queryOrderDetail({ id: orderId })
                    .then(res => {
                        let orderInfo = res.data;

                        const goods = orderInfo?.goodsList;
                        let content_category_arr = [], content_ids_arr = [];
                        goods.map(item => {
                            content_category_arr.push(item.categorylist && item.categorylist.title);
                            content_ids_arr.push(item.id);
                            return item;
                        });
                        FBUtil.trackFBEvent('Purchase', {
                            content_ids: content_ids_arr.join(','),
                            content_name: goods[0]?.goods_title,
                            content_type: 'product',
                            contents: goods,
                            currency: 'USD',
                            num_items: goods?.length,
                            value: orderInfo?.accout
                        })
                    })
                    .catch((err) => {
                    })

                FBUtil.trackFBEvent('Purchase',)
            }
        })
    }

    backToHome() {
        const { history, location } = this.props;
        history && history.push({ pathname: PagePathConstants.HOME_PAGE, search: location.search });
    }

    render() {
        const { successfullyText, failedText, orderState } = this.state;
        return (
            <div className='tw-w-full tw-h-full SFNS-font tw-text-[#393c41] '>
                <div className='tw-w-full tw-h-full phone:tw-h-full'>
                    <div className='tw-mt-[30px] tw-w-[750px] tw-h-[500px] phone:tw-w-full tw-mx-auto phone:tw-px-[20px] phone:tw-w-full phone:tw-mt-[36px]'>
                        {orderState === 'success' && <div className='tw-flex tw-flex-col tw-justify-between tw-items-center'>
                            <OrderSuccessIcon size={120} color='#00142D' />
                            <div className='tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-center  phone:tw-text-left tw-mt-[30px]' dangerouslySetInnerHTML={{ __html: successfullyText }}></div>
                        </div>}
                        {orderState === 'cancel' && <div className='tw-flex tw-flex-col tw-justify-between tw-items-center'>
                            <OrderSuccessIcon size={120} color='#00142D' />
                            <div className='tw-mt-[30px] tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-light-red tw-text-center  phone:tw-text-left'>{failedText}</div>
                        </div>}
                        <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[20px]'>
                            <RDSButton
                                color="primary"
                                onClick={this.backToHome}>
                                <span>Back to Home</span>
                            </RDSButton>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActionCreator, dispatch),
        tagAcdtions: bindActionCreators(tagActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusPage);
