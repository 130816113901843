
export default {
  GLOBAL_ALERT                    : 'globalAlertReducer',
  ORDER                           : 'orderReducer',
  PRODUCT_DETAIL                  : 'productDetailReducer',
  ROUTER                          : 'routerReducer',
  USER                            : 'userReducer',
  BASE_CONFIG                     : 'baseConfigReducer',
  CATEGORY                        : 'categoryReducer',
  SHOPPING_CART                   : 'shoppingCartReducer',
  COUNTDOWN                       : 'countdownReducer',
};
