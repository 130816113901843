const MobileDetect = require('mobile-detect');

class MobileDetectUtil {
  static mobileDetect(){
  	return new MobileDetect(navigator.userAgent);
  }

  static isMobile() {
    return !!MobileDetectUtil.mobileDetect().mobile();
  }

  static isTablet() {
    return !!MobileDetectUtil.mobileDetect().tablet();
  }

  static getPlatform() {
    return MobileDetectUtil.isMobile() ? 'mobile-web' : 'web';
  }
}

export default MobileDetectUtil;
