import { Map } from 'immutable';
import LocalStorageConstants from '../constants/LocalStorageConstants';
class LocalStorageUtil {
  static setItem(key, value) {
    try {
      localStorage.setItem(key, value);
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
    }
  }

  static getItem(key) {
    try {
      return localStorage.getItem(key);
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
      return null;
    }
  }

  static removeItem(key) {
    try {
      return localStorage.removeItem(key);
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
    }
  }

  static clear() {
    try {
      localStorage.clear();
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
    }
  }

  static loadState() {
    try {
      // if (!this.getItem(LocalStorageConstants.CLEARED) || this.getItem(LocalStorageConstants.EDMODO_WEB_APP_STATE)) {
      //   this.clear();
      //   this.setItem(LocalStorageConstants.CLEARED, true);
      // }

      let newState = Map();
      newState = this.loadConfigMode(newState);

      return newState;
    } catch (err) {
      console.log('Local Storage Immutable Error:', err);
      return undefined;
    }
  };

  static loadConfigMode(newState) {
    const mode = this.getItem(LocalStorageConstants.CONFIG_MODE);

    if (mode) {
      setAPIBaseUrl(ConfigModeUtil.setConfigMode(mode).baseUrl);
      newState = newState.setIn([ReducerNameConstants.CONFIG_MODE_REDUCER, StateConstants.CURRENT_MODE], mode);
    }

    return newState;
  };


  static syncToLocalStorage(key, newState) {
    this.setItem(key, newState && JSON.stringify(newState.toJS()[key]));
    return newState;
  }
}

export default LocalStorageUtil;
