import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectRouterConfigReducer  = state => state.get(ReducerNameConstants.ROUTER);

export const selectRouterConfig = createSelector(
  [selectRouterConfigReducer],
  (routerReducer) => {
    const routerConfig = routerReducer && routerReducer.get(StoreStateConstants.ROUTER_CONFIG);
    return routerConfig?.toJS();
  },
);