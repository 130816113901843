
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors';
import { EDSRichTextRenderer } from '../components/common/rich_text/index';

class ProvacyPolicyPage extends Component {
    constructor(props, context) {
        super(props, context);
    }


    render() {
        const { baseConfig } = this.props;
        return (
            <div className='tw-w-full SFNS-font tw-text-[#393c41] tw-flex tw-flex-col tw-items-center'>
                <div className='tw-w-full tw-h-[474px] tw-mb-[40px] tw-pt-[220px] tw-pb-[100px] tw-text-[78px] phone:tw-text-[36px] phone:tw-h-[167px] phone:tw-pt-[75px] phone:tw-pb-[15px] tw-text-white tw-text-center tw-bg-[#0fc6b3]' >
                    Privacy Policy
                </div>
                <div className='tw-w-[80%] tw-mb-[100px] tw-h-full phone:tw-h-full SFNS-font tw-leading-[40px]'>
                    <EDSRichTextRenderer overflowAction={'none'} source={baseConfig && baseConfig.provacyPolicy} enableLatex />
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state)
    };
}

export default connect(mapStateToProps, null)(ProvacyPolicyPage);
