
import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Checkbox from '../components/common/Checkbox';
import ReactFlagsSelect from 'react-flags-select';
import ReactPhoneInput from 'react-telephone-input/lib/withStyles';
import { Input } from 'reactstrap';
import * as UserLocationUtil from '../utils/UserLocationUtil';
import I18N from '../i18n';
import flagImg from '../images/flags.png';
import ShieldIcon from '../icons/ShieldIcon';
import ShoppingCartIcon from '../icons/ShoppingCartIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import VisaIcon from '../icons/payment/visaIcon';
import MasterCardIcon from '../icons/payment/masterCardIcon';
import AmericanExpressIcon from '../icons/payment/americanExpressIcon';
import DiscoverCardIcon from '../icons/payment/discoverCardIcon';
import JcbCardIcon from '../icons/payment/jcbCardIcon';

import BitcoinIcon from '../icons/payment/bitcoinIcon';
import EthIcon from '../icons/payment/ethIcon';
import USDTIcon from '../icons/payment/usdtIcon';
import BNBIcon from '../icons/payment/bnbIcon';
import ETCIcon from '../icons/payment/etcIcon';
import DogeIcon from '../icons/payment/dogeIcon';
import TrxIcon from '../icons/payment/trxIcon';



import CustomButton from '../components/common/button';
import RDSRadioButton from '../components/common/RDSRadioButton';
import MobileDetectUtil from '../utils/MobileDetectUtil';
import PagePathConstants from '../constants/router/PagePathConstants';
import classNames from 'classnames';
import ProductHelper from '../helpers/ProductHelper';
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors';
import * as UserSelectors from '../selectors/UserSelectors';
import * as ShoppingCartSelectors from '../selectors/ShoppingCartSelectors';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';
import { LOGIN_CONSTANTS } from '../constants/store/StoreStateConstants';
import RDSButton from '../components/common/RDSButton';
import * as globalAlertActionCreator from '../actions/globalAlertActionCreator';
import * as orderActionCreator from '../actions/orderActionCreator';
import GlobalAlertConstants from '../constants/GlobalAlertConstants';
import * as authActionCreator from '../actions/authActionCreator';
import * as FormValidationsUtil from '../utils/FormValidationsUtil';

import { setAPIAuthorizationToken } from '../apis/API';
import * as FBUtil from '../utils/FbUtil';
import tracker from '../tracker';

class CheckoutPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            // isRegisterWithEmail: true,
            isSaveAddress: false,
            selectedCountryCode: 'US',
            email: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            phone: '',
            state: '',
            city: '',
            address: '',
            zipCode: '',
            notes: '',
            couponCode: '',
            courier: 0,
            showShoppingCartInfoModal: false,
            isPaymenting: false,
            isLoading: false,
            isFromCart: false,
            paymentType: 0,
        }

        this.toggleRegisterWithEmail = this.toggleRegisterWithEmail.bind(this);
        this.toggleSaveAddress = this.toggleSaveAddress.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.renderSubtotalComponent = this.renderSubtotalComponent.bind(this);
        this.renderPaymentBtn = this.renderPaymentBtn.bind(this);
        this.toggleCartInfoModal = this.toggleCartInfoModal.bind(this);
        this.routeToLoginPage = this.routeToLoginPage.bind(this);
        this.handleApplyCouponCode = this.handleApplyCouponCode.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleChangeCourier = this.handleChangeCourier.bind(this);
        this.togglePaymentStatue = this.togglePaymentStatue.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleChangePaymentType = this.handleChangePaymentType.bind(this);
        this.renderPaymentType = this.renderPaymentType.bind(this);
        this.formatGoodsArr = this.formatGoodsArr.bind(this);
    }
    // queryPaymentUrlInterval = null;

    componentDidMount() {
        const { location, shoppingCartArr } = this.props;
        const queryParams = queryString.parse(location.search);
        const { fromCart } = queryParams;
        this.setState({
            isFromCart: !!fromCart
        }, () => {
            let goods = [];
            if (this.state.isFromCart) {
                goods = shoppingCartArr;
            } else {
                const checkoutData = ProductHelper.getCheckoutProductData();
                goods = checkoutData;
            }

            let content_category_arr = [], content_ids_arr = [];
            goods.map(item => {
                content_category_arr.push(item.categorylist && item.categorylist.title);
                content_ids_arr.push(item.id);
                return item;
            });

            FBUtil.trackFBEvent('InitiateCheckout', {
                content_category: content_category_arr.join(','),
                content_ids: content_ids_arr.join(','),
                contents: goods,
                currency: 'USD',
                num_items: goods?.length,
                value: ProductHelper.getCheckoutAmount()
            });
        });
        tracker.trackCheckoutPage();
    }

    // getPaypalRequest(url) {
    //     const that = this;
    //     const {history, location} = this.props;
    //     this.toggleLoading();

    //     const PAYMENT_REQUEST_INTERVAL = 8000;
    //     const queryAPI = () => {
    //         axios.get(url)
    //             .then(res => {
    //                 if (res.status === 200 && res.data.code === 1) {
    //                     that.toggleLoading();
    //                     window.clearInterval(this.queryPaymentUrlInterval);
    //                     setTimeout(() => {
    //                         history && history.replace({pathname: PagePathConstants.HOME_PAGE, search: location.search});
    //                     }, 5000);
    //                     const paymentUrl = res.data.data.url;
    //                     window.open(paymentUrl, '__blank');
    //                 }
    //             })
    //             .catch(() => { })
    //     }
    //     queryAPI();
    //     this.queryPaymentUrlInterval = setInterval(queryAPI, PAYMENT_REQUEST_INTERVAL);
    // }

    togglePaymentStatue() {
        this.setState({ isPaymenting: !this.state.isPaymenting });
    }

    // toggleLoading() {
    //     this.setState({ isLoading: !this.state.isLoading });
    // }

    handleApplyCouponCode() {
        const { baseConfig } = this.props;
        const { couponCode } = this.state;
        this.setState({ applyCoupon: baseConfig.couponCode === couponCode.trim() });
        if (baseConfig.couponCode !== couponCode.trim()) {
            const { globalAlertActions } = this.props;
            globalAlertActions && globalAlertActions.dispatchGlobalAlert('invalid-coupon-code', 'Invalid coupon code', GlobalAlertConstants.ERROR);
        }
    }

    toggleRegisterWithEmail() {
        this.setState({ isRegisterWithEmail: !this.state.isRegisterWithEmail });
    }

    toggleSaveAddress() {
        this.setState({ isSaveAddress: !this.state.isSaveAddress });
    }

    onSelectCountry(countryCode) {
        this.setState({ selectedCountryCode: countryCode });
    }

    handleChangeCourier(index) {
        this.setState({ courier: index });
    }

    renderSubtotalComponent() {
        const { couponCode, applyCoupon, courier,  isPaymenting, email, selectedCountryCode, firstName, lastName, phone, state, city, address, zipCode, isFromCart } = this.state;
        const { baseConfig, shoppingCartArr } = this.props;
        const isMobile = MobileDetectUtil.isMobile();
        let { subtotal, discount } = ProductHelper.calcCheckoutAmount(isFromCart);
        let couponDiscount = parseFloat(parseFloat(subtotal) * parseFloat((parseFloat(baseConfig.coupon) / 100))).toFixed(2);
        const subtotalWithCoupon = applyCoupon ? parseFloat(parseFloat(subtotal) - parseFloat(couponDiscount)).toFixed(2) : subtotal;
        const discountWithCoupon = applyCoupon ? parseFloat(parseFloat(discount) + parseFloat(couponDiscount)).toFixed(2) : discount;
        let freeShippingAmount = baseConfig && parseFloat(baseConfig.freeShippingAmount);
        let isFreeShipping = (subtotal >= freeShippingAmount);
        const normalShippingAmount = (baseConfig.shipArr && baseConfig.shipArr[courier].price);
        const finallyAmount = isFreeShipping ? subtotalWithCoupon : parseFloat(parseFloat(subtotalWithCoupon) + parseFloat(normalShippingAmount)).toFixed(2)
        ProductHelper.setCheckoutAmount(finallyAmount);
        const disabledPaymentBtn = (email.trim() === '' || selectedCountryCode === '' || selectedCountryCode === null || firstName.trim() === '' || lastName.trim() === '' || phone.trim() === '' || state.trim() === '' || city.trim() === '' || address.trim() === '' || zipCode.trim() === '' || isPaymenting)

        const displayProductArr = isFromCart ? shoppingCartArr : ProductHelper.getCheckoutProductData();

        return (<div className='tw-w-1/2 phone:tw-w-full tw-h-full '>
            <div className='tw-w-[430px] phone:tw-w-full tw-mx-[40px] phone:tw-mx-0 phone:tw-pl-[20px] phone:tw-pr-[20px] phone:tw-pt-[20px] tw-pt-[60px] phone:tw-bg-white'>
                {/* 暂时隐藏优惠券 */}
                {/* <div className='tw-w-full tw-bg-white tw-rounded-[15px] tw-px-[20px] tw-py-[20px] tw-mb-[20px]'>
                    <div className='tw-flex tw-justify-between tw-items-center tw-mb-[15px]'>
                        <Input
                            className="tw-w-[70%] tw-h-[42px] tw-border-border-gray placeholder:tw-text-[12px] placeholder:tw-text-body-text-4"
                            placeholder="Please enter your coupon code"
                            required name="text" type="text"
                            value={couponCode}
                            onChange={(e) => this.handleTextChange(e, 'couponCode')}
                        />
                        <RDSButton rectangle className="tw-w-[150px] tw-h-[50px] tw-flex tw-justify-center tw-text-white tw-rounded-btn" onClick={this.handleApplyCouponCode} disabled={couponCode.trim() === ''}>
                            Apply
                        </RDSButton>
                    </div>
                    {applyCoupon && <div className='tw-text-light-red tw-mb-[15px]'>Congratulations! the coupon saved <span>${couponDiscount}</span></div>}
                </div> */}
                <div className='tw-relative tw-border-b'>
                    {displayProductArr.map(item => {
                        const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);
                        return (<div className='tw-flex tw-justify-between tw-items-start tw-mb-[30px]'>
                            <div className='tw-flex tw-items-center'>
                                <div className='tw-w-[64px] tw-h-[64px] tw-rounded-[10px] tw-relative tw-flex-shrink-0' style={{ backgroundImage: `url(${img})`, backgroundSize: '100%', backgroundPosition: 'center' }}>
                                    <span className='tw-absolute tw-right-[-10px] tw-top-[-10px] tw-w-[25px] tw-h-[25px] tw-px-[2px] tw-py-[2px] tw-rounded-full tw-bg-[rgb(153,153,153)] tw-flex tw-justify-center tw-items-center tw-text-white tw-text-[12px]'>{item.quantity}</span>
                                </div>
                                <div className='tw-ml-[15px] tw-h-[64px] tw-flex tw-flex-col tw-justify-between tw-items-start tw-text-[14px] phone:tw-text-[12px] !tw-text-[#757575] phone:tw-w-[170px] '>
                                    <div className='tw-font-[600] tw-text-black phone:tw-w-full tw-line-clamp-2 phone:tw-line-clamp-2'>{item.title}</div>
                                    <div className='phone:tw-w-full phone:tw-line-clamp-1'>
                                        {item.skulist && item.skulist.map(sku => {
                                            return <div className='tw-mr-[20px]'><span className='tw-font-[600] tw-mr-[5px]'>{sku.title}:</span>{sku.selectedValue.content}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='tw-flex tw-flex-col tw-justify-start tw-items-center'>
                                <div className='tw-font-[600] tw-text-[16px] phone:tw-text-[14px] tw-text-black'>${item.currentPrice}</div>
                                <div className='tw-font-[600] tw-text-[12px] tw-line-through tw-text-[#757575]'>${item.originalPrice}</div>
                            </div>
                        </div>)
                    })}

                </div>

                <div className='tw-relative tw-py-[30px] tw-border-b tw-text-[#888888] tw-text-[14px]'>
                    {baseConfig && baseConfig.shipArr && baseConfig.shipArr.map((item, index) => {
                        return (<div className='tw-flex tw-items-center tw-mt-[15px]'>
                            <RDSRadioButton id={index} checked={courier === index} onClick={this.handleChangeCourier} />
                            <div className='tw-ml-[15px]'>{`${item.title} - $${item.price}`} </div>
                        </div>)
                    })}
                </div>

                <div className='tw-pt-[20px] tw-text-[#888888] tw-text-[14px] tw-border-b'>
                    <div className='tw-flex tw-justify-between tw-items-center tw-mb-[10px]'>
                        <div>Subtotal:</div>
                        <div className=''>${subtotalWithCoupon}</div>
                    </div>
                    <div className='tw-flex tw-justify-between tw-items-center tw-mb-[10px]'>
                        <div>Discount total:</div>
                        <div className=''>${discountWithCoupon}</div>
                    </div>
                    <div className='tw-flex tw-justify-between tw-items-center tw-mb-[20px]'>
                        <div>Shipping:</div>
                        <div className=''>${isFreeShipping ? 0.00 : normalShippingAmount}</div>
                    </div>
                </div>
                <div className='tw-flex tw-justify-between tw-items-center tw-pt-[20px]'>
                    <div className='tw-text-[#333333]'>Total price:</div>
                    <div className='tw-text-[#212121] tw-text-[24px]'>${finallyAmount}</div>
                </div>

                <div className='tw-mt-[20px] tw-flex tw-justify-center'>
                    <img src='https://img.staticdj.com/c78070176f959071323764a31f717795.jpg' className='tw-w-[404px] tw-h-[165px]' alt='payment-img' />
                </div>
                {/* {!isMobile && this.renderPaymentType()} */}

                {!isMobile && this.renderPaymentBtn(disabledPaymentBtn)}
            </div>
        </div>)
    }

    renderPaymentType() {
        const { paymentType } = this.state;
        return (
            <div className='tw-mt-[20px]'>
                <div className='tw-text-[16px] tw-font-[600]'>Select a payment method</div>
                <div className='tw-flex tw-items-center tw-mt-[15px]'>
                    <RDSRadioButton id={0} checked={paymentType === 0} onClick={this.handleChangePaymentType} />
                    <div className='tw-ml-[15px] tw-flex tw-items-center'>
                        <VisaIcon size={48} /> <MasterCardIcon size={48} className="tw-ml-[15px]" /> <AmericanExpressIcon size={48} className="tw-ml-[15px]" /> <JcbCardIcon size={48} className="tw-ml-[15px]" /> <DiscoverCardIcon size={48} className="tw-ml-[15px]" />
                    </div>
                </div>
                <div className='tw-flex tw-items-center tw-mt-[15px]'>
                    <RDSRadioButton id={3} checked={paymentType === 3} onClick={this.handleChangePaymentType} />
                    <div className='tw-ml-[15px] tw-flex tw-items-center'>
                        <BitcoinIcon size={36} /> <EthIcon size={36} className="tw-ml-[15px]" /> <USDTIcon size={36} className="tw-ml-[15px]" /> <BNBIcon size={36} className="tw-ml-[15px]" /> <ETCIcon size={36} className="tw-ml-[15px]" /> <DogeIcon size={36} className="tw-ml-[15px]" /> <TrxIcon size={36} className="tw-ml-[15px]" />
                    </div>
                </div>

            </div>
        )
    }


    renderPaymentBtn(disabled) {
        const { isPaymenting } = this.state;
        return (<div className='tw-mt-[20px]'>
            <RDSButton loading={isPaymenting} rectangle className="tw-w-full tw-h-[50px] tw-flex tw-justify-center tw-text-white tw-rounded-btn SFNS-font" onClick={this.handlePayment} disabled={disabled}>
                Pay by Credit Card
            </RDSButton>
        </div>)
    }

    handleChangePaymentType(paymentType) {
        this.setState({ paymentType });
    }

    formatGoodsArr(arr) {
        return arr.map(item => {
            let skulist = item.skulist.map(sku => {
                return Object.assign({}, sku.selectedValue, { title: sku.title });
            });
            return {
                id: item.id,
                num: item.quantity,
                skulist,
            }
        });
    }

    handlePayment() {
        const { globalAlertActions, loginStatus, authActions, orderActions, shoppingCartArr, location } = this.props;
        const { email, selectedCountryCode, firstName, lastName, phone, state, city, address, zipCode, notes, password, confirmPassword, courier, applyCoupon, isRegisterWithEmail, paymentType } = this.state;
        if (!FormValidationsUtil.isValidEmail(email.trim())) {
            globalAlertActions && globalAlertActions.dispatchGlobalAlert('invalid-email', 'Invalid email', GlobalAlertConstants.ERROR);
            return;
        }
        // if (loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && !isRegisterWithEmail) {
        //     globalAlertActions && globalAlertActions.dispatchGlobalAlert('checkout-with-logged-out', 'You are not logged in yet, please check the registration button and fill in the password', GlobalAlertConstants.ERROR);
        //     return;
        // }
        // if (loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && (FormValidationsUtil.hasContainSpecialCharacters(password.trim()) || !FormValidationsUtil.isValidPassword(password.trim()))) {
        //     globalAlertActions && globalAlertActions.dispatchGlobalAlert('invalid-password', 'The password contains a minimum of 6 characters and a maximum of 19 characters, and special characters such as emojis are not allowed', GlobalAlertConstants.ERROR);
        //     return;
        // }
        // if (loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && (password.trim() !== confirmPassword)) {
        //     globalAlertActions && globalAlertActions.dispatchGlobalAlert('invalid-confirm-password', 'The two passwords are inconsistent', GlobalAlertConstants.ERROR);
        //     return;
        // }
        if (!FormValidationsUtil.isValidPhoneNumber(phone.trim())) {
            globalAlertActions && globalAlertActions.dispatchGlobalAlert('invalid-phone', 'Invalid phone number', GlobalAlertConstants.ERROR);
            return;
        }

        let formattedPhone = phone.replaceAll(' ', '-').trim();


        let goods = [], completeGoods;
        if (this.state.isFromCart) {
            completeGoods = shoppingCartArr;
            goods = this.formatGoodsArr(shoppingCartArr);
        } else {
            const checkoutData = ProductHelper.getCheckoutProductData();
            completeGoods = checkoutData;
            goods = this.formatGoodsArr(checkoutData);
        }

        let content_category_arr = [], content_ids_arr = [], num = 0;
        completeGoods.map(item => {
            content_category_arr.push(item.categorylist && item.categorylist.title);
            content_ids_arr.push(item.id);
            num += parseInt(item.quantity);
            return item;
        });

        const queryParams = queryString.parse(location.search);
        const successUrl = `${PagePathConstants.ORDER_CALLBACK}?state=success&email=${email}${queryParams.ad !== '' ? `&ad=${queryParams.ad}` : ''}`;
        const cancelUrl = `${PagePathConstants.ORDER_CALLBACK}?state=cancel&email=${email}${queryParams.ad !== '' ? `&ad=${queryParams.ad}` : ''}`;
        let params = {
            goods: JSON.stringify(goods),
            address,
            last_name: lastName,
            first_name: firstName,
            city,
            code: zipCode,
            country: selectedCountryCode,
            success_url: successUrl,
            cancel_url: cancelUrl,
            telephone: formattedPhone,
            province: state,
            notes,
            courier,
            is_coupon: (applyCoupon ? 1 : 0),
            email,
            type: paymentType,
        }

        if (queryParams.ad && queryParams.ad !== '') {
            params['ad'] = queryParams.ad;
        }

        
        FBUtil.trackFBEvent('AddPaymentInfo', {
            content_category: content_category_arr.join(','),
            content_ids: content_ids_arr.join(','),
            contents: completeGoods,
            currency: 'USD',
            value: ProductHelper.getCheckoutAmount()
        });
        tracker.addPaymentInfo({ contents: params, currency: 'USD', value: ProductHelper.getCheckoutAmount() })

        this.togglePaymentStatue();
        const submitOrder = () => {
            orderActions.submitProductOrder(params)
                .then((data) => {
                    this.togglePaymentStatue();
                    if (data && data.data.url) {
                        // 原有的st支付
                        console.log(data.data.url);
                        window.location.href = data.data.url;

                        // https://www.rtfdo.xyz/order//pay.php?data=L1a9gtIsmkCHEPUwE36dETh2MWpOWGZsU3RPSlBpQ3VYSkVJY1V1TEhIdUJIUUp6a011aVU0enI4d3FwTHI3WTc5OVlhbHE5VS9EUUljWWFBaFlLV1NRTzlwb1NIam5UeDRWbWt0N3dmeDdqclBkZ0dHa0FZUGliMEQzcUR0bmVrakk5aC9MYkhVLytpUzJsYUJJenhwM0JZaDk3Vjl5bFpCOWJwUHJIVjlZZjhRVW40NzV1ZDdmSVlxRGp4cXRURStNVmxEWmhpVStxUjRyZEtUbmJ1VVRYMEJmUFBSeEtMVTdrazg5TFNsTE9DMVdweG9rR1dvdUh4cjJyVFlLZlRsR0ZSOWRMSVpYZFRhN1o5Wk9JRFdYVEExOXNGb0I3VGFFODZUaDBhUURhMXlxMEp6RDIrVFJGenhBWGFVWVpIRzJCcDFDVVZOVHE2dE5vSVFoczNpUC9xcjNNREduclFJOFptSnBLL1ZWYWRtbjhQMWl2T2R4ZjFLek9DNzlCWHpUTUhwaFdmODlCNmNhWkh1SXIrRU5OckNEQjRkeHRjS2JMbU9PWUJidmU3WHlOb0RCTUFlbHJjRVBXZ3YwRlRUTUUyaWp1MDc1L1FkZG9Ed0hsc3RvWGttZ2hmNTFiVmpmb0MzTEIyZVpxdWZieDBhc2lPV0Ftc1VNRytpTkdEb1RzN09jN3FQT3dxR1ZN&orderNo=14492068937&amount=178.00

                        //Tk支付
                        // this.getPaypalRequest(data.data.url);
                    }
                })
                .catch((err) => {
                    this.togglePaymentStatue();
                    globalAlertActions && globalAlertActions.dispatchGlobalAlert('order_failed_tip', err, GlobalAlertConstants.ERROR);
                });
        }
        // if (loginStatus === LOGIN_CONSTANTS.LOGGED_OUT) {
        //     authActions && authActions.registerUser({
        //         email,
        //         password,
        //         firstName,
        //         lastName,
        //     }, false)
        //         .then((res) => {
        //             let token = res.data.userinfo.token;
        //             setAPIAuthorizationToken(token)
        //             submitOrder();

        //         })
        //         .catch((err) => {
        //             this.togglePaymentStatue();
        //             globalAlertActions && globalAlertActions.dispatchGlobalAlert('registered_failed_tip', err, GlobalAlertConstants.ERROR);
        //         });
        // } else {
        //     submitOrder();
        // }
        submitOrder();

    }

    toggleCartInfoModal() {
        this.setState({ showShoppingCartInfoModal: !this.state.showShoppingCartInfoModal });
    }

    routeToLoginPage() {
        const { history, location } = this.props;
        history && history.push({ pathname: PagePathConstants.LOGIN_PAGE, search: location.search });
    }

    handlePhoneChange(phone) {
        this.setState({ phone });
    }

    handleTextChange(e, property) {
        const value = e && e.target.value;
        if (property === 'couponCode') {
            const regx = new RegExp(/^[0-9]{1,6}$/);
            if (regx.test(value) || value === '') {
                this.setState({ [property]: value, applyCoupon: false });
            }
        } else {
            this.setState({ [property]: value, applyCoupon: false });
        }
    }

    render() {
        const { isRegisterWithEmail, isSaveAddress, selectedCountryCode, email, password, confirmPassword, firstName, lastName, phone, state, city, address, zipCode, notes, showShoppingCartInfoModal, isPaymenting, applyCoupon, courier, isLoading } = this.state;
        const isMobile = MobileDetectUtil.isMobile();
        const { subtotal, discount } = ProductHelper.calcCheckoutAmount();
        const { loginStatus, baseConfig } = this.props;
        const disabledPaymentBtn = (email.trim() === '' || selectedCountryCode === '' || selectedCountryCode === null || firstName.trim() === '' || lastName.trim() === '' || phone.trim() === '' || state.trim() === '' || city.trim() === '' || address.trim() === '' || zipCode.trim() === '' || isPaymenting)
        let couponDiscount = parseFloat(parseFloat(subtotal) * parseFloat((parseFloat(baseConfig.coupon) / 100))).toFixed(2);
        const subtotalWithCoupon = applyCoupon ? parseFloat(parseFloat(subtotal) - parseFloat(couponDiscount)).toFixed(2) : subtotal;
        const discountWithCoupon = applyCoupon ? parseFloat(parseFloat(discount) + parseFloat(couponDiscount)).toFixed(2) : discount;
        let freeShippingAmount = baseConfig && parseFloat(baseConfig.freeShippingAmount);
        let isFreeShipping = (subtotal >= freeShippingAmount);
        const normalShippingAmount = (baseConfig.shipArr && baseConfig.shipArr[courier].price);
        const finallyAmount = isFreeShipping ? subtotalWithCoupon : parseFloat(parseFloat(subtotalWithCoupon) + parseFloat(normalShippingAmount)).toFixed(2)
        return (
            <div className='tw-w-full w-min-h-full tw-flex tw-justify-start tw-items-start tw-text-body-text-1 SFNS-font  tw-bg-[rgb(250,250,250)]'>
                {/* {isLoading && <Loading />} */}
                {isMobile && <div className='tw-fixed tw-w-full  tw-top-0 tw-left-0 tw-z-[100]'>
                    <div className='tw-flex tw-justify-between tw-items-center tw-px-[20px] tw-py-[15px] tw-bg-[rgb(250,250,250)] tw-border tw-h-[77px] tw-overflow-hidden'>
                        <div className='tw-flex tw-items-center' onClick={this.toggleCartInfoModal}>
                            <ShoppingCartIcon color='#2277CB' size={28} />
                            <div className='tw-text-[#2277CB] tw-ml-[10px]'>Shopping cart</div>
                            <div className='tw-ml-[5px]'><ArrowDownIcon size={15} color='#2277CB' style={{ transform: showShoppingCartInfoModal ? 'rotate(180deg)' : 'none' }} /> </div>
                        </div>
                        <div className='tw-flex tw-flex-col tw-justifty-center tw-items-center'>
                            <div className='tw-line-through tw-text-[14px] tw-text-[#888888]'>${discountWithCoupon}</div>
                            <div className='tw-text-[16px] tw-text-[#2277CB]'>${finallyAmount}</div>
                        </div>
                    </div>
                    <div className={classNames('tw-w-full tw-h-[calc(100%-77px)] tw-fixed tw-top-[77px] tw-left-0 tw-bg-[rgba(0,0,0,0.5)] tw-hidden', { '!tw-block': showShoppingCartInfoModal })}>
                        <div className={classNames('tw-w-full tw-h-[80%] tw-overflow-y-scroll', { 'cart-info-model-show': showShoppingCartInfoModal })}>
                            {this.renderSubtotalComponent()}
                        </div>
                    </div>
                </div>}
                <div className='tw-w-1/2 phone:tw-w-full tw-pb-[200px] phone:tw-pb-[100px] tw-bg-white tw-border tw-flex tw-justify-end phone:tw-bg-white'>
                    <div className='tw-w-[572px] phone:tw-w-full tw-mx-[64px] phone:tw-mx-[16px] tw-pt-[60px] phone:tw-pt-[90px] '>
                        <div>
                            <div className='tw-w-[44px] tw-h-[44px]' style={{ backgroundImage: 'url("https://img.staticdj.com/efdd467da57954347828ba1ba2b7d1f0.jpg")', backgroundSize: '100%', backgroundPosition: 'center' }}></div>
                            {loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && <React.Fragment>
                                <div className='tw-flex tw-justify-between tw-items-center tw-text-emphasize-title'>
                                    <div className='tw-text-[16px] tw-font-[600]'>Contact information</div>
                                    {/* <div className='tw-text-[12px] tw-mb-[5px]'>Already have an account? <span className='tw-text-[#2277C8]' onClick={this.routeToLoginPage}>Login</span></div> */}
                                </div>
                                {/* <div className='tw-mt-[10px]'>
                                    <div className='tw-flex tw-items-center'>
                                        <Checkbox
                                            checked={isRegisterWithEmail}
                                            onChange={this.toggleRegisterWithEmail}
                                            label={null}
                                        />
                                        <div className="hs-eula-tip tw-text-[#93989D] tw-text-[12px] tw-leading-[25px]">Register your email to obtain activity coupons and track the logistics of your orders</div></div>
                                </div> */}
                            </React.Fragment>}

                            <div className='tw-mt-[10px]'>
                                <div className=''>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>Email</div>
                                    <Input className='tw-w-full tw-h-[48px] tw-bg-[rgb(232,242,254)] tw-border-border-gray placeholder:tw-text-[14px]'
                                        value={email}
                                        onChange={(e) => this.handleTextChange(e, 'email')}
                                        placeholder='Enter your email'

                                    />
                                </div>
                            </div>
                            {/* {loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && isRegisterWithEmail && <React.Fragment>
                                <div className='tw-mt-[10px]'>
                                    <div className=''>
                                        <div className='tw-text-[12px] tw-mb-[5px]'>Password</div>
                                        <Input className='tw-w-full tw-h-[48px] tw-bg-[rgb(232,242,254)] tw-border-border-gray placeholder:tw-text-[14px]'
                                            value={password}
                                            onChange={(e) => this.handleTextChange(e, 'password')}
                                            placeholder='Enter your password'
                                        />
                                    </div>
                                </div>
                                <div className='tw-mt-[10px]'>
                                    <div className=''>
                                        <div className='tw-text-[12px] tw-mb-[5px]'>Confrim password</div>
                                        <Input className='tw-w-full tw-h-[48px] tw-bg-[rgb(232,242,254)] tw-border-border-gray placeholder:tw-text-[14px]'
                                            value={confirmPassword}
                                            onChange={(e) => this.handleTextChange(e, 'confirmPassword')}
                                            placeholder='Enter your password again'
                                        />
                                    </div>
                                </div>
                            </React.Fragment>} */}
                        </div>

                        <div className='tw-mt-[30px] tw-text-body-text-1'>
                            <div className='tw-text-[16px] tw-text-emphasize-title tw-font-[600]'>Delivery address</div>
                            <div className='tw-mt-[10px]'>
                                <div className='tw-mt-[5px]'>
                                    <div className='tw-text-[12px] '>Country or Location</div>
                                    <ReactFlagsSelect
                                        className='tw-w-full tw-mt-[5px]'
                                        selected={selectedCountryCode}
                                        placeholder="Select Country or Location"
                                        onSelect={this.onSelectCountry}
                                        searchable
                                        searchPlaceholder="Search Country or location"
                                        customLabels={UserLocationUtil.getCountryNames(I18N.getText)}
                                    />
                                </div>
                            </div>

                            <div className='tw-mt-[10px] tw-flex tw-justify-between tw-items-center phone:tw-flex-wrap'>
                                <div className=' tw-w-[48%] phone:tw-w-full'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>First name</div>
                                    <Input className='tw-w-full tw-h-[48px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='First Name' value={firstName} onChange={(e) => this.handleTextChange(e, 'firstName')} />
                                </div>
                                <div className=' tw-w-[48%] phone:tw-w-full phone:tw-mt-[10px]'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>Last name</div>
                                    <Input className='tw-w-full tw-h-[48px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='Last Name' value={lastName} onChange={(e) => this.handleTextChange(e, 'lastName')} />
                                </div>
                            </div>

                            <div className='tw-mt-[10px] tw-flex tw-justify-between tw-items-center'>
                                <div className='tw-w-full'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>Phone number</div>
                                    <ReactPhoneInput
                                        className="tw-w-full tw-h-[48px] hs-phone-input"
                                        defaultCountry="us"
                                        value={phone}
                                        flagsImagePath={flagImg}
                                        onChange={this.handlePhoneChange}
                                    />
                                </div>
                            </div>

                            <div className='tw-mt-[10px] tw-flex tw-justify-between tw-items-center phone:tw-flex-wrap'>
                                <div className=' tw-w-[48%] phone:tw-w-full'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>State</div>
                                    <Input className='tw-w-full tw-h-[48px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='State' value={state} onChange={(e) => this.handleTextChange(e, 'state')} />
                                </div>
                                <div className=' tw-w-[48%] phone:tw-w-full phone:tw-mt-[10px]'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>City</div>
                                    <Input className='tw-w-full tw-h-[48px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='City' value={city} onChange={(e) => this.handleTextChange(e, 'city')} />
                                </div>
                            </div>

                            <div className='tw-mt-[10px] tw-flex tw-justify-between tw-items-center'>
                                <div className=' tw-w-full'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>Address</div>
                                    <Input className='tw-w-full tw-h-[48px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='Address' value={address} onChange={(e) => this.handleTextChange(e, 'address')} />
                                </div>
                            </div>

                            <div className='tw-mt-[10px] tw-flex tw-justify-between tw-items-center'>
                                <div className=' tw-w-full'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>Zip code</div>
                                    <Input className='tw-w-full tw-h-[48px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='Zip code' value={zipCode} onChange={(e) => this.handleTextChange(e, 'zipCode')} />
                                </div>
                            </div>

                            {/* <div className='tw-mt-[10px]'>
                                <div className='tw-flex tw-items-center'>
                                    <Checkbox
                                        checked={isSaveAddress}
                                        onChange={this.toggleSaveAddress}
                                        label={null}
                                    />
                                    <div className="hs-eula-tip tw-text-[#93989D] tw-text-[12px] tw-leading-[25px]">Save delivery information for your next purchase</div></div>
                            </div> */}

                            <div className='tw-mt-[10px] tw-flex tw-justify-between tw-items-center'>
                                <div className=' tw-w-full'>
                                    <div className='tw-text-[12px] tw-mb-[5px]'>Order notes(optional)</div>
                                    <Input type="textarea" className='tw-w-full tw-h-[80px] !placeholder:tw-text-body-text-1-translucence tw-text-[14px]' placeholder='Order notes' value={notes} onChange={(e) => this.handleTextChange(e, 'notes')} />
                                </div>
                            </div>

                            {/* {isMobile && this.renderPaymentType()} */}

                            {isMobile && this.renderPaymentBtn(disabledPaymentBtn)}
                        </div>
                    </div>
                </div>

                {!isMobile && this.renderSubtotalComponent()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
        loginStatus: UserSelectors.selectLoginStatus(state),
        shoppingCartArr: ShoppingCartSelectors.selectShoppingCartData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
        authActions: bindActionCreators(authActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
