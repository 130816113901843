

import React from 'react';
import Colors from '../../constants/ColorConstants';

const TrxIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (

    <svg width={size} height={size} viewBox="0 0 50 50" {...props}>
        <title>Icons Crypto/Tron</title>
        <g id="Icons-Crypto/Tron" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M47.874378,15.0629636 C45.5306591,12.8989298 42.2885146,9.59428617 39.6479247,7.25056728 L39.4916767,7.14119373 C39.2317051,6.93242947 38.9385874,6.76870608 38.6245007,6.65682515 L38.6245007,6.65682515 C32.2573977,5.46934091 2.62497844,-0.069648087 2.04686111,0.000663479987 C1.88486956,0.0233507233 1.73002941,0.082083195 1.59374212,0.172536199 L1.44530659,0.289722144 C1.26253112,0.475345291 1.12371003,0.699594742 1.03906198,0.945963436 L1,1.04752459 L1,1.60220473 L1,1.68814109 C4.33589323,10.9770803 17.5075934,41.406364 20.101309,48.5468943 C20.257557,49.0312629 20.554428,49.9531256 21.1091082,50 L21.2341065,50 C21.5309776,50 22.7965858,48.3281472 22.7965858,48.3281472 C22.7965858,48.3281472 45.4212855,20.8910113 47.7103177,17.969175 C48.0066148,17.6092658 48.2681937,17.222129 48.4915573,16.8129404 C48.607528,16.1616985 48.3732334,15.4973694 47.874378,15.0629636 Z M28.6011962,18.2582337 L38.2573181,10.2505275 L43.9213054,15.4692082 L28.6011962,18.2582337 Z M24.851246,17.7348032 L8.22646661,4.10998395 L35.1245472,9.07085562 L24.851246,17.7348032 Z M26.3512261,21.3050683 L43.3666253,18.5629172 L23.9137584,42.0001062 L26.3512261,21.3050683 Z M5.96868407,5.46934091 L23.4606395,20.3128939 L20.929423,42.0157309 L5.96868407,5.46934091 Z" id="Shape" fill="#FF060A" fill-rule="nonzero" />
        </g>
    </svg>
);

export default TrxIcon