import React from 'react';
import Colors from '../constants/ColorConstants';

const EyeOffIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
<svg width={size} height={size} viewBox={viewBox} {...props}>
  <path 
    fill={color}
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
    id="Icon/small/eyeoff"
    d="M9.952 13.437a2.509 2.509 0 01-.343-2.173c.196.434.633.736 1.141.736.692 0 1.25-.558 1.25-1.25 0-.508-.302-.945-.736-1.14a2.509 2.509 0 012.187.352l1.073-1.065a4.001 4.001 0 00-5.644 5.605l1.072-1.065zm6.435-6.39l1.454-1.444a1 1 0 011.41 1.42L6.976 19.21a1 1 0 01-1.409-1.42l1.281-1.272C5.262 15.562 3.827 14.1 3 12c1.73-4.39 5.935-6 9-6 1.317 0 2.891.315 4.387 1.046zm2.76 2.016A9.132 9.132 0 0121 12c-1.73 4.39-6.118 6-9 6a9.168 9.168 0 01-1.686-.165l1.852-1.838a4.002 4.002 0 003.83-3.804l3.151-3.13z"
  />  
</svg>
);

export default EyeOffIcon;
