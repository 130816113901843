
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from "query-string";
import PagePathConstants from '../constants/router/PagePathConstants';
import RDSButton from '../components/common/RDSButton';
import * as orderActionCreator from '../actions/orderActionCreator';
// import EDSInput from '../components/common/EDSInput';
import { Input } from 'reactstrap';
import SearchIcon from '../icons/SearchIcon';
import OrderSearchIcon from '../icons/OrderSearchIcon';
import NonRecordIcon from '../icons/NonRecordIcon';
import SvgIcon from '../components/common/SvgIcon';
import ColorConstants from '../constants/ColorConstants';
import I18N from '../i18n/index';
import Pagination from '@material-ui/lab/Pagination';
import * as DateTimeUtil from '../utils/DateTimeUtil';
import RdsBackIcon from '../icons/RdsBackIcon';
import Loading from '../components/common/LoadingIndicator';
import * as ResponsivePageUtils from '../utils/ResponsivePageUtils';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';

class OrderListPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            orderListParams: {
                page: 1,
                per_page: 10,
                keywords: ''
            },
            orderList: [],
            total: 0,
            showLoading: false,
        }
        this.handleBack = this.handleBack.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.queryOrder = this.queryOrder.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleViewOrderDetail = this.handleViewOrderDetail.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const queryParams = queryString.parse(location.search);
    }


    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }
    queryOrder(e, orderListParams) {
        const { orderActions } = this.props;
        this.toggleLoading();
        orderActions && orderActions.queryOrderData(orderListParams ? orderListParams : this.state.orderListParams)
            .then(res => {
                this.toggleLoading();
                const orderList = res.data.list;
                const total = res.data.total;
                this.setState({ orderList, total });
            })
            .catch((err) => {
                this.toggleLoading();
            })
    }

    handleBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleTextChange(e) {
        const value = e && e.target.value;
        let { orderListParams } = this.state;
        orderListParams.keywords = value;
        this.setState({ orderListParams });
    }


    handlePageChange(e, page) {
        let { orderListParams } = this.state;
        orderListParams.page = page;
        this.queryOrder(null, orderListParams)
            .then(() => {
                this.setState({ orderListParams });
            });
    }


    handleViewOrderDetail(id) {
        const { history, location } = this.props;
        const queryParams = queryString.parse(location.search);
        const params = new URLSearchParams(Object.assign({}, queryParams, {
            id: id,
        }));
        history && history.push({ pathname: PagePathConstants.ORDER_DETAIL, search: `?${params.toString()}`});
    }

    render() {
        const isMobile = ResponsivePageUtils.isMobileScreen();
        const { orderListParams, orderList, total, showLoading } = this.state;
        const pageTotal = Math.ceil(total / orderListParams.per_page);
        return (
            <div className='tw-w-full tw-h-full  scrollbar-hidden SFNS-font tw-text-[#393c41] ad-bg-container'>
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-overflow-scroll '>
                    <div className='tw-h-[50px] tw-flex tw-items-center tw-pl-[80px] phone:tw-pl-[30px] tw-pt-[30px] tw-text-brand-primary' onClick={this.handleBack}><RdsBackIcon color={ColorConstants.brand.primary} /> Back</div>
                    <div className='tw-w-[80%] phone:tw-w-full tw-mx-auto phone:tw-px-[20px] phone:tw-w-full '>


                        <div className='tw-flex tw-flex-col tw-items-center tw-w-full tw-px-[20px] tw-relative'>
                            <OrderSearchIcon size={400} />
                            {/* <EDSInput className="tw-w-full" /> */}
                            <div className="tw-text-brand-primary tw-font-[500] tw-text-[18px] tw-my-[15px]">Enter your email address to search your order!</div>
                            <div className='tw-relative tw-w-[60%] tw-mx-auto'>
                                <Input className='tw-px-[20px] tw-h-[50px] tw-text-body-text-1 tw-text-[14px] tw-border-none  tw-rounded-[30px] tw-shadow-xl focus:tw-border-brand-primary focus:tw-shadow-none tw-placeholder-body-text-2'
                                    value={orderListParams.keywords}
                                    onChange={this.handleTextChange}
                                    placeholder='Please enter your email'
                                />
                                <SearchIcon className="tw-absolute tw-right-[32px] tw-top-[13px]" onClick={this.queryOrder} />
                            </div>

                        </div>
                        {/* <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-pt-[30px] tw-text-white '>
                            <RDSButton
                                color="primary"
                                onClick={this.handleBack}>
                                <span>Back to Home</span>
                            </RDSButton>
                        </div> */}
                    </div>

                    <div className='tw-w-[80%] tw-mx-auto '>
                        {orderList && orderList.length > 0 && <React.Fragment>
                            <div className='tw-w-full tw-mt-[30px]'>
                                <div className='tw-w-full hs-users-table-container'>
                                    <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-[600] tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[60px] tw-shadow-xl'>
                                        {!isMobile && <div className='tw-w-[20%]'>OrderNo</div>}
                                        <div className='tw-w-[20%] phone:tw-w-[30%]'>Product</div>
                                        {!isMobile && <div className='tw-w-[10%] phone:tw-w-[30%]'>Sku</div>}
                                        {!isMobile && <React.Fragment>
                                            <div className='tw-w-[10%]'>Num</div>
                                            <div className='tw-w-[10%]'>Money</div>
                                        </React.Fragment>}
                                        <div className='tw-w-[20%] phone:tw-w-[40%]'>Time</div>
                                        <div className='tw-w-[10%] phone:tw-w-[20%]'>Operation</div>
                                    </div>
                                    <div className='tw-w-full tw-text-[14px]'>
                                        {orderList && orderList.map(record => {
                                            
                                            const productImg = record && record.goodsList && record.goodsList[0] && record.goodsList[0].goods_image && (BASE_URL + JSON.parse(record.goodsList[0].goods_image + ']')[0]);
                                            const selectedSku = record && record.goodsList && record.goodsList[0] && record.goodsList[0].goods_sku && JSON.parse(record.goodsList[0].goods_sku);
                                            let formatSku = '';
                                            selectedSku.map(item => {
                                                formatSku += `${item.title}: ${item.content}    `;
                                            });
                                            const num = record && record.goodsList && record.goodsList[0] && record.goodsList[0].goods_num;
                                            return (<div className='tw-rounded-[30px] tw-min-h-[60px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                                                {!isMobile && <div className='tw-w-[20%] tw-flex tw-justify-center' ><div className='tw-text-center tw-h-[60px] tw-py-[20px] tw-overflow-hidden' >{record && `${record.orderNu}`}</div> </div>}
                                                <div className='tw-w-[20%] phone:tw-w-[30%] tw-flex tw-justify-center'><div className='tw-w-[46px] tw-h-[46px]' style={{ backgroundImage: `url(${productImg})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}></div></div>
                                                {!isMobile && <div className='tw-w-[10%] phone:tw-w-[30%] tw-flex tw-justify-center' ><div className='tw-text-center  tw-py-[20px] tw-overflow-hidden' >{formatSku}</div> </div>}
                                                {!isMobile && <React.Fragment>
                                                    <div className='tw-w-[10%] tw-flex tw-justify-center' ><div className='tw-text-center tw-h-[60px] tw-py-[20px] tw-overflow-hidden' >{num}</div> </div>
                                                    <div className='tw-w-[10%] tw-flex tw-justify-center' ><div className='tw-text-center tw-h-[60px] tw-py-[20px] tw-overflow-hidden' >{record && `${record.accout}`}</div> </div>
                                                </React.Fragment>}
                                                <div className='tw-w-[20%] phone:tw-w-[40%] tw-flex tw-justify-center' ><div className='tw-text-center tw-h-[60px] tw-py-[20px] tw-overflow-hidden' >{record && DateTimeUtil.getFriendlyTime(new Date(record.updatetime * 1000))}</div> </div>
                                                <div className='tw-w-[10%] phone:tw-w-[20%] tw-flex tw-justify-center'>
                                                    <SvgIcon onClick={() => this.handleViewOrderDetail(record.id)} size={20} color={ColorConstants.brand.primary} name="eye-icon" />
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='tw-w-full tw-flex tw-justify-center'>
                                <Pagination count={pageTotal} page={orderListParams.page} onChange={this.handlePageChange} variant="outlined" color="standard" />
                            </div>
                        </React.Fragment>}
                        {orderList && orderList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
                            <NonRecordIcon size={200} />
                            <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>No records</div>
                        </div>}
                    </div>

                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        // tagActions: bindActionCreators(tagActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListPage);


