import ResponsiveConstants from '../constants/ResponsiveConstants';
import { useState, useEffect } from 'react';

export const isMobileScreen = () => {
  return window.innerWidth <= 767.98;
}

export const isTabletScreen = () => {
  return window.innerWidth > 767.98 && window.innerWidth <= ResponsiveConstants.Breakpoints.TW_LARGE;
}

export const isPCScreen = () => {
  return window.innerWidth > ResponsiveConstants.Breakpoints.TW_LARGE;
}

export const isScreenXSmall = () => {
  return window.innerWidth < ResponsiveConstants.Breakpoints.SMALL;
}

export const isScreenSmall = () => {
  return window.innerWidth >= ResponsiveConstants.Breakpoints.SMALL && window.innerWidth < ResponsiveConstants.Breakpoints.MEDIUM;
}

export const isScreenMedium = () => {
  return window.innerWidth >= ResponsiveConstants.Breakpoints.MEDIUM && window.innerWidth < ResponsiveConstants.Breakpoints.LARGE;
}

export const isScreenLarge = () => {
  return window.innerWidth >= ResponsiveConstants.Breakpoints.LARGE && window.innerWidth < ResponsiveConstants.Breakpoints.EXTRA_LARGE;
}

export const isScreenXLarge = () => {
  return window.innerWidth >= ResponsiveConstants.Breakpoints.EXTRA_LARGE;
}

export const isScreenBelow = (size) => { //sm, md, lg, xl
  let breakpoint;
  switch(size){
    case 'sm': breakpoint = ResponsiveConstants.Breakpoints.SMALL; break;
    case 'md': breakpoint = ResponsiveConstants.Breakpoints.MEDIUM; break;
    case 'lg': breakpoint = ResponsiveConstants.Breakpoints.LARGE; break;
    case 'xl': breakpoint = ResponsiveConstants.Breakpoints.EXTRA_LARGE; break;
    default: breakpoint = 0; break;
  }
  return window.innerWidth < breakpoint;
}

export const isScreenBelowBreakpoint = (breakpoint) => {
  return window.innerWidth < breakpoint;
}

// TODO need to unify the breakpoints of the rebranding version and the original version
// Keep 767.98 for now as this impacts too many places
export const useWindowSize = (breakpoint = 767.98) => {
  const [isMobileOrWeb, setisMobileOrWeb] = useState(isScreenBelowBreakpoint(breakpoint));
  useEffect(() => {
    function updateSize() {
      setisMobileOrWeb(isScreenBelowBreakpoint(breakpoint));
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return [isMobileOrWeb];
}
