

import React from 'react';
import Colors from '../../constants/ColorConstants';

const EthIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={0.633333333 * size} height={size} viewBox="0 0 19 30" {...props}>
        <g>
            <g>
                <g>
                    <path fill="#343434" d="M9.497 0l-.205.683v19.833l.205.2 9.404-5.441z" />
                </g>
                <g>
                    <path fill="#8c8c8c" d="M9.404 0L0 15.275l9.404 5.441v-9.625z" />
                </g>
                <g>
                    <path fill="#3c3c3b" d="M9.401 22.46l-.116.139v7.065l.116.33 9.41-12.972z" />
                </g>
                <g>
                    <path fill="#8c8c8c" d="M9.404 29.995V22.46L0 17.022z" />
                </g>
                <g>
                    <path fill="#141414" d="M9.402 20.714l9.404-5.442-9.404-4.184z" />
                </g>
                <g>
                    <path fill="#393939" d="M0 15.272l9.404 5.442v-9.626z" />
                </g>
            </g>
        </g>
    </svg>
);

export default EthIcon