export default {

  // TODO need to unify the breakpoints of the rebranding version and the original version
  Breakpoints: {
    EXTRA_SMALL   : 0,
    SMALL         : 576,
    MEDIUM        : 768,
    LARGE         : 992,
    TW_LARGE      : 1024,
    EXTRA_LARGE   : 1140,
  },

};

