const kv = {
  'back': '返回',
  'login-form-title': '登录您的账户',
  'continue': '继续',
  'email': '邮件地址',
  'password': '密码',


  'home-route': '首页',
  'payment-route': '付款',
  'wallet-route': '钱包',
  'website-route': '网站',
  'agent-route': '代理',

  'dashboard-overview': '您的概览',

  'login-error': '账户或密码错误，请重试',
};
export { kv };