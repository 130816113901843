

import React from 'react';
import Colors from '../../constants/ColorConstants';

const BitCoinIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={size} viewBox='0 0 32 32' {...props}>
        <g fill-rule="nonzero" fill="none">
            <path d="M31.522 19.87c-2.138 8.572-10.82 13.788-19.394 11.651C3.558 29.384-1.66 20.702.479 12.131 2.615 3.56 11.298-1.659 19.87.48c8.573 2.137 13.79 10.82 11.652 19.392z" fill="#F7931A" />
            <path d="M22.806 13.72c.318-2.128-1.304-3.273-3.52-4.036l.719-2.884-1.756-.438-.7 2.808a72.52 72.52 0 0 0-1.407-.33l.705-2.827-1.754-.438-.72 2.883a58.74 58.74 0 0 1-1.121-.263l.002-.01-2.421-.604-.467 1.875s1.302.299 1.275.317c.71.178.84.648.818 1.021l-.82 3.286c.05.012.113.03.183.058l-.185-.046-1.148 4.603c-.087.216-.308.54-.805.417.018.025-1.276-.319-1.276-.319l-.872 2.01 2.286.57c.425.106.841.218 1.25.323l-.726 2.917 1.754.438.72-2.886c.479.13.944.25 1.399.363l-.717 2.872 1.755.438.727-2.912c2.994.567 5.245.338 6.192-2.369.764-2.18-.037-3.437-1.613-4.258 1.147-.265 2.012-1.02 2.242-2.578zm-4.012 5.625c-.543 2.18-4.214 1.002-5.404.706l.964-3.864c1.19.297 5.006.885 4.44 3.158zm.542-5.656c-.495 1.983-3.55.976-4.542.729l.874-3.505c.992.247 4.184.708 3.668 2.776z" fill="#FFF" />
        </g>
    </svg>
);

export default BitCoinIcon