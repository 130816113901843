import React from 'react';
import Colors from '../constants/ColorConstants';

const EyeIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
<svg width={size} height={size} viewBox={viewBox} {...props}>
  <path 
    fill={color}
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
    id="Icon/small/eye"
    d="M9.61 11.264c.195.434.632.736 1.14.736.692 0 1.25-.558 1.25-1.25 0-.508-.302-.945-.736-1.14A2.497 2.497 0 0114.5 12c0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 01-2.39-3.236zM3 12c1.73-4.39 5.935-6 9-6 2.978 0 7.27 1.61 9 6-1.73 4.39-6.118 6-9 6-2.882 0-7.27-1.61-9-6zm9 4c2.208 0 4-1.792 4-4s-1.792-4-4-4-4 1.792-4 4 1.792 4 4 4z"
  />  
</svg>
);

export default EyeIcon;
