import { createSelector } from 'reselect';
import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectGlobalAlertReducer  = state => state.get(ReducerNameConstants.GLOBAL_ALERT);

export const selectGlobalAlert = createSelector(
  [selectGlobalAlertReducer],
  (globalAlertReducer) => {
    const globalAlert = globalAlertReducer && globalAlertReducer.get(StoreStateConstants.GLOBAL_ALERT);
    return globalAlert || Map();
  },
);
