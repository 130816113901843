import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getProductCategporyList = () => {
    return api.post(
        `${ApiEndpointConstants.CATEGORY_LIST}`,
    );
}

export const fetchCategoryDetailById = (id) => {
    return api.post(
        `${ApiEndpointConstants.CATEGORY_DETAIL}`,
        { id }
    );
}