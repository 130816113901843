import React from 'react';
import Latex from 'react-latex';

//** Utils */
import EDSRichTextRendererUtil from './EDSRichTextRendererUtil';
import ResourceLoader from '../../../../utils/ThirdPartyResourceLoader';


const LatexRenderer = ({ source }) => {
  ResourceLoader.getKaTextStylesheet();

  return (
    <div className="EDSRichTextRenderer__Latex">
      <Latex>
        {EDSRichTextRendererUtil.preprocessLatexCode(source)}
      </Latex>
    </div>
  );

};

export default LatexRenderer;
