import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectShoppingCartReducer  = state => state.get(ReducerNameConstants.SHOPPING_CART);

export const selectShoppingCartData = createSelector(
  [selectShoppingCartReducer],
  (shoppingCartReducer) => {
    const shoppingCartData = shoppingCartReducer && shoppingCartReducer.get(StoreStateConstants.SHOPPING_CART);
    return shoppingCartData?.toJS();
  },
);