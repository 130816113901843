

import React from 'react';
import Colors from '../../constants/ColorConstants';

const GooglePayIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={0.7 * size} viewBox="0 0 115 80" fill="none" {...props}>
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" fill="url(#paint0_linear)" />
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" stroke="#E8E8E8" />
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" stroke="#EDEDED" />
        <path d="M54.9427 39.5434V49.666H51.6667V24.6677H60.3553C61.3924 24.6465 62.4236 24.8268 63.3892 25.1982C64.3549 25.5696 65.2359 26.1249 65.9816 26.8318C66.7347 27.4959 67.3341 28.3103 67.7394 29.2202C68.1448 30.1302 68.3468 31.1147 68.3318 32.1076C68.3533 33.1057 68.1545 34.0966 67.7488 35.0124C67.3431 35.9282 66.7403 36.7472 65.9816 37.4132C64.4622 38.8333 62.5868 39.5428 60.3553 39.5414H54.9427V39.5434ZM54.9427 27.7454V36.4717H60.4367C61.039 36.4892 61.6384 36.3833 62.1966 36.1608C62.7547 35.9382 63.2593 35.6039 63.6781 35.1792C64.0948 34.7822 64.4259 34.3073 64.6521 33.7826C64.8782 33.2579 64.9947 32.6941 64.9947 32.1245C64.9947 31.5549 64.8782 30.9911 64.6521 30.4664C64.4259 29.9417 64.0948 29.4668 63.6781 29.0698C63.2646 28.6362 62.7617 28.2937 62.2029 28.065C61.6441 27.8363 61.042 27.7267 60.4367 27.7434H54.9427V27.7454Z" fill="#5F6368" />
        <path d="M75.3192 32.166C77.5387 32.166 79.2907 32.7752 80.5752 33.9936C81.8596 35.212 82.5012 36.8825 82.5 39.0051V49.1296H79.6277V46.8499H79.4971C78.2537 48.7273 76.5999 49.666 74.5358 49.666C72.7738 49.666 71.2997 49.1296 70.1135 48.0568C69.5442 47.5644 69.0891 46.9478 68.7813 46.2518C68.4734 45.5559 68.3207 44.7981 68.3341 44.0338C68.3341 42.334 68.9596 40.9821 70.2105 39.9783C71.4614 38.9744 73.1313 38.4713 75.2203 38.4687C77.0034 38.4687 78.4719 38.8039 79.6258 39.4744V38.7695C79.6292 38.2484 79.5197 37.7331 79.3051 37.261C79.0906 36.7888 78.7764 36.3716 78.3855 36.0396C77.5914 35.3037 76.5565 34.9022 75.487 34.9151C73.8096 34.9151 72.4822 35.6418 71.5049 37.0951L68.8601 35.3844C70.3149 33.2388 72.468 32.166 75.3192 32.166ZM71.434 44.1009C71.432 44.4934 71.5214 44.8808 71.6946 45.2306C71.8679 45.5805 72.12 45.8828 72.43 46.1124C73.0942 46.649 73.9185 46.9332 74.7633 46.917C76.0305 46.9148 77.2452 46.3968 78.1411 45.4764C79.1359 44.5147 79.6333 43.3863 79.6333 42.0913C78.6969 41.325 77.3913 40.9419 75.7164 40.9419C74.4966 40.9419 73.4795 41.2439 72.665 41.848C71.8425 42.461 71.434 43.2062 71.434 44.1009Z" fill="#5F6368" />
        <path d="M100 33L89.5547 56.3333H86.3255L90.2017 48.169L83.3333 33H86.7335L91.6978 44.6336H91.7658L96.594 33H100Z" fill="#5F6368" />
        <path d="M42.5 37.5532C42.5012 36.5856 42.4212 35.6196 42.2609 34.666H29.1667V40.1346H36.6664C36.513 41.0081 36.1881 41.8406 35.7115 42.5822C35.2349 43.3238 34.6164 43.959 33.8933 44.4496V47.9993H38.3692C40.99 45.5283 42.5 41.874 42.5 37.5532Z" fill="#4285F4" />
        <path d="M29.1059 51.3327C32.863 51.3327 36.0265 50.0542 38.3333 47.8499L33.8453 44.2429C32.5962 45.1206 30.9874 45.6216 29.1059 45.6216C25.4745 45.6216 22.3922 43.0847 21.2901 39.666H16.6667V43.3832C17.8254 45.7728 19.6023 47.7816 21.7989 49.1854C23.9954 50.5891 26.5253 51.3326 29.1059 51.3327Z" fill="#34A853" />
        <path d="M20.8333 39.4139C20.2767 37.6857 20.2767 35.8143 20.8333 34.0861V30.5H16.4163C15.485 32.4393 15 34.5796 15 36.75C15 38.9204 15.485 41.0607 16.4163 43L20.8333 39.4139Z" fill="#FBBC04" />
        <path d="M29.0561 28.7116C31.0337 28.678 32.9445 29.4553 34.3756 30.8756L38.3333 26.7579C35.8237 24.3055 32.499 22.9591 29.0561 23.0009C26.4858 23.0011 23.9661 23.7444 21.7783 25.1481C19.5906 26.5517 17.8208 28.5603 16.6667 30.9497L21.2716 34.6667C22.3693 31.2483 25.4393 28.7116 29.0561 28.7116Z" fill="#EA4335" />
        <defs>
            <linearGradient id="paint0_linear" x1="57" y1="5" x2="57" y2="80" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F5F5F5" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
        </defs>
    </svg>
);

export default GooglePayIcon