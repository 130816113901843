import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import ActionConstants from "../constants/actions/ActionConstants";
import * as CategoryAPI from "../apis/CategoryAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';


export const updateCurrentCategory = (data) => ({
    type: ActionConstants.UPDATE_CURRENT_CATEGORY,
    payload: data,
});

export const selectCurrentCategory = (item) => {
    return function (dispatch, getState) {
        dispatch(updateCurrentCategory(item))
    };
}



export const fetchProductCategoryList = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CategoryAPI.getProductCategporyList()
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        return resolve(res.data.data.list);
                    }
                    return reject(res.data.msg);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                    } else {
                        dispatch(showErrorGlobalAlert('category-fetch-error'));
                    }
                    return reject('err');
                });
        });
    };
}

export const fetchCategoryDetailById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CategoryAPI.fetchCategoryDetailById(id)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(updateCurrentCategory(res.data.data))
                        return resolve(res.data.data);
                    }
                    return reject(res.data.msg);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                    } else {
                        dispatch(showErrorGlobalAlert('category-fetch-error'));
                    }
                });
        });
    };
}

