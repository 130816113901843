import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentsImg from '../../../images/payments.png';
import * as BaseConfigSelectors from '../../../selectors/BaseConfigSelectors';
import PagePathConstants from '../../../constants/router/PagePathConstants';
import FreeReturnsBadge from '../../../images/badge/freeReturnsBadge.png';
import SaveBadge from '../../../images/badge/saveBadge.png';
import WarrantyBadge from '../../../images/badge/warrantyBadge.png';
import TrustPliot from '../../../images/trustPliot.png';
import { isMobileScreen } from '../../../utils/ResponsivePageUtils';

class Footer extends Component {
    routeToPage(url) {
        const { history, location } = this.props;
        history && history.push({ pathname: url, search: location.search });
    }
    render() {
        const { baseConfig } = this.props;
        return (
            <div className='tw-w-full tw-pb-[60px]'>
                <div className='tw-w-full tw-bg-[#0fc6b3] SFNS-font tw-font-[400]'>
                    <div className='tw-w-[1330px] pad:tw-w-full pad:tw-px-[50px] phone:tw-w-full phone:tw-px-[30px] tw-mx-auto tw-py-[20px] tw-relative'>
                        <div className='tw-w-full phone:tw-w-[60%]'>
                            <h1 className='tw-font-[500] tw-py-[12px] tw-text-[18px]'>Your thoughtful shopping market</h1>
                            <div className='tw-text-[14px]'>Our goal is to ensure your satisfaction with every purchase.</div>
                        </div>
                        {isMobileScreen() && <img src={TrustPliot} alt='trust-pliot' className='tw-absolute tw-bottom-[20px] tw-right-[10px] tw-w-[150px]'/>}
                    </div>
                    <div className='tw-w-[1330px] pad:tw-w-[calc(100%-100px)] phone:tw-w-[calc(100%-60px)] pad:tw-mx-[50px] phone:tw-mx-[30px] tw-mx-auto tw-bg-white tw-rounded-t-[30px] tw-px-[56px] phone:tw-px-[30px] tw-pt-[30px] tw-pb-[20px] tw-flex phone:tw-flex-col tw-items-start tw-relative'>
                        <div className='tw-w-[25%] pad:tw-w-[22%] phone:tw-w-full tw-h-[200px] pad:tw-h-[260px] phone:tw-h-auto phone:tw-pb-[50px] tw-mr-[25px] '>
                            <div className='tw-flex tw-items-center'>
                                <img src={SaveBadge} alt="badge" className='tw-w-[35px]' />
                                <div className='tw-ml-[10px] tw-text-[16px]'>Return Policy</div>
                            </div>
                            <div className='tw-mt-[10px] tw-text-[14px]'>We offer a hassle-free return policy for all purchases made through our website. If you are not completely satisfied with your purchase, you may return the item(s) within 30 days of receipt for a full refund. Please ensure that the item(s) are unused, in their original packaging, and accompanied by proof of purchase.</div>
                        </div>
                        <div className='tw-w-[25%] pad:tw-w-[22%] phone:tw-w-full tw-h-[200px] pad:tw-h-[260px] phone:tw-h-auto phone:tw-pb-[50px] tw-mr-[25px] '>
                            <div className='tw-flex tw-items-center'>
                                <img src={FreeReturnsBadge} alt="badge" className='tw-w-[35px]' />
                                <div className='tw-ml-[10px] tw-text-[16px]'>Exchange Policy</div>
                            </div>
                            <div className='tw-mt-[10px] tw-text-[14px]'>We also offer an exchange policy for eligible items. If you would like to exchange your purchase for a different size, color, or style, please contact us within 14 days of receipt to initiate the exchange process. The item(s) must be in unused condition and in their original packaging.</div>
                        </div>
                        <div className='tw-w-[25%] pad:tw-w-[22%] phone:tw-w-full tw-h-[200px] pad:tw-h-[260px] phone:tw-h-auto phone:tw-pb-[50px] tw-mr-[25px] '>
                            <div className='tw-flex tw-items-center'>
                                <img src={WarrantyBadge} alt="badge" className='tw-w-[35px]' />
                                <div className='tw-ml-[10px] tw-text-[16px]'>Warranty Policy</div>
                            </div>
                            <div className='tw-mt-[10px] tw-text-[14px]'>All products purchased through our website are covered by a 1-year warranty against defects in materials and workmanship. If you encounter any issues with your purchase during the warranty period, please contact us for assistance.</div>
                        </div>
                        {!isMobileScreen() && <img src={TrustPliot} alt='trust-pliot' className='tw-absolute tw-bottom-0 tw-right-[10px] tw-w-[270px]' />}
                    </div>
                </div>
                <div className='tw-bg-[#F7F7F7] tw-pt-[30px] SFNS-font phone:tw-px-[20px] phone:tw-pb-[30px]'>
                    <div className='tw-w-full tw-mx-auto tw-h-[220px] phone:tw-h-auto  tw-select-none tw-flex tw-justify-center tw-items-start phone:tw-flex-wrap'>
                        <div className='phone:tw-text-center'>
                            <div className='tw-text-[22px] phone:tw-text-[18px]]'>CONTACT</div>
                            <div className='tw-mt-[20px] tw-flex phone:tw-justify-center tw-items-center phone:tw-text-[14px]'>
                                <div>Email:</div>
                                <div className='tw-ml-[10px] phone:tw-ml-0 tw-text-[#27458f]'>{`${baseConfig.partnerEmail}`}</div>
                            </div>
                            <div className='tw-mt-[10px] tw-flex  phone:tw-justify-center tw-items-center phone:tw-text-[14px]'>
                                <div>Company address:</div>
                                <div className='tw-ml-[10px] phone:tw-ml-0 '>{baseConfig.companyAddress}</div>
                            </div>

                            <div className='tw-mt-[10px] tw-flex  phone:tw-justify-center tw-items-center phone:tw-text-[14px]'>
                                <div>Phone:</div>
                                <div className='tw-ml-[10px] phone:tw-ml-0 '>{baseConfig.phone}</div>
                            </div>
                            <div className='tw-mt-[10px] tw-flex tw-items-center tw-justify-between phone:tw-flex-col'>
                                <div className='tw-text-[#27458f]'>100% Secure Payments</div>
                                <div className='tw-w-[255px] tw-h-[22px] phone:tw-mt-[20px] tw-ml-[10px]' style={{ backgroundImage: `url(${PaymentsImg})`, backgroundSize: 'cover' }}></div>
                            </div>
                        </div>

                        <div className='tw-ml-[100px] phone:tw-ml-0 phone:tw-mt-[50px] phone:tw-text-center'>
                            <div className='tw-text-[22px] phone:tw-text-[18px]]'>POLICIES AND TERMS</div>
                            <div className='tw-mt-[20px] tw-text-[16px] phone:tw-text-[14px] tw-text-[#999999]' onClick={() => this.routeToPage(PagePathConstants.ABOUT_US)}>About us</div>
                            <div className='tw-mt-[10px] tw-text-[16px] phone:tw-text-[14px] tw-text-[#999999]' onClick={() => this.routeToPage(PagePathConstants.SHIPPING_POLICY)}>Shipping Policy</div>
                            <div className='tw-mt-[10px] tw-text-[16px] phone:tw-text-[14px] tw-text-[#999999]' onClick={() => this.routeToPage(PagePathConstants.PRIVACY_POLICY)}>Privacy Policy</div>
                        </div>
                    </div>


                </div>
            </div>

        );
    }
}
function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
    };
}


// function mapDispatchToProps(dispatch) {
//     return {
//         tagActions: bindActionCreators(tagActionCreator, dispatch),
//         productActions: bindActionCreators(productActionCreator, dispatch),
//     };
// }

export default connect(mapStateToProps, null)(Footer);