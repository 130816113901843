import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkOrAnchor = ({ href, children, ...otherProps }) => {
  // If href is not provided just render a regular div and alert devs
  // about it using PropTypes
  if (!href) {
    return <div {...otherProps}>{children}</div>
  }

  if (href.startsWith('/')) {
    return <Link to={href} {...otherProps}>{children}</Link>;
  }

  return <a href={href} {...otherProps}>{children}</a>;
};

LinkOrAnchor.propTypes = {
  href     : PropTypes.string,
  children : PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

export default LinkOrAnchor;
