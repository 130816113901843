import isUndefined from 'lodash/isUndefined';
// import "url-search-params-polyfill";

// === Env === //

// === Helpers === //

// === Utils === //

// === Constants === //
import RegexConstants from "../constants/RegexConstants";

class UrlUtil {
  static isHttpInUrl(url) {
    if (isUndefined(url)) {
      return false;
    }

    if (
      url &&
      !url.match(RegexConstants.HTTP_PROTOCOL) &&
      !url.match(RegexConstants.HTTPS_PROTOCOL)
    ) {
      return false;
    }
    return true;
  }

  static addHttpToUrl(url) {
    let returnString = url;

    if (
      url &&
      !url.match(RegexConstants.HTTP_PROTOCOL) &&
      !url.match(RegexConstants.HTTPS_PROTOCOL)
    ) {
      returnString = `http://${url}`;
    }
    return returnString;
  }
}

export default UrlUtil;
