import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import RDSButton from './RDSButton';
import { REFRESH_INTERVAL } from '../../constants/CountdownConstants';
import * as DateTimeUtil from '../../utils/DateTimeUtil';
import * as CountdownSelectors from '../../selectors/CountdownSelectors';
import * as countdownActionCreator from '../../actions/countdownActionCreator';

const INIT_COUNTDOWN_TIME = {
    hours: 8,
    minutes: 13,
    seconds: 27
};
const COUNTDOWN_TIME = DateTimeUtil.convertToTime(INIT_COUNTDOWN_TIME);

class CountdownButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timestamp: Date.now(),
            // timeStart: 0,
            // isCountdowning: false,
            countdownTimeStr: '',
        };

        this.handleCountdown = this.handleCountdown.bind(this);
        this.generateTick = this.generateTick.bind(this);
        this.tick = this.tick.bind(this);
        this.clearTimeout = this.clearTimeout.bind(this);
    }

    componentDidMount() {
        const { countingDownStatus, countdownStartTime } = this.props;
        if (countingDownStatus && countdownStartTime !== 0) {
            this.tick();
        }
        if (!countingDownStatus && countdownStartTime === 0) {
            this.handleCountdown();
        }
    }

    handleCountdown() {
        const { startHandler, countdownActions } = this.props;
        const countDownEvent = () => {
            countdownActions && countdownActions.updateCountingDownStatus(true);
            countdownActions && countdownActions.updateCountDownStartTime(Date.now());
            // console.log('开始时间-------' + this.props.countdownStartTime)
            this.tick();
        }
        if (startHandler) {
            startHandler()
                .then(countDownEvent)
                .catch(() => { });
        } else {
            countDownEvent();
        }
    }

    tick() {
        let now = Date.now();
        const { countdownStartTime, countingDownStatus, countdownActions, stopHandler } = this.props;
        const cumulativeTime = now - countdownStartTime;
        // console.log('累积时间~~~~~' + cumulativeTime);
        const timediff = (COUNTDOWN_TIME - cumulativeTime);
        if (countingDownStatus && countdownStartTime !== 0 && timediff <= 0) {
            this.clearTimeout();
            countdownActions && countdownActions.updateCountingDownStatus(false);
            countdownActions && countdownActions.updateCountDownStartTime(0);
            this.setState({ countdownTimeStr: '00:00:00' });
            stopHandler && stopHandler();
            return;
        }
        const countdownTime = DateTimeUtil.renderCountdownTime(timediff);
        let countdownTimeStr = '';
        if (INIT_COUNTDOWN_TIME.hours > 0) {
            countdownTimeStr += `${DateTimeUtil.digitalFillZero(countdownTime.hours, 2)}:${DateTimeUtil.digitalFillZero(countdownTime.minutes, 2)}:${DateTimeUtil.digitalFillZero(countdownTime.seconds, 2)}`;
        } else {
            countdownTimeStr += `${DateTimeUtil.digitalFillZero(countdownTime.minutes, 2)}:${DateTimeUtil.digitalFillZero(countdownTime.seconds, 2)}`;
        }
        // console.warn(countdownTimeStr)
        this.setState({ countdownTimeStr });
        this.clearCountdown = this.setState({ timestamp: now }, this.generateTick); // update state to trigger refresh
    }

    generateTick() {
        setTimeout(this.tick, REFRESH_INTERVAL); // we'll come back later
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    clearTimeout() {
        if (this.clearCountdown) {
            clearTimeout(this.clearCountdown);
            this.clearCountdown = null;
        }
    }

    render() {
        const { className, countingDownStatus, text } = this.props;
        const { countdownTimeStr } = this.state;
        // return (<RDSButton disabled={countingDownStatus} className={classNames('tw-rounded-btn tw-text-white', className)} onClick={this.handleCountdown}>{countingDownStatus ? countdownTimeStr : (text ? text : 'Code')}</RDSButton>);
        const renderCountdownArr = countdownTimeStr.split(':');
        return (<div className={classNames('tw-text-[16px] tw-text-light-red tw-flex tw-items-center', className)} onClick={this.handleCountdown}>{countingDownStatus ? renderCountdownArr.map(item => {
            return (<div className='tw-mr-[15px] tw-w-[40px] tw-h-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-[10px] tw-bg-white'>{item}</div>)
        }) : (text ? text : 'Code')}</div>);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        countdownStartTime: CountdownSelectors.selectCountdownStartTime(state, ownProps),
        countingDownStatus: CountdownSelectors.selectCountingDownStatus(state, ownProps),
    };
}


function mapDispatchToProps(dispatch) {
    return {
        countdownActions: bindActionCreators(countdownActionCreator, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(CountdownButton);
