import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectBaseConfigReducer  = state => state.get(ReducerNameConstants.BASE_CONFIG);

export const selectBaseConfig = createSelector(
  [selectBaseConfigReducer],
  (baseConfigReducer) => {
    const baseConfig = baseConfigReducer && baseConfigReducer.get(StoreStateConstants.BASE_CONFIG);
    return baseConfig?.toJS();
  },
);