//    ____ _____ ___  ____
//   / ___|_   _/ _ \|  _ \
//   \___ \ | || | | | |_) |
//    ___) || || |_| |  __/
//   |____/ |_| \___/|_|
//
//  This component is part of the Syllabus (EDS) project
//  Before making any updates to it, make sure you read the "Contributing to Syllabus" section in http://guide.clubmodo.com/syllabus/
//

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './EDSTooltip.scss';

const EDSUncontrolledTooltip = ({
  className,
  qaSelector,
  ...otherProps
}) => {

  const tooltipClassName = classnames('eds-tooltip', className, qaSelector);

  return (
    <UncontrolledTooltip
      className={tooltipClassName}
      {...otherProps}
    />
  )
}

EDSUncontrolledTooltip.propTypes = {
  className  : PropTypes.string,
  qaSelector : PropTypes.string,
}

export default EDSUncontrolledTooltip;
