import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';
import ProductHelper from '../helpers/ProductHelper';

const initialState = Map({
  [StoreStateConstants.BASE_CONFIG]: Map(),
});

export const BaseConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_BASE_CONFIG: {
      return state.set(StoreStateConstants.BASE_CONFIG, Map(action.payload));
    }
    default:
      return state;
  }
};
