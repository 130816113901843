import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectUserReducer = state => state.get(ReducerNameConstants.USER);

export const selectLoginStatus = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.LOGIN_STATUS);
  },
);

export const selectCurrentUser = createSelector(
  [selectUserReducer],
  (userReducer) => {
    const currentUser = userReducer && userReducer.get(StoreStateConstants.CURRENT_USER)
    return (currentUser && currentUser.toJS()) || {};
  },
);