//    ____ _____ ___  ____
//   / ___|_   _/ _ \|  _ \
//   \___ \ | || | | | |_) |
//    ___) || || |_| |  __/
//   |____/ |_| \___/|_|
//
//  This component is part of the Syllabus (EDS) project
//  Before making any updates to it, make sure you read the "Contributing to Syllabus" section in http://guide.clubmodo.com/syllabus/
//
//  Documentation on this component can be found at: http://guide.clubmodo.com/storybook/
//

import React, { useState } from 'react';
import RDSTextButton from '../../RDSTextButton';
import PropTypes from 'prop-types';
import { OverflowDetector } from 'react-overflow';

import SvgIcon from '../../SvgIcon';

//** Componets */
import MarkdownRenderer from './MarkdownRenderer';
import DraftRenderer from './DraftRenderer';
import LatexRenderer from './LatexRenderer';
import LinkOrAnchor from '../../LinkOrAnchor';

//** Utils */
import EDSRichTextRendererUtil from './EDSRichTextRendererUtil';

//** Styles */
import './EDSRichTextRenderer.scss';
import I18N from '../../../../i18n';

const EDSRichTextRenderer = ({
  source,
  enableHashtags,
  enableMentions,
  enableLatex,
  overflowHidden,
  overflowAction,
  showOverlay,
  height,
  maxHeight,
  overflowRedirectUrl,
  overflowLabel,
  hashtagLinkUrl,
  mentions,
  isStudent,
  creator
}) => {

  const [isOverflowed, setIsOverflowed] = useState(false);
  const [isShowingAll, setIsShowingAll] = useState(false);

  const options = {
    enableHashtags: enableHashtags,
    enableMentions: enableMentions,
    enableLatex: enableLatex,
    overflowHidden: overflowHidden,
    overflowAction: overflowAction,
    height: height,
    maxHeight: maxHeight,
    overflowRedirectUrl: overflowRedirectUrl,
    overflowLabel: overflowLabel,
    hashtagLinkUrl: hashtagLinkUrl,
  }

  const renderRichText = ({ source, mentions, options }) => {
    const hasLatexCode = EDSRichTextRendererUtil.hasLatexCode(source);
    if (options.enableLatex && hasLatexCode) {
      const strArrayByMathSplit = (source && source.split(/(\[math\].*?\[\/math\])/g)) || []
      return strArrayByMathSplit.map((strItem, index) => {
        if (/(^\[math\].*\[\/math\]$)/.test(strItem)) { // Render LaTex and Markdown separately
          return (
            <LatexRenderer
              key={index}
              source={strItem}
            />
          )
      } else {
          return <MarkdownRenderer
            key={index}
            source={strItem}
            options={options}
            mentions={mentions}
            isStudent={isStudent}
            creator={creator}
          />
        }
      })
    } else {
      return (
        <MarkdownRenderer
          source={source}
          options={options}
          mentions={mentions}
          isStudent={isStudent}
          creator={creator}
        />
      );
    }
  }

  const style = { overflow: 'auto' };

  const showExpandLink = isOverflowed && !isShowingAll && options.overflowHidden && options.overflowAction === 'expand';
  const showRedirectLink = isOverflowed && !isShowingAll && options.overflowHidden && options.overflowAction === 'redirect' && options.overflowRedirectUrl;

  if (isShowingAll && options.overflowHidden) {
    style['height'] = 'unset';
    style['maxHeight'] = 'unset';
  } else if (options.height) {
    style['height'] = options.height;
  } else if (options.maxHeight) {
    style['maxHeight'] = options.maxHeight;
  }

  if (showExpandLink || showRedirectLink || options.overflowAction === 'none') {
    style['overflow'] = 'hidden';
  } else {
    style['overflow'] = 'auto';
  }

  style['transition'] = 'height 0.3s ease-out'

  return (
    <div className="EDSRichTextRenderer">
      <OverflowDetector
        onOverflowChange={(isOverflowed) => setIsOverflowed(isOverflowed)}
        style={style}
      >
        {EDSRichTextRendererUtil.isDraftData(source) ?
          <DraftRenderer
            source={source}
            options={options}
            mentions={mentions}
            isStudent={isStudent}
            creator={creator}
          />
          :
          renderRichText({ source, mentions, options })
        }
      </OverflowDetector>

      {showExpandLink && (
        <>
          <div className="EDSRichTextRenderer__overflow-overlay"></div>
          <div className="EDSRichTextRenderer__overflow-btn">
            <RDSTextButton className="qa-test-show-more-btn"
              onClick={() => setIsShowingAll(true)}
              block
            >
              {overflowLabel}
            </RDSTextButton>
          </div>
        </>
      )}
      {showRedirectLink && (
        <>
          <div className="EDSRichTextRenderer__overflow-overlay EDSRichTextRenderer__overflow-overlay--link"></div>
          <div className="EDSRichTextRenderer__overflow-btn qa-test-show-more-btn">
            <LinkOrAnchor
              href={options.overflowRedirectUrl}
              target="_blank"
            >
              {overflowLabel}
            </LinkOrAnchor>
          </div>
        </>
      )}
      {
        !showExpandLink && !showRedirectLink && showOverlay && <div className="EDSRichTextRenderer__overflow-overlay !tw-bottom-0"></div>
      }
    </div >
  );
}

EDSRichTextRenderer.propTypes = {
  // (required) markdown formatted source, can contain LaTex code with [math]latex[/math]
  source: PropTypes.string.isRequired,

  // (optional) render hashtags with link to hashtag page
  enableHashtags: PropTypes.bool,

  // (optional) render mentions with proper styling and link to profile page of user, requires mentions to be passed in 'mentions' prop
  enableMentions: PropTypes.bool,

  // (optional)render latex, will scan source for [math]latex[/math] and anything inside will be parsed as latex
  enableLatex: PropTypes.bool,

  // (optional) truncate the document, default false, document will be cut off at specificed height || maxHeight
  overflowHidden: PropTypes.bool,

  // (optional) what to do if overflowHidden is true
  overflowAction: PropTypes.oneOf(['scroll', 'expand', 'redirect', 'none']),

  // (optional) if 'redirect' is specified in 'overflowAction', we'll redirect to this specified link
  overflowRedirectUrl: PropTypes.string,

  // (optional) height for truncating document
  height: PropTypes.string,

  // (optional) maxHeight for truncating document
  maxHeight: PropTypes.string,

  // (optional) mentions data to be used for name and profile link is mentions is true
  mentions: PropTypes.array,

  // (optional) custom label to be used for viewing more of document, default is "See more".  Be sure to pass Translate component
  overflowLabel: PropTypes.node,

  // (optional) url to be used for hashtag redirect link, defaults to `/home?hashtag=math`
  // url string MUST contain `%{hashtag}`, which will be replaced with hashtag label.
  // example:  `/example/query=%{hashtag}` becomes `/example/query=math`
  hashtagLinkUrl: PropTypes.string,
}

EDSRichTextRenderer.defaultProps = {
  enableHashtags: false,
  enableMentions: false,
  enableLatex: false,
  overflowHidden: false,
  overflowAction: 'scroll',
  overflowLabel:  (
    <>
      {I18N.getText('show_more')}
      <SvgIcon name="down-icon"/>
    </>
  )
}

export default EDSRichTextRenderer;
