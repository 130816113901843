



import React, { Component } from 'react';
import CertifiedImg from '../../images/guarantee/certified.png';
import FreeShippingImg from '../../images/guarantee/freeShipping.png';
import WarrantyImg from '../../images/guarantee/warranty.png';
import ReturnImg from '../../images/guarantee/return.png';


class GuaranteeComponent extends Component {
    render() {
        return (
            <div className='tw-w-[1340px] phone:tw-w-full pad:tw-w-full tw-h-auto tw-mx-auto'>
                    <div className='tw-flex tw-items-center tw-justify-center phone:tw-flex-wrap'>
                        <div className='tw-w-[24%] phone:tw-w-[46%] phone:tw-mb-[20px] tw-flex phone:tw-flex-col tw-justify-center tw-items-center tw-mr-[10px]'>
                            <img src={FreeShippingImg} alt="promise-img" className='tw-w-[60px]' />
                            <div className='tw-text-[18px] phone:tw-mt-[10px] phone:tw-ml-0 tw-ml-[6px] tw-font-[600]'>Free Shipping</div>
                        </div>
                        <div className='tw-w-[24%] phone:tw-w-[46%] phone:tw-mb-[20px] tw-flex phone:tw-flex-col tw-justify-center tw-items-center tw-mr-[10px]'>
                            <img src={WarrantyImg} alt="promise-img" className='tw-w-[60px]' />
                            <div className='tw-text-[18px] phone:tw-mt-[10px] phone:tw-ml-0 tw-ml-[6px] tw-font-[600]'>12-Month Warranty</div>
                        </div>
                        <div className='tw-w-[24%] phone:tw-w-[46%] phone:tw-mb-[20px] tw-flex phone:tw-flex-col tw-justify-center tw-items-center tw-mr-[10px]'>
                            <img src={ReturnImg} alt="promise-img" className='tw-w-[60px]' />
                            <div className='tw-text-[18px] phone:tw-mt-[10px] phone:tw-ml-0 tw-ml-[6px] tw-font-[600]'>Free 30-Day Returns</div>
                        </div>
                        <div className='tw-w-[24%] phone:tw-w-[46%] phone:tw-mb-[20px] tw-flex phone:tw-flex-col tw-justify-center tw-items-center'>
                            <img src={CertifiedImg} alt="promise-img" className='tw-w-[60px]' />
                            <div className='tw-text-[18px] phone:tw-mt-[10px] phone:tw-ml-0 tw-ml-[6px] tw-font-[600]'>Certified Sellers</div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default GuaranteeComponent;
