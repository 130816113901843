import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectCategoryReducer  = state => state.get(ReducerNameConstants.CATEGORY);

export const selectCurrentCategory = createSelector(
  [selectCategoryReducer],
  (categoryReducer) => {
    const category = categoryReducer && categoryReducer.get(StoreStateConstants.CURRENT_CATEGORY);
    return category?.toJS();
  },
);