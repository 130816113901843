import FormValidationConstants from '../constants/FormValidationConstants';
import PhoneNumber, { parsePhoneNumber } from 'awesome-phonenumber';

export const isValidEmail = (value) => {
  return !!value && FormValidationConstants.EMAIL_REGEXP.test(value);
};

export const isValidPassword = (value) => {
  return value.trim() !== '' && value.length >= 6 && value.length <= 19;
};


export const validUsername = (value) => {
  let error = false;
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9_-]+[a-zA-Z0-9]$/;

  if ((value && !value.trim()) || !value || value.length > 25
    || value.length < 3 || !regex.test(value) || !isNaN(Number(value))) {
    error = FormValidationConstants.INVALID_USERNAME;
  }
  return error;
}

export const hasContainSpecialCharacters = (str) => {
  // UTF surrogate pair
  const surrogatePair = RegExp(/[\uD800-\uDBFF][\uDC00-\uDFFF]/);
  return surrogatePair.test( str.trim());
}

export const isValidPhoneNumber = (value) => {
  if (!value) return false;
  const phoneNumber = new PhoneNumber(value);

  if (parsePhoneNumber(value).regionCode === 'US') {
    // 美国电话号码只校验10位数字即可
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber.getNumber('significant'));
  }
  if (phoneNumber.isValid(value)) {
    return true;
  }
  return phoneNumber.isValid(value);
}

export const validLoginPhoneNumber = (value) => {
  if (!value || !((new PhoneNumber(value)).getNumber(PhoneNumberConstants.E164))) {
    return FormValidationConstants.INVALID_US_CA_PHONE_NUMBER;
  }
}

export const required = (value) => {
  if ((value && (value.length === 0 || (typeof value === 'string' && !value.trim()))) || !value) {
    return FormValidationConstants.REQUIRED;
  }
};