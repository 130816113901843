import { List, Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';
import ProductHelper from '../helpers/ProductHelper';

const shoppingCartData = ProductHelper.getShoppingCartData();
const initialState = Map({
  [StoreStateConstants.SHOPPING_CART]: List(shoppingCartData),
});

export const ShoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.ADD_PRODUCT_TO_CART: {
      return state.set(StoreStateConstants.SHOPPING_CART, List(ProductHelper.addProductToShoppingCart(action.payload)));
    }
    case ActionConstants.ADD_PRODUCT_TO_CART: {
      return state.set(StoreStateConstants.SHOPPING_CART, List(ProductHelper.removeProductFromShoppingCart(action.payload)));
    }
    case ActionConstants.UPDATE_PRODUCT_QUANTITY: {
      const { quantity, index } = action.payload;
      return state.set(StoreStateConstants.SHOPPING_CART, List(ProductHelper.updateProductQuantity(quantity, index)));
    }
    case ActionConstants.REMOVE_PRODUCT_FROM_CART: {
      return state.set(StoreStateConstants.SHOPPING_CART, List(ProductHelper.removeProductFromShoppingCart(action.payload)));
    }
    default:
      return state;
  }
};
