import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import ActionConstants from "../constants/actions/ActionConstants";
import * as TagAPI from "../apis/TagAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';

export const fetchProductTagList = (is_home = '') => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            TagAPI.getProductTagList(is_home)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        return resolve(res.data.data.list);
                    }
                    return reject(res.data.msg);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                    } else {
                        dispatch(showErrorGlobalAlert('tag-fetch-error'));
                    }
                    return reject('err');
                });
        });
    };
}