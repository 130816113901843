import React, { Component } from 'react';
import classNames from 'classnames';
import '../../styles/common/Checkbox.scss';

const defaultProps = {
  prefixCls      : 'black-checkbox',
  className      : '',
  type           : 'checkbox',
  defaultChecked : false,
  indeterminate  : false,
  onChange() {},
};


class Checkbox extends Component {
  constructor(props) {
    super(props);
    const checked = 'checked' in props ? props.checked : props.defaultChecked;

    this.state = {
      checked: checked,
    };

    this.focus        = this.focus.bind(this);
    this.blur         = this.blur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.el.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.el.indeterminate = this.props.indeterminate;
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if ('checked' in nextProps) {
      return(
        {checked: nextProps.checked}
      );
    } else {
      return null;
    }
  }

  focus() {
    this.el.focus();
  }

  blur() {
    this.el.blur();
  }

  handleChange(e) {
    const { props } = this;
    if (props.disabled) {
      return;
    }
    if (!('checked' in props)) {
      this.setState({
        checked: e.target.checked,
      });
    }

    props.onChange({
      target: {
        ...props,
        checked: e.target.checked,
      },
      stopPropagation() {
        e.stopPropagation();
      },
      preventDefault() {
        e.preventDefault();
      },
      nativeEvent: e.nativeEvent,
    });
  }

  render() {
    const {
      prefixCls,
      className,
      classNameBox,
      indeterminate,
      name,
      id,
      type,
      disabled,
      readOnly,
      tabIndex,
      onClick,
      value,
      label,
      ...others
    } = this.props;

    const globalProps = Object.keys(others).reduce((prev, key) => {
      if (key.substr(0, 5) === 'aria-' || key.substr(0, 5) === 'data-' || key === 'role') {
        prev[key] = others[key];
      }
      return prev;
    }, {});

    const { checked } = this.state;

    const classString = classNames(prefixCls, className, {
      [`${prefixCls}-checked qa-test-${prefixCls}-checked`]: !indeterminate && checked,
      [`${prefixCls}-indeterminate`]: indeterminate,
      [`${prefixCls}-disabled`] : disabled,
    });

    return (
      <label className={`qa-test-checkbox-container ${classNameBox} ${prefixCls}-container ${label ? '' : 'tw-mr-4'}`}>
        <span className={classString} {...globalProps}>
          <input
            name={name}
            id={id}
            type={type}
            readOnly={readOnly}
            disabled={disabled}
            tabIndex={tabIndex}
            className={`${prefixCls}-input ${className}-input`}
            checked={!!checked}
            onClick={onClick}
            ref={el => this.el = el}
            onChange={this.handleChange}
            value={value}
            {...globalProps}
          />
          <span className={`${prefixCls}-inner ${className}-inner`} />
        </span>
        { label && (
          <span className={`${prefixCls}-label body-2-text`} >
            {label}
          </span>
        )}
      </label>
    );
  }

}

Checkbox.defaultProps = defaultProps;

export default Checkbox;
