import uuid from 'uuid/v4';

// Refer to this documentatin for more: https://www.npmjs.com/package/uuid
const generateRandomString = (length) => {
  if (length) {
    return uuid().toString().substring(0, length);
  }

  return uuid();
};

export { generateRandomString };
