import React, { useRef, useState, useEffect } from "react";
import { EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import LatexRenderer from "./LatexRenderer";
import { findLinkEntities } from "../rich_text_input/DraftJSUtil";
import DraftjsBlockConstants from "./../../../../constants/DraftjsBlockConstants";
// import UserHelper from "../../../../model_helpers/UserHelper";
import flattenDeep from "lodash/flattenDeep";
import PagePathConstants from "../../../../constants/router/PagePathConstants";

const DraftRenderer = ({ source, options, isStudent, creator }) => {
  const refRender = useRef();
  const plugins = [];
  const hashtagPlugin = createHashtagPlugin({
    hashtagComponent: (props) => {
      const url =  `${PagePathConstants.HOME_PAGE}?hashtag=${props.decoratedText}`;
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      );
    },
  });
  const linkifyPlugin = createLinkifyPlugin({
    target: "_blank",
    theme: { link: "tw-text-brand-primary" },
  });
  plugins.push(linkifyPlugin);

  // if (
  //   options?.enableHashtags &&
  //   (!isStudent || !UserHelper.isStudent(creator))
  // ) {
    plugins.push(hashtagPlugin);
  // }

  const decorators = flattenDeep(
    plugins.map((plugin) => plugin.decorators)
  );
  const decorator = new CompositeDecorator(
    decorators.concat({
      strategy: findLinkEntities,
      component: (props) => {
        const { url } = props.contentState
          .getEntity(props.entityKey)
          .getData();
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        );
      },
    })
  );

  const createEditorState = (source) => {
    try {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(source)),
        decorator
      );
    } catch (error) {
      return EditorState.createEmpty(decorator);
    }
  };
  const [editorState, setEditorState] = useState(createEditorState(source));

  useEffect(() => {
    setEditorState(createEditorState(source));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source])
  
  const onChange = (nextEditorState) => {
    setEditorState(EditorState.set(nextEditorState, { decorator }));
  };

  const blockRenderer = (contentBlock) => {
    const type = contentBlock.getType();

    if (type === DraftjsBlockConstants.MATH_BLOCK) {
      return {
        component: MathComponent,
        editable: false,
      };
    }
    if (type === DraftjsBlockConstants.CODE_BLOCK) {
      return {
        component: CodeComponent,
        editable: false,
      };
    }
  };

  const MathComponent = (props) => {
    const { block, offsetKey } = props;
    return (
      <span data-offset-key={offsetKey}>
        <LatexRenderer source={`$\\\\${block.text}$`} />
      </span>
    );
  };

  const CodeComponent = (props) => {
    const { block, offsetKey } = props;
    return (
      <pre data-offset-key={offsetKey} className="tw-border tw-border-[#DDD] tw-bg-[#EEE] !tw-text-black tw-p-2 tw-mx-4 tw-rounded-md tw-block tw-whitespace-pre-wrap">
        <code>{block.text}</code>
      </pre>
    );
  };

  return (
    <div className="RichEditor-root richtext-render qa-test-rich-text-render">
      <Editor
        ref={refRender}
        editorState={editorState}
        onChange={onChange}
        blockRendererFn={blockRenderer}
        plugins={plugins}
        hideToolbarOnBlur={true}
        readOnly={true}
      />
    </div>
  );
};

export default DraftRenderer;
