import { fromJS } from 'immutable';
import LocalStorageUtil from '../utils/LocalStorageUtil';

const SHOPPING_CART_KEY = 'shopping_cart';
const CHECKOUT_KEY = 'checkout';
const CHECKOUT_AMOUNT = 'checkout_amount';

class ProductHelper {

    static completeProductData(product) {
        // TODO: 获取商品数据

        if (!product) return Map({});
        let newProduct = Object.assign({}, product);
        // newProduct.skulist = newProduct.skulist && JSON.parse(newProduct.skulist);

        newProduct.skulist = typeof newProduct.skulist === 'string' ? JSON.parse(newProduct.skulist) : newProduct.skulist;
        // let formatSkuList = [];
        // for (let i = 0; i < newProduct.skulist.length; i++) {
        //     const sku = newProduct.skulist[i];
        //     if (formatSkuList.indexOf(sku.title) < 0) {
        //         formatSkuList.push(sku.title);
        //     }
        // }
        newProduct.skulist = newProduct.skulist.map((item, index) => {
            item['isOpen'] = (index === 0);
            item['selectedValue'] = item.data[0];
            return item;
        });
        // for (let i = 0; i < newProduct.skulist.length; i++) {
        //     const sku = newProduct.skulist[i];
        //     const findIndex = formatSkuList.findIndex(item => item.title === sku.title);
        //     formatSkuList[findIndex].data.push({
        //         skuid: sku.skuid,
        //         content: sku.content,
        //         originalPrice: sku.originalPrice,
        //         currentPrice: sku.currentPrice,
        //     });
        // }
        // formatSkuList = formatSkuList.map((item) => {
        //     item['selectedValue'] = item.data[0];
        //     return item;
        // });

        // newProduct.skulist = formatSkuList;
        return fromJS(newProduct);
    }
    

    static addProductToShoppingCart(product) {
        let shoppingCart = LocalStorageUtil.getItem(SHOPPING_CART_KEY);
        if (!shoppingCart) {
            LocalStorageUtil.setItem(SHOPPING_CART_KEY, JSON.stringify([product]));
            return [product];
        }
        shoppingCart = JSON.parse(shoppingCart);
        let hasSameProduct = false, sameProductIndex = -1;
        for (let i = 0; i < shoppingCart.length; i++) {
            let p = shoppingCart[i];
            if (p.id === product.id) {
                let hasSameSku = true;
                for (let j = 0; j < p.skulist.length; j++) {
                    if (p.skulist[j].selectedValue !== product.skulist[j].selectedValue) {
                        hasSameSku = false;
                        break;
                    }
                }
                if (hasSameSku) {
                    hasSameProduct = true;
                    sameProductIndex = i;
                    break;
                } else {
                    continue;
                }

            } else {
                continue;
            }
        }

        if (hasSameProduct && sameProductIndex >= 0) {
            shoppingCart[sameProductIndex].quantity += (product.quantity);
        } else {
            shoppingCart.push(product);
        }
        LocalStorageUtil.setItem(SHOPPING_CART_KEY, JSON.stringify(shoppingCart));
        return shoppingCart;
    }

    static calcShoppingCartAmount() {
        let shoppingCart = LocalStorageUtil.getItem(SHOPPING_CART_KEY);
        if (!shoppingCart) {
            return { subtotal: 0, discount: 0 };
        }
        shoppingCart = JSON.parse(shoppingCart);
        let subtotal = 0, discount = 0;
        shoppingCart.map(item => {
            let quantity = parseInt(item.quantity);
            let currentPrice = parseFloat(item.currentPrice), originalPrice = parseFloat(item.originalPrice);
            subtotal = parseFloat(parseFloat(subtotal) + parseFloat(quantity * currentPrice)).toFixed(2);
            discount = parseFloat(parseFloat(discount) + parseFloat((originalPrice - currentPrice) * quantity)).toFixed(2);
        })
        return { subtotal, discount };
    }

    static getShoppingCartData() {
        let shoppingCart = LocalStorageUtil.getItem(SHOPPING_CART_KEY);
        if (!shoppingCart) return [];
        return JSON.parse(shoppingCart)
    }

    static updateProductQuantity(quantity, index) {
        let shoppingCart = LocalStorageUtil.getItem(SHOPPING_CART_KEY);
        if (!shoppingCart) return [];
        shoppingCart = JSON.parse(shoppingCart);
        shoppingCart[index].quantity = quantity;
        LocalStorageUtil.setItem(SHOPPING_CART_KEY, JSON.stringify(shoppingCart));
        return shoppingCart;
    }

    static removeProductFromShoppingCart(index) {
        let shoppingCart = LocalStorageUtil.getItem(SHOPPING_CART_KEY);
        if (!shoppingCart) return [];
        shoppingCart = JSON.parse(shoppingCart);
        shoppingCart.splice(index, 1);
        LocalStorageUtil.setItem(SHOPPING_CART_KEY, JSON.stringify(shoppingCart));
        return shoppingCart;
    }

    static saveCheckoutProductList(isFromShoppingCart = true, newProduct) {
        if (isFromShoppingCart) {
            let shoppingCartData = this.getShoppingCartData();
            LocalStorageUtil.setItem(CHECKOUT_KEY, JSON.stringify(shoppingCartData));
            return;
        }
        LocalStorageUtil.setItem(CHECKOUT_KEY, JSON.stringify([newProduct]));
    }

    static getCheckoutProductData() {
        let checkoutData = LocalStorageUtil.getItem(CHECKOUT_KEY);
        if (!checkoutData) return [];
        return JSON.parse(checkoutData);
    }

    static calcCheckoutAmount(isFromCart) {
        let productArr = isFromCart ? LocalStorageUtil.getItem(SHOPPING_CART_KEY) : LocalStorageUtil.getItem(CHECKOUT_KEY);
        if (!productArr) {
            return { subtotal: 0, discount: 0 };
        }
        productArr = JSON.parse(productArr);
        let subtotal = 0, discount = 0;
        productArr.map(item => {
            let quantity = parseInt(item.quantity);
            let currentPrice = parseFloat(item.currentPrice), originalPrice = parseFloat(item.originalPrice);
            subtotal = parseFloat(parseFloat(subtotal) + parseFloat(quantity * currentPrice)).toFixed(2);
            discount = parseFloat(parseFloat(discount) + parseFloat((originalPrice - currentPrice) * quantity)).toFixed(2);
        })
        return { subtotal, discount };
    }

    static setCheckoutAmount(amount) {
        LocalStorageUtil.setItem(CHECKOUT_AMOUNT, amount);
    }

    static getCheckoutAmount() {
        return LocalStorageUtil.getItem(CHECKOUT_AMOUNT);
    }

}

export default ProductHelper;
