import React from "react";
import Colors from '../constants/ColorConstants';

const RdsBackIcon = ({
  size = 32,
  viewBox = "0 0 32 32",
  color =  Colors.brand.primary,
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox={viewBox}
    className={className}
    fill="none"
    {...props}
  >
    <path d="M8.107 16.505c.063.164.158.313.28.44l6.666 6.667a1.34 1.34 0 0 0 1.894-1.893l-4.4-4.387h10.12a1.334 1.334 0 0 0 0-2.667h-10.12l4.4-4.386a1.336 1.336 0 0 0-.434-2.186 1.334 1.334 0 0 0-1.46.292l-6.666 6.667a1.333 1.333 0 0 0-.28.44 1.333 1.333 0 0 0 0 1.013z" fill={color}/>
  </svg>
);

export default RdsBackIcon;
