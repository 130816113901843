// import { EnvConfig } from "../config/env";

/*
* Provides thin wrapper around 3rd party dynamic 'script' tag creation (these in turn usually fetch external JSSDK's)
* or dynamic 'link' tag creation to request 3rd party stylesheets
* use when finer control needed over when requests for resources are made - e.g. on demand not as page loads.
* note: fb and twitter already guard against creating multiple script tags -
* so exported wrapper methods can be called safely multiple times
*/

class ResourceLoader {

  // KaTex Styles
  static getKaTextStylesheet() {
    (function(d, n, id){
      let newNode, firstNode = d.getElementsByTagName(n)[0];
      if (d.getElementById(id)) {return;}
      newNode = d.createElement(n); newNode.id = id; newNode.rel = 'stylesheet';
      newNode.href = '//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.11.1/katex.min.css';
      firstNode.parentNode.insertBefore(newNode, firstNode);
    }(document,'link','katex-styles'))
  }

  

  // Twitter JSSDK wrapper
  static getTwitterSDK() {
    window.twttr = (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
              t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function(f) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "twitter-wjs"));
  }
}

export default ResourceLoader;
