import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const initialState = Map({
  [StoreStateConstants.GLOBAL_ALERT]: Map(),
});

export const GlobalAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.SHOW_GLOBAL_ALERT: {
      const globalAlert = Map(action.payload);
      return state.set(StoreStateConstants.GLOBAL_ALERT, globalAlert);
    }
    default:
      return state;
  }
};
