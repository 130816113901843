import React, { Component } from 'react';
import { Input } from 'reactstrap';
import XIcon from '../../../icons/XIcon';
import LogisticsIcon from '../../../icons/LogisticsIcon';
import StarIcon from '../../../icons/StarIcon';
import QuantityController from '../../../components/common/quantity/index';
import CustomButton from '../../../components/common/button/index';
import PagePathConstants from '../../../constants/router/PagePathConstants';
import RDSButton from '../../../components/common/RDSButton';
import { Progress } from 'reactstrap';
import classNames from 'classnames';
import ProductHelper from '../../../helpers/ProductHelper';
import { BASE_URL } from '../../../constants/api/ApiEndpointConstants';
import MobileDetectUtil from '../../../utils/MobileDetectUtil';
import ShoppingCartEmptyIcon from '../../../icons/ShoppingCartEmptyIcon';

class ShoppingCartModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            productTagList: []
        }
        this.closeShoppingCartModal = this.closeShoppingCartModal.bind(this);
        this.renderSubtotalComponent = this.renderSubtotalComponent.bind(this);
        this.renderRemoveBtn = this.renderRemoveBtn.bind(this);
        this.routeToCheckoutPage = this.routeToCheckoutPage.bind(this);
        this.updateShoppingCartData = this.updateShoppingCartData.bind(this);
        this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
        this.routeToProductDetailPage = this.routeToProductDetailPage.bind(this);
    }

    componentDidMount() {
        this.fetchProductTagList();
    }

    fetchProductTagList() {
        const { tagActions, productActions } = this.props;
        tagActions && tagActions.fetchProductTagList(0)
            .then((tagList) => {
                this.setState({ productTagList: tagList });
                tagList.map((tag, index) => {
                    productActions && productActions.fetchProductsByTagId(tag.id)
                        .then((productList) => {
                            let { productTagList } = this.state;
                            if (productList.length > 0 && productTagList[index]) {
                                productTagList[index]['productList'] = productList;
                                this.setState({ productTagList });
                            }
                        })
                })
            })
    }

    closeShoppingCartModal() {
        const { toggleShoppingCartModal } = this.props;
        toggleShoppingCartModal && toggleShoppingCartModal();
    }

    routeToCheckoutPage() {
        ProductHelper.saveCheckoutProductList(true);
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.CHECKOUT_PAGE, search: location.search && location.search !== '' ? `${location.search}&fromCart=true` : '?fromCart=true'});
    }

    renderSubtotalComponent() {
        let { subtotal, discount } = ProductHelper.calcShoppingCartAmount();
        const { fromPage, shoppingCartArr } = this.props;
        return (shoppingCartArr && shoppingCartArr.length > 0 && <div className={classNames('tw-py-[20px] phone:tw-w-full', { 'tw-mt-[48px] tw-px-[20px] tw-shadow-md tw-w-[375px] tw-rounded-[15px] tw-h-auto': fromPage === 'cart' })}>
            <div className='tw-flex tw-justify-between tw-items-center tw-text-body-text-1'>
                <div className='tw-text-[#121b17b3]'>Discount</div>
                <div>-${discount}</div>
            </div>
            <div className='tw-mt-[15px] tw-flex tw-justify-between tw-items-center tw-text-body-text-1'>
                <div className=''>Subtotal</div>
                <div>${subtotal}</div>
            </div>
            <div className='tw-mt-[10px] tw-text-body-text-1-translucence tw-text-center'>Taxes and shipping calculated at checkout</div>
            <RDSButton rectangle className="tw-mt-[20px] tw-w-full tw-h-[50px] tw-flex tw-justify-center tw-text-white tw-rounded-btn" onClick={this.routeToCheckoutPage} >
                Check out
            </RDSButton>
        </div>);
    }

    renderRemoveBtn(index) {
        return (<div className=' tw-flex tw-items-center tw-text-body-text-1-translucence' onClick={() => this.handleRemoveProduct(index)}>
            <XIcon className="tw-mr-[6px]" size={24} color='#121B17CC' />
            <span>Remove</span>
        </div>)
    }

    handleRemoveProduct(index) {
        let { shoppingCartActions } = this.props;
        shoppingCartActions && shoppingCartActions.removeProductFromShoppingCart(index);
    }

    updateShoppingCartData(quantity, index) {
        let { shoppingCartActions } = this.props;
        shoppingCartActions && shoppingCartActions.updateProductQuantity(quantity, index);
    }

    routeToProductDetailPage(id) {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.PRODUCT_DETAIL.replace(':id', id), search: location.search});
    }


    render() {
        const { showModal, fromPage, baseConfig, shoppingCartArr } = this.props;
        const { productTagList } = this.state;
        const formatProductTagList = productTagList && productTagList.filter(tag => tag.productList && tag.productList.length > 0);
        const { subtotal } = ProductHelper.calcShoppingCartAmount();
        const freeShippingAmount = baseConfig && parseFloat(baseConfig.freeShippingAmount);
        let isFreeShipping = (subtotal >= freeShippingAmount);
        const isMobile = MobileDetectUtil.isMobile();
        return (
            <div className={classNames('tw-w-full tw-h-full SFNS-font', { 'tw-bg-[rgba(0,0,0,0.5)] tw-fixed tw-top-0 tw-left-0 tw-z-[1000]': fromPage === 'productDetail' })} style={{ display: (showModal ? 'block' : 'none') }} >
                <div className={classNames({ 'tw-w-full tw-h-full tw-flex tw-justify-between tw-items-start phone:tw-flex-wrap': fromPage === 'cart', '!tw-justify-center': shoppingCartArr && shoppingCartArr.length === 0 })}>
                    <div className={classNames('tw-bg-white tw-px-[24px] tw-py-[24px] tw-text-body-text-1', { 'tw-border tw-w-[468px] phone:tw-w-full !tw-h-full tw-absolute tw-right-0 tw-top-0': fromPage === 'productDetail' }, { 'tw-w-[788px] tw-min-h-full !tw-px-0': fromPage === 'cart' })}>
                        {fromPage === 'productDetail' && <div className='tw-w-full tw-h-[36px] tw-flex tw-justify-end tw-items-center'>
                            <div className='tw-w-[36px] tw-h-[36px] tw-bg-[rgb(246,246,246)] tw-rounded-full tw-flex tw-justify-center tw-items-center'>
                                <XIcon size={24} className="tw" color='#121B17' onClick={this.closeShoppingCartModal} />
                            </div>
                        </div>}
                        {shoppingCartArr && shoppingCartArr.length > 0 && <div className='tw-w-full tw-h-[70px] phone:tw-h-auto tw-my-[24px] tw-px-[16px] tw-py-[16px]  tw-bg-[rgba(248,248,248,0.8)] tw-rounded-[10px]'>
                            <div className='tw-flex tw-items-center'>
                                <LogisticsIcon size={18} />
                                <div className='tw-ml-[10px] phone:tw-text-[14px]'>{isFreeShipping ? 'Congratulations! Free shipping for your order' : `Spend another ${parseFloat(freeShippingAmount - subtotal).toFixed(2)} and get free shipping!`}</div>
                            </div>
                            <div className='tw-w-full tw-mt-[10px]'>
                                <Progress value={isFreeShipping ? 100 : (subtotal / freeShippingAmount) * 100} className='tw-h-[5px] shopping-cart-progress-container' />
                            </div>
                        </div>}
                        <div className='tw-w-full tw-h-[calc(100%-106px)] tw-overflow-y-scroll scrollbar-hidden'>
                            <div className='tw-w-full tw-font-[500] tw-text-[20px]'>Shopping cart</div>
                            {shoppingCartArr && shoppingCartArr.length > 0 &&
                                <React.Fragment>
                                    {shoppingCartArr.map((item, index) => {
                                        const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);
                                        return (<div className='tw-w-full tw-h-auto tw-py-[20px] tw-border-b tw-bg-line-gray' key={index}>
                                            <div className='tw-flex tw-justify-between tw-mb-[16px]'>
                                                <div className='tw-flex tw-justify-center tw-items-center tw-w-[88px] tw-h-[104px] tw-rounded-[10px] tw-overflow-hidden'>
                                                    <div className='tw-w-full tw-h-full' style={{ backgroundImage: `url(${img})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                                </div>
                                                <div className='tw-w-[calc(100%-88px)] tw-h-[104px] tw-pl-[16px] tw-text-body-text-1-translucence tw-text-[14px] tw-flex tw-flex-col tw-justify-between'>
                                                    <div>
                                                        <div className='tw-line-clamp-1'>{item.title}</div>
                                                        <div className='tw-mt-[5px] tw-flex tw-items-center'>
                                                            {item.skulist && item.skulist.map(sku => {
                                                                return <div className='tw-mr-[20px]'><span className='tw-font-[600] tw-mr-[5px]'>{sku.title}:</span>{sku.selectedValue.content}</div>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='tw-w-full tw-flex tw-justify-between tw-items-center'>
                                                        <div className='tw-flex tw-items-center'>
                                                            <QuantityController index={index} addCallback={this.updateShoppingCartData} subCallback={this.updateShoppingCartData} quantity={item.quantity} className="!tw-px-[5px] !tw-py-[5px] !tw-w-[104px] tw-mr-[25px]" />
                                                            {fromPage === 'cart' && !isMobile && this.renderRemoveBtn(index)}
                                                        </div>
                                                        <span className='tw-text-[18px]'>${item.currentPrice}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {(fromPage === 'productDetail' || isMobile) && this.renderRemoveBtn()}
                                        </div>)
                                    })}
                                </React.Fragment>}
                            {shoppingCartArr && shoppingCartArr.length === 0 && <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-my-[15px]'>
                                <div className='tw-w-full tw-flex tw-flex-col tw-items-center'>
                                    <ShoppingCartEmptyIcon size={108} />
                                    <span className={classNames('tw-text-[18px] tw-text-[#333] tw-mt-[40px]', {'!tw-text-[26px]': fromPage === 'cart'})}>Your shopping cart is empty</span>
                                </div>
                            </div>}

                            {(fromPage === 'productDetail' || (fromPage === 'cart' && isMobile)) && this.renderSubtotalComponent()}


                            <div className={classNames('tw-w-full', { 'tw-mt-[44px]': fromPage === 'cart' })}>
                                <div className={classNames('tw-text-[26px] tw-text-body-text-1', { 'tw-text-[32px]': fromPage === 'cart' })}>You might also like</div>
                                <div className='tw-mt-[20px] tw-w-full tw-h-full tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                    {formatProductTagList && formatProductTagList.map(tag => {
                                        return tag.productList && tag.productList.map((item, index) => {
                                            const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);
                                            const originalPrice = parseFloat(item.originalPrice);
                                            const currentPrice = parseFloat(item.currentPrice);
                                            const discount = Math.floor(((originalPrice - currentPrice) / originalPrice) * 100);

                                            return (<div className={classNames("tw-w-[48%] phone:tw-w-[47%] tw-overflow-hidden tw-mb-[30px]", { '!tw-w-[23%] !tw-mb-[50px]': fromPage === 'cart' && !isMobile })} key={item.id} onClick={() => this.routeToProductDetailPage(item.id)}>
                                                <div className='tw-overflow-hidden tw-w-full tw-rounded-[15px] '>
                                                    <div className='tw-w-full tw-pb-[100%] tw-relative product-img' style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} >
                                                        <div className='tw-absolute tw-top-[10px] tw-left-[10px] tw-font-[600] tw-text-white tw-font-[20px] tw-px-[10px] tw-py-[3px] tw-rounded-[8px] tw-bg-brand-primary'>{discount}% OFF</div>
                                                    </div>
                                                </div>


                                                <div className='tw-mt-[10px] tw-h-[50px] tw-line-clamp-2 tw-opacity-[0.8] tw-text-[15px]'>{item.title}</div>
                                                <div className='tw-flex tw-items-center tw-my-[5px]'>
                                                    {new Array(item.star).fill(undefined).map((item, index) => {
                                                        return <StarIcon key={index} className="tw-mr-[2px]" size={20} />
                                                    })}
                                                </div>
                                                <div className='tw-flex tw-justify-between tw-items-center'>
                                                    <div className='tw-line-through'>${originalPrice}</div>
                                                    <div className='tw-text-light-red tw-text-[20px]'>${currentPrice}</div>
                                                </div>
                                            </div>)
                                        })

                                    })}
                                </div>


                            </div>
                        </div>

                    </div>
                    {fromPage === 'cart' && !isMobile && this.renderSubtotalComponent()}
                </div>

            </div>
        );
    }
}

export default ShoppingCartModal;
