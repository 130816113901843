import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectProductDetailReducer  = state => state.get(ReducerNameConstants.PRODUCT_DETAIL);

export const selectCurrentProduct = createSelector(
  [selectProductDetailReducer],
  (productReducer) => {
    const currentProduct = productReducer && productReducer.get(StoreStateConstants.CURRENT_PRODUCT);
    return currentProduct?.toJS();
  },
);