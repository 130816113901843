

import React from 'react';
import Colors from '../../constants/ColorConstants';

const BNBTIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 50 50" {...props}>
        <title>Icons Crypto/Binance coin</title>
        <g id="Icons-Crypto/Binance-coin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M25,0 C38.8071238,0 50,11.1928762 50,25 C50,38.8071238 38.8071238,50 25,50 C11.1928762,50 0,38.8071238 0,25 C0,11.1928762 11.1928762,0 25,0 Z M31.4479161,27.6937417 L25.0041744,34.1374834 L22.7416578,31.8750333 L22.3250133,31.4583222 L18.5312716,27.6937417 L14.7812716,31.4437417 L25.0062383,41.6520639 L35.1979161,31.4437417 L31.4479161,27.6937417 Z M12.0987017,21.2388482 L8.33332224,25.0041611 L12.0987017,28.7695406 L15.8640146,25.0041611 L12.0987017,21.2388482 Z M37.9028695,21.2367044 L34.13749,25.0020839 L37.9028695,28.7674634 L41.6681824,25.0020839 L37.9028695,21.2367044 Z M25.0112051,21.2283822 L21.2458256,24.9937617 L25.0112051,28.7591411 L28.776518,24.9937617 L25.0112051,21.2283822 Z M25.0062184,8.33328895 L14.7812517,18.5416778 L18.5312517,22.3145806 L22.3166711,18.5416778 L23.1146072,17.7437417 L25.0062184,15.8520639 L31.4478961,22.3145806 L35.1978961,18.5416778 L25.0062184,8.33328895 Z" id="Combined-Shape" fill="#F0B90B" />
        </g>
    </svg>
);

export default BNBTIcon