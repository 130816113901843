import React, { Component } from 'react';
import PropTypes from 'prop-types';

// === Components === //
import { Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input } from 'reactstrap';
// import WithTranslationsFactory from '../../../../components/factories/WithTranslationsFactory'; 

// === Utils === //
import noop from 'lodash/noop';
import trim from 'lodash/trim';
import UrlUtil from '../../../../utils/UrlUtil';
import RDSButton from '../../RDSButton';

import './AddLinkModal.scss';
import I18N from '../../../../i18n';

class AddLinkModal extends Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(event) {
    event.preventDefault();
    const { target } = event;
    const { link, text } = target;
    const { onSubmit } = this.props;
    //TODO: 
    onSubmit(trim(text.value), trim(UrlUtil.isHttpInUrl(link.value) ? link.value : UrlUtil.addHttpToUrl(link.value)));
  }

  render() {
    const { translate, isOpen, toggle, defaultText, defaultUrl } = this.props

    return <Modal zIndex={1100} isOpen={isOpen} className="rich-add-link-modal">
      <ModalHeader toggle={toggle} >
        {I18N.getText('add-link')}
      </ModalHeader>
      <Form onSubmit={this.onFormSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="text">
              {I18N.getText('title')}
            </Label>
            <Input
              placeholder={I18N.getText('please-select-resource-title')}
              required name="text" type="text" defaultValue={defaultText} className="qa-test-addLinkModal-title"
            />
          </FormGroup>
          <FormGroup>
            <Label for="link">URL/ &lt;Embed&gt;</Label>
            <Input
              placeholder={I18N.getText('add-link')}
              required name="link" type="text" defaultValue={defaultUrl} className="qa-test-addLinkModal-link"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <RDSButton
            qaSelector="qa-test-add-link-cancel-btn add-link-cancel-btn"
            onClick={toggle}
            color="secondary"
            className="tw-text-brand-primary"
          >
            {I18N.getText('cancel')}
          </RDSButton>
          <RDSButton
            type="submit"
            weight="loud"
            qaSelector="qa-test-add-link-save-btn add-link-save-btn"
            className="tw-text-white"
          >
            {I18N.getText('save')}
          </RDSButton>
        </ModalFooter>
      </Form>
    </Modal>
  }
}

AddLinkModal.propTypes = {
  defaultText: PropTypes.string.isRequired,
  defaultUrl: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};

AddLinkModal.defaultProps = {
  defaultUrl: '',
  toggle: noop,
  isOpen: false,
  onSubmit: noop,
};

export default AddLinkModal;

