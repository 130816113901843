import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Sizes = {
  sm: 'tw-shadow-md tw-rounded-sm',
  md: 'tw-shadow-md tw-rounded-md',
  lg: 'tw-shadow-lg tw-rounded-lg',
  xl: 'tw-shadow-xl tw-rounded-2.5xl',
}

const RDSPopover = ({
  mask = false,
  className,
  classNameBtn,
  classNamePanel,
  classNameTransition,
  qaSelector,
  children,
  hasArrow = true,
  bgClassName = 'tw-bg-background-2',
  placement = 'auto',
  modifiers = [],
  strategy = 'absolute',
  enableCtrl = null,
  disabled,
  size = 'sm',
  transtion = true, 
  buttonAs = 'div',
  refreshing,
}) => {
  const [refEle, setRefEle]       = useState(null);
  const [popperEle, setPopperEle] = useState(null);
  const {styles, attributes, update}      = usePopper(refEle, popperEle, {
    placement: placement,
    modifiers,
    strategy,
  });

  useEffect(() => {
    update && update()
  }, [update, refreshing])

  if (!Array.isArray(children)) {
    return 'oops, error children props';
  }

  const transitionShowProps = enableCtrl && {
    show: enableCtrl.isOpen
  }

  const popoverButtonClickProps = enableCtrl && {
    onClick: enableCtrl.toggle
  }

  const classes = classNames(
    Sizes[size],
    classNamePanel,
    bgClassName,
    qaSelector,
    "tw-z-50",
  );


  const transitionProps = transtion && {
    enter: "tw-transition tw-ease-out tw-duration-200",
    enterFrom: "tw-opacity-0",
    enterTo: "tw-opacity-100",
    leave: "tw-transition tw-ease-in tw-duration-150",
    leaveFrom: "tw-opacity-100",
    leaveTo: "tw-opacity-0",
  }

  return (
    <Popover as='span' className={className}>
      <Popover.Button as={buttonAs} disabled={disabled} className={classNameBtn} ref={setRefEle} {...popoverButtonClickProps}>{children[0]}</Popover.Button>
      {mask && <Popover.Overlay className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-30" />}
      {
        !disabled &&
        <Transition
          {...transitionShowProps}
          {...transitionProps}
          className={classNameTransition}
        >
          <Popover.Panel
            static={enableCtrl ? true : false}
            className={classes}
            ref={setPopperEle}
            style={styles.popper}
            {...attributes.popper}>

            {children[1]}
            {hasArrow && <div data-popper-arrow className={bgClassName} />}
          </Popover.Panel>
        </Transition>
      }

    </Popover>
  );
};

// type Placement =
// | 'auto'
// | 'auto-start'
// | 'auto-end'
// | 'top'
// | 'top-start'
// | 'top-end'
// | 'bottom'
// | 'bottom-start'
// | 'bottom-end'
// | 'right'
// | 'right-start'
// | 'right-end'
// | 'left'
// | 'left-start'
// | 'left-end';
RDSPopover.propTypes = {
  children       : PropTypes.node, // must be a array
  qaSelector     : PropTypes.string,
  mask           : PropTypes.bool,
  className      : PropTypes.string,
  classNameBtn   : PropTypes.string,
  classNamePanel : PropTypes.string,
  hasArrow       : PropTypes.bool,
  bgClassName    : PropTypes.string,
  placement      : PropTypes.string,
  modifiers      : PropTypes.array,
  enableCtrl     : PropTypes.object,
  disabled       : PropTypes.bool,
  size           : PropTypes.string,
  buttonAs       : PropTypes.string,
  refreshing     : PropTypes.any, // any value to indicate position updation is required after it's changed
};

export default RDSPopover;
export const RDSPopoverBtn = Popover.Button;
