import React from 'react';
import Colors from '../constants/ColorConstants';

const AddIcon = ({
  size = 24,
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="2280" width={size} height={size} {...props}>
    <path d="M512 832a32 32 0 0 0 32-32v-256h256a32 32 0 0 0 0-64h-256V224a32 32 0 0 0-64 0v256H224a32 32 0 0 0 0 64h256v256a32 32 0 0 0 32 32" fill={color} p-id="2281"></path>
  </svg>
);

export default AddIcon