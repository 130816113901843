import { parseQuerystring } from '../utils/Request';

let exec = function (code, options) {
    let mixpanel = window.mixpanel;
    if (typeof mixpanel != 'undefined') {
        const url = window.location.hash;
        const queryParams = parseQuerystring(url.substring(url.indexOf('?')));
        const ad = queryParams.ad;
        let tmp = {
            url: window.location.protocol + "//" + window.location.host,
            ad
        };
        let myOptions = Object.assign({}, options, tmp);
        mixpanel.track && mixpanel.track(code, myOptions);
    }
};
const tracker = {
    trackCheckoutPage: function() {
        exec('Route to the checkout page');
    },
    addPaymentInfo: function(paymentInfo) {
        exec('User initiated payment', paymentInfo);
    },
    trackHomePage: function() {
        exec('Route to the home page');
    },
    trackCategoryPage: function(categoryInfo) {
        exec('Route to the category page', categoryInfo);
    },
    trackProductDetailPage: function(productInfo) {
        exec('Route to the product detail page', productInfo);
    },
    addToCart:  function(productInfo) {
        exec('Add product to cart', productInfo);
    },
    error: function (msg) {
        exec('ERROR', {msg});
    },
};
window.tracker = tracker;
export default tracker;
