export const getCountryNames = (translate) => {
  return {
    'AF': translate('ctry-name-Afghanistan', 'Afghanistan'),
    'AL': translate('ctry-name-Albania', 'Albania'),
    'DZ': translate('ctry-name-Algeria', 'Algeria'),
    'AS': translate('ctry-name-AmericanSamoa', 'American Samoa'),
    'AD': translate('ctry-name-Andorra', 'Andorra'),
    'AO': translate('ctry-name-Angola', 'Angola'),
    'AI': translate('ctry-name-Anguilla', 'Anguilla'),
    'AG': translate('ctry-name-AntiguaandBarbuda', 'Antigua and Barbuda'),
    'AR': translate('ctry-name-Argentina', 'Argentina'),
    'AM': translate('ctry-name-Armenia', 'Armenia'),
    'AW': translate('ctry-name-Aruba', 'Aruba'),
    'AU': translate('ctry-name-Australia', 'Australia'),
    'AT': translate('ctry-name-Austria', 'Austria'),
    'AZ': translate('ctry-name-Azerbaijan', 'Azerbaijan'),
    'BS': translate('ctry-name-Bahamas', 'Bahamas'),
    'BH': translate('ctry-name-Bahrain', 'Bahrain'),
    'BD': translate('ctry-name-Bangladesh', 'Bangladesh'),
    'BB': translate('ctry-name-Barbados', 'Barbados'),
    'BY': translate('ctry-name-Belarus', 'Belarus'),
    'BE': translate('ctry-name-Belgium', 'Belgium'),
    'BZ': translate('ctry-name-Belize', 'Belize'),
    'BJ': translate('ctry-name-Benin', 'Benin'),
    'BM': translate('ctry-name-Bermuda', 'Bermuda'),
    'BT': translate('ctry-name-Bhutan', 'Bhutan'),
    'BO': translate('ctry-name-BoliviaPlurinationalStateof', 'Bolivia, Plurinational State of'),
    'BA': translate('ctry-name-BosniaandHerzegovina', 'Bosnia and Herzegovina'),
    'BW': translate('ctry-name-Botswana', 'Botswana'),
    'BR': translate('ctry-name-Brazil', 'Brazil'),
    'IO': translate('ctry-name-BritishIndianOceanTerritory', 'British Indian Ocean Territory'),
    'BG': translate('ctry-name-Bulgaria', 'Bulgaria'),
    'BF': translate('ctry-name-BurkinaFaso', 'Burkina Faso'),
    'BI': translate('ctry-name-Burundi', 'Burundi'),
    'KH': translate('ctry-name-Cambodia', 'Cambodia'),
    'CM': translate('ctry-name-Cameroon', 'Cameroon'),
    'CA': translate('ctry-name-Canada', 'Canada'),
    'CV': translate('ctry-name-CapeVerde', 'Cape Verde'),
    'KY': translate('ctry-name-CaymanIslands', 'Cayman Islands'),
    'CF': translate('ctry-name-CentralAfricanRepublic', 'Central African Republic'),
    'TD': translate('ctry-name-Chad', 'Chad'),
    'CL': translate('ctry-name-Chile', 'Chile'),
    'CN': translate('ctry-name-China', 'China'),
    'CO': translate('ctry-name-Colombia', 'Colombia'),
    'KM': translate('ctry-name-Comoros', 'Comoros'),
    'CG': translate('ctry-name-Congo', 'Congo'),
    'CD': translate('ctry-name-DemocraticRepublicoftheCongo', 'Democratic Republic of the Congo'),
    'CK': translate('ctry-name-CookIslands', 'Cook Islands'),
    'CR': translate('ctry-name-CostaRica', 'Costa Rica'),
    'CI': translate('ctry-name-CotedIvoire', 'Côte d\'Ivoire'),
    'HR': translate('ctry-name-Croatia', 'Croatia'),
    'CU': translate('ctry-name-Cuba', 'Cuba'),
    'CW': translate('ctry-name-Curaçao', 'Curaçao'),
    'CY': translate('ctry-name-Cyprus', 'Cyprus'),
    'CZ': translate('ctry-name-CzechRepublic', 'Czech Republic'),
    'DK': translate('ctry-name-Denmark', 'Denmark'),
    'DJ': translate('ctry-name-Djibouti', 'Djibouti'),
    'DM': translate('ctry-name-Dominica', 'Dominica'),
    'DO': translate('ctry-name-DominicanRepublic', 'Dominican Republic'),
    'EC': translate('ctry-name-Ecuador', 'Ecuador'),
    'EG': translate('ctry-name-Egypt', 'Egypt'),
    'SV': translate('ctry-name-ElSalvador', 'El Salvador'),
    'GQ': translate('ctry-name-EquatorialGuinea', 'Equatorial Guinea'),
    'ER': translate('ctry-name-Eritrea', 'Eritrea'),
    'EE': translate('ctry-name-Estonia', 'Estonia'),
    'ET': translate('ctry-name-Ethiopia', 'Ethiopia'),
    'FK': translate('ctry-name-FalklandIslandsMalvinas', 'Falkland Islands (Malvinas)'),
    'FO': translate('ctry-name-FaroeIslands', 'Faroe Islands'),
    'FJ': translate('ctry-name-Fiji', 'Fiji'),
    'FI': translate('ctry-name-Finland', 'Finland'),
    'FR': translate('ctry-name-France', 'France'),
    'PF': translate('ctry-name-FrenchPolynesia', 'French Polynesia'),
    'GA': translate('ctry-name-Gabon', 'Gabon'),
    'GM': translate('ctry-name-Gambia', 'Gambia'),
    'GE': translate('ctry-name-Georgia', 'Georgia'),
    'DE': translate('ctry-name-Germany', 'Germany'),
    'GH': translate('ctry-name-Ghana', 'Ghana'),
    'GI': translate('ctry-name-Gibraltar', 'Gibraltar'),
    'GR': translate('ctry-name-Greece', 'Greece'),
    'GL': translate('ctry-name-Greenland', 'Greenland'),
    'GD': translate('ctry-name-Grenada', 'Grenada'),
    'GU': translate('ctry-name-Guam', 'Guam'),
    'GT': translate('ctry-name-Guatemala', 'Guatemala'),
    'GG': translate('ctry-name-Guernsey', 'Guernsey'),
    'GN': translate('ctry-name-Guinea', 'Guinea'),
    'GW': translate('ctry-name-GuineaBissau', 'Guinea-Bissau'),
    'HT': translate('ctry-name-Haiti', 'Haiti'),
    'HN': translate('ctry-name-Honduras', 'Honduras'),
    'HK': translate('ctry-name-HongKong', 'Hong Kong'),
    'HU': translate('ctry-name-Hungary', 'Hungary'),
    'IS': translate('ctry-name-Iceland', 'Iceland'),
    'IN': translate('ctry-name-India', 'India'),
    'ID': translate('ctry-name-Indonesia', 'Indonesia'),
    'IR': translate('ctry-name-IranIslamicRepublicof', 'Iran, Islamic Republic of'),
    'IQ': translate('ctry-name-Iraq', 'Iraq'),
    'IE': translate('ctry-name-Ireland', 'Ireland'),
    'IM': translate('ctry-name-IsleofMan', 'Isle of Man'),
    'IL': translate('ctry-name-Israel', 'Israel'),
    'IT': translate('ctry-name-Italy', 'Italy'),
    'JM': translate('ctry-name-Jamaica', 'Jamaica'),
    'JP': translate('ctry-name-Japan', 'Japan'),
    'JE': translate('ctry-name-Jersey', 'Jersey'),
    'JO': translate('ctry-name-Jordan', 'Jordan'),
    'KZ': translate('ctry-name-Kazakhstan', 'Kazakhstan'),
    'KE': translate('ctry-name-Kenya', 'Kenya'),
    'KI': translate('ctry-name-Kiribati', 'Kiribati'),
    'KP': translate('ctry-name-NorthKorea', 'North Korea'),
    'KR': translate('ctry-name-SouthKorea', 'South Korea'),
    'KW': translate('ctry-name-Kuwait', 'Kuwait'),
    'KG': translate('ctry-name-Kyrgyzstan', 'Kyrgyzstan'),
    'LA': translate('ctry-name-LaoPeoplesDemocraticRepublic', 'Lao People\'s Democratic Republic'),
    'LV': translate('ctry-name-Latvia', 'Latvia'),
    'LB': translate('ctry-name-Lebanon', 'Lebanon'),
    'LS': translate('ctry-name-Lesotho', 'Lesotho'),
    'LR': translate('ctry-name-Liberia', 'Liberia'),
    'LY': translate('ctry-name-Libya', 'Libya'),
    'LI': translate('ctry-name-Liechtenstein', 'Liechtenstein'),
    'LT': translate('ctry-name-Lithuania', 'Lithuania'),
    'LU': translate('ctry-name-Luxembourg', 'Luxembourg'),
    'MO': translate('ctry-name-Macao', 'Macao'),
    'MK': translate('ctry-name-RepublicofMacedonia', 'Republic of Macedonia'),
    'MG': translate('ctry-name-Madagascar', 'Madagascar'),
    'MW': translate('ctry-name-Malawi', 'Malawi'),
    'MY': translate('ctry-name-Malaysia', 'Malaysia'),
    'MV': translate('ctry-name-Maldives', 'Maldives'),
    'ML': translate('ctry-name-Mali', 'Mali'),
    'MT': translate('ctry-name-Malta', 'Malta'),
    'MH': translate('ctry-name-MarshallIslands', 'Marshall Islands'),
    'MQ': translate('ctry-name-Martinique', 'Martinique'),
    'MR': translate('ctry-name-Mauritania', 'Mauritania'),
    'MU': translate('ctry-name-Mauritius', 'Mauritius'),
    'MX': translate('ctry-name-Mexico', 'Mexico'),
    'FM': translate('ctry-name-MicronesiaFederatedStatesof', 'Micronesia, Federated States of'),
    'MD': translate('ctry-name-RepublicofMoldova', 'Republic of Moldova'),
    'MC': translate('ctry-name-Monaco', 'Monaco'),
    'MN': translate('ctry-name-Mongolia', 'Mongolia'),
    'ME': translate('ctry-name-Montenegro', 'Montenegro'),
    'MS': translate('ctry-name-Montserrat', 'Montserrat'),
    'MA': translate('ctry-name-Morocco', 'Morocco'),
    'MZ': translate('ctry-name-Mozambique', 'Mozambique'),
    'MM': translate('ctry-name-Myanmar', 'Myanmar'),
    'NA': translate('ctry-name-Namibia', 'Namibia'),
    'NR': translate('ctry-name-Nauru', 'Nauru'),
    'NP': translate('ctry-name-Nepal', 'Nepal'),
    'NL': translate('ctry-name-Netherlands', 'Netherlands'),
    'NZ': translate('ctry-name-NewZealand', 'New Zealand'),
    'NI': translate('ctry-name-Nicaragua', 'Nicaragua'),
    'NE': translate('ctry-name-Niger', 'Niger'),
    'NG': translate('ctry-name-Nigeria', 'Nigeria'),
    'NU': translate('ctry-name-Niue', 'Niue'),
    'NF': translate('ctry-name-NorfolkIsland', 'Norfolk Island'),
    'MP': translate('ctry-name-NorthernMarianaIslands', 'Northern Mariana Islands'),
    'NO': translate('ctry-name-Norway', 'Norway'),
    'OM': translate('ctry-name-Oman', 'Oman'),
    'PK': translate('ctry-name-Pakistan', 'Pakistan'),
    'PW': translate('ctry-name-Palau', 'Palau'),
    'PS': translate('ctry-name-PalestinianTerritory', 'Palestinian Territory'),
    'PA': translate('ctry-name-Panama', 'Panama'),
    'PG': translate('ctry-name-PapuaNewGuinea', 'Papua New Guinea'),
    'PY': translate('ctry-name-Paraguay', 'Paraguay'),
    'PE': translate('ctry-name-Peru', 'Peru'),
    'PH': translate('ctry-name-Philippines', 'Philippines'),
    'PN': translate('ctry-name-Pitcairn', 'Pitcairn'),
    'PL': translate('ctry-name-Poland', 'Poland'),
    'PT': translate('ctry-name-Portugal', 'Portugal'),
    'PR': translate('ctry-name-PuertoRico', 'Puerto Rico'),
    'QA': translate('ctry-name-Qatar', 'Qatar'),
    'RO': translate('ctry-name-Romania', 'Romania'),
    'RU': translate('ctry-name-Russian', 'Russian'),
    'RW': translate('ctry-name-Rwanda', 'Rwanda'),
    'KN': translate('ctry-name-SaintKittsandNevis', 'Saint Kitts and Nevis'),
    'LC': translate('ctry-name-SaintLucia', 'Saint Lucia'),
    'WS': translate('ctry-name-Samoa', 'Samoa'),
    'SM': translate('ctry-name-SanMarino', 'San Marino'),
    'ST': translate('ctry-name-SaoTomeandPrincipe', 'Sao Tome and Principe'),
    'SA': translate('ctry-name-SaudiArabia', 'Saudi Arabia'),
    'SN': translate('ctry-name-Senegal', 'Senegal'),
    'RS': translate('ctry-name-Serbia', 'Serbia'),
    'SC': translate('ctry-name-Seychelles', 'Seychelles'),
    'SL': translate('ctry-name-SierraLeone', 'Sierra Leone'),
    'SG': translate('ctry-name-Singapore', 'Singapore'),
    'SX': translate('ctry-name-SintMaarten', 'Sint Maarten'),
    'SK': translate('ctry-name-Slovakia', 'Slovakia'),
    'SI': translate('ctry-name-Slovenia', 'Slovenia'),
    'SB': translate('ctry-name-SolomonIslands', 'Solomon Islands'),
    'SO': translate('ctry-name-Somalia', 'Somalia'),
    'ZA': translate('ctry-name-SouthAfrica', 'South Africa'),
    'SS': translate('ctry-name-SouthSudan', 'South Sudan'),
    'ES': translate('ctry-name-Spain', 'Spain'),
    'LK': translate('ctry-name-SriLanka', 'Sri Lanka'),
    'SD': translate('ctry-name-Sudan', 'Sudan'),
    'SR': translate('ctry-name-Suriname', 'Suriname'),
    'SZ': translate('ctry-name-Swaziland', 'Swaziland'),
    'SE': translate('ctry-name-Sweden', 'Sweden'),
    'CH': translate('ctry-name-Switzerland', 'Switzerland'),
    'SY': translate('ctry-name-Syria', 'Syria'),
    'TW': translate('ctry-name-TaiwanProvinceofChina', 'Taiwan, Province of China'),
    'TJ': translate('ctry-name-Tajikistan', 'Tajikistan'),
    'TZ': translate('ctry-name-Tanzania', 'Tanzania'),
    'TH': translate('ctry-name-Thailand', 'Thailand'),
    'TG': translate('ctry-name-Togo', 'Togo'),
    'TK': translate('ctry-name-Tokelau', 'Tokelau'),
    'TO': translate('ctry-name-Tonga', 'Tonga'),
    'TT': translate('ctry-name-TrinidadandTobago', 'Trinidad and Tobago'),
    'TN': translate('ctry-name-Tunisia', 'Tunisia'),
    'TR': translate('ctry-name-Turkey', 'Turkey'),
    'TM': translate('ctry-name-Turkmenistan', 'Turkmenistan'),
    'TC': translate('ctry-name-TurksandCaicosIslands', 'Turks and Caicos Islands'),
    'TV': translate('ctry-name-Tuvalu', 'Tuvalu'),
    'UG': translate('ctry-name-Uganda', 'Uganda'),
    'UA': translate('ctry-name-Ukraine', 'Ukraine'),
    'AE': translate('ctry-name-UnitedArabEmirates', 'United Arab Emirates'),
    'GB': translate('ctry-name-UnitedKingdom', 'United Kingdom'),
    'US': translate('ctry-name-UnitedStates', 'United States'),
    'UY': translate('ctry-name-Uruguay', 'Uruguay'),
    'UZ': translate('ctry-name-Uzbekistan', 'Uzbekistan'),
    'VU': translate('ctry-name-Vanuatu', 'Vanuatu'),
    'VE': translate('ctry-name-VenezuelaBolivarianRepublicof', 'Venezuela, Bolivarian Republic of'),
    'VN': translate('ctry-name-VietNam', 'Viet Nam'),
    'VI': translate('ctry-name-VirginIslands', 'Virgin Islands'),
    'YE': translate('ctry-name-Yemen', 'Yemen'),
    'ZM': translate('ctry-name-Zambia', 'Zambia'),
    'ZW': translate('ctry-name-Zimbabwe', 'Zimbabwe'),
  };
}
