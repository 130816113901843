import { Map, List } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';
import {LOGIN_CONSTANTS} from '../constants/store/StoreStateConstants';
import cookie from "react-cookies";
import LocalStorageUtil from '../utils/LocalStorageUtil';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import { setAPIAuthorizationToken, unsetAPIAuthorizationToken } from '../apis/API';

const localAuth = cookie.load(StoreStateConstants.AUTHENTICATION, {path: '/'});
if (localAuth) {
    setAPIAuthorizationToken(localAuth);
}

const localCurrentUser = LocalStorageUtil.getItem(StoreStateConstants.CURRENT_USER);
const initialState = Map({
    [StoreStateConstants.LOGIN_STATUS]: cookie.load(StoreStateConstants.AUTHENTICATION) ? LOGIN_CONSTANTS.LOGGED_IN: LOGIN_CONSTANTS.LOGGED_OUT,
    [StoreStateConstants.CURRENT_USER]: (localCurrentUser && JSON.parse(localCurrentUser) && Map(JSON.parse(localCurrentUser))) || Map(),
    [StoreStateConstants.AUTHENTICATION]: localAuth || null
});

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.UPDATE_CURRENT_USER: {
            const data = action.payload;
            return LocalStorageUtil.syncToLocalStorage(StoreStateConstants.CURRENT_USER, state.set(StoreStateConstants.CURRENT_USER, Map(data))) ;
        }
        case ActionConstants.LOGOUT: {
            cookie.remove(StoreStateConstants.AUTHENTICATION, {path: '/'});
            unsetAPIAuthorizationToken();
            return state.set(StoreStateConstants.LOGIN_STATUS, LOGIN_CONSTANTS.LOGGED_OUT).set(StoreStateConstants.AUTHENTICATION, null);
        }
        case ActionConstants.LOGIN_SUCCESS: {
            //TODO: 叫后端设置cookie httpOnly 以及samesite 属性
            const {token, expires_in} = action.payload;
            const time = new Date(new Date().getTime() + expires_in * 1000);
            cookie.save(StoreStateConstants.AUTHENTICATION, token, {expires: time });
            setAPIAuthorizationToken(token)
            return state.set(StoreStateConstants.LOGIN_STATUS, LOGIN_CONSTANTS.LOGGED_IN).set(StoreStateConstants.AUTHENTICATION, token);
        }
        case ActionConstants.CLEAR_CURRENT_USER: {
            LocalStorageUtil.removeItem(StoreStateConstants.CURRENT_USER);
            return state.set(StoreStateConstants.CURRENT_USER, Map());
        }
        default:
            return state;
    }
};
