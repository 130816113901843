import React from 'react';
import PropTypes from 'prop-types';

// === Components === //
import EDSUncontrolledTooltip from './../../tooltip/EDSUncontrolledTooltip';
import SvgIcon from '../../SvgIcon';

// === Utils === //
import noop from 'lodash/noop';

// === Styles === //
import './EDSLinkItem.scss';
import I18N from '../../../../i18n';

const LinkItem = (props) => {
  const { onEdit, onRemove, decoratedText, blockKey, entityKey } = props;
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  const id = `Tooltip-${blockKey}-${entityKey}`;

  return (
    <>
      <a href={url} id={id}>
        {props.children}
      </a>

      <EDSUncontrolledTooltip key={id} className='RichEditor-link-tooltip'  placement="top" target={id} trigger="click" >
        <p>
          <SvgIcon size={20} name="link-icon" />&nbsp;
          <span onClick={() => { window.open(url); }} title={url} className='RichEditor-url'>{url}</span>
          <span className='RichEditor-split'>|</span>
          <span className='RichEditor-btn' onClick={(e) => {
            onEdit({ decoratedText, url });
          }}>
            {I18N.getText('edit')}
          </span>
          <span className='RichEditor-split'>|</span>
          <span className='RichEditor-btn' onClick={(e) => {
            onRemove();
          }}>
            {I18N.getText('remove')}
          </span>
        </p>
      </EDSUncontrolledTooltip>
    </>
  );
}

LinkItem.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
}

LinkItem.defaultProps = {
  onEdit: noop,
  onRemove: noop,
};

export default LinkItem;
