import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Translate from '../../common/translate/Translate';

import RdsCancelIcon from '../../icons/RdsCancelIcon';
import * as GlobalAlertSelectors from '../../selectors/GlobalAlertSelectors';
import GlobalAlertConstants from '../../constants/GlobalAlertConstants';
import I18N from '../../i18n';

const defaultAlertType = GlobalAlertConstants.SUCCESS;

const bgColors = {
  success: 'tw-bg-subtitle',
  error: 'tw-bg-error-text',
  info: 'tw-bg-blue',
  warning: 'tw-bg-yellow-orange-1',
}

const textColors = {
  success: 'tw-text-subtitle',
  error: 'tw-text-error-text',
  info: 'tw-text-blue',
  warning: 'tw-text-yellow-orange-1',
}

const chars = {
  success: 'tw-radio-tick before:tw-border-subtitle after:tw-border-subtitle',
  error: "tw-disk tw-cross before:tw-bg-error-text after:tw-bg-error-text",
  info: "tw-disk tw-italic before:tw-block before:tw-content-['i']",
  warning: "tw-disk before:tw-block before:tw-content-['!']",
}


class GlobalAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.clearTimeout = this.clearTimeout.bind(this);
  }

  componentDidUpdate(prevProps) {
    const prevGlobalAlertKey = prevProps.globalAlert.get(GlobalAlertConstants.KEY);
    const currentGlobalAlertKey = this.props.globalAlert.get(GlobalAlertConstants.KEY);

    if (currentGlobalAlertKey && prevGlobalAlertKey !== currentGlobalAlertKey) {
      this.clearTimeout();

      const alertTimeout = this.props.globalAlert.get(GlobalAlertConstants.TIMEOUT) || GlobalAlertConstants.DEFAULT_TIMEOUT;

      this.setState({ isVisible: true });

      this.timeout = setTimeout(() => {
        this.setState({ isVisible: false });
      }, alertTimeout);
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  forceClose() {
    this.clearTimeout();
    this.setState({ isVisible: false });
  }

  render() {
    const { globalAlert }     = this.props;
    const alertType           = globalAlert.get(GlobalAlertConstants.ALERT_TYPE) || defaultAlertType;
    const translationKey      = globalAlert.get(GlobalAlertConstants.TRANSLATION_KEY);
    let content
    if (typeof(translationKey) === 'string') {
      const translationFallback = globalAlert.get(GlobalAlertConstants.TRANSLATION_FALLBACK);
      content = (<span>{I18N.getText(translationKey, translationFallback)}</span>)
    } else {
      content = translationKey
    }

    if (!this.state.isVisible) { return null; }

    return (
      <div className={`tw-z-[99999] tw-fixed tw-top-6 tw-left-[50%] tw-ml-[-150px] sm:tw-ml-[-250px] tw-text-white tw-rounded-xl tw-py-4 tw-px-5 tw-max-w-[300px] sm:tw-max-w-[500px] tw-flex tw-items-center ${bgColors[alertType]}`} >
        <span className={`tw-rounded-full tw-bg-white tw-w-6 tw-h-6 tw-shrink-0 tw-mr-4 tw-text-center tw-font-black tw-font-['Consolas','monospace'] tw-text-lg before:tw-leading-6 ${chars[alertType]} ${textColors[alertType]}`} />
        {content}
        <span className='tw-inline-block tw-ml-4 tw-pl-4 tw-border-l tw-border-currentColor tw-cursor-pointer'>
          <RdsCancelIcon className='tw-text-white tw-cursor-pointer' onClick={() => this.forceClose()}/>
        </span>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    globalAlert: GlobalAlertSelectors.selectGlobalAlert(state, ownProps),
  };
}

export default connect(mapStateToProps)(GlobalAlert);
