import React from 'react';
import Markdown from 'react-markdown';
import remarkDisableTokenizers from "remark-disable-tokenizers";

//** Utils */
import EDSRichTextRendererUtil from './EDSRichTextRendererUtil';
import * as RegexUtil from '../../../../utils/RegexUtil';

//** Components */
import LinkOrAnchor from '../../LinkOrAnchor';

const MarkdownRenderer = ({ source, options, mentions, isStudent, creator }) => {

  let scrubbedSource = source || '';
  const scrubbedSources = scrubbedSource.split('```');
  let newScrubbedSource = '';

  scrubbedSources.forEach((string, index) => {
    if (index % 2 === 0) { // if not a code block
      // Parse emails, hashtags, and mentions into proper markdown links
      newScrubbedSource += EDSRichTextRendererUtil.parseLinks(string.replace(/\n\s{4}/g, `\n  `), mentions, options);
    } else {
      newScrubbedSource += '```' + string + '```';
    }
  });

  // disable reference links to avoid creating links when a users uses brackets with link notation
  // https://edmodo.atlassian.net/browse/CTN-369
  // https://github.com/rexxars/react-markdown/pull/223#issuecomment-426772633
  const plugins = [
    [remarkDisableTokenizers, { inline: ['reference'] }]
  ];

  const LinkRenderer = props => {
    if (EDSRichTextRendererUtil.isMailToLink(props.href)) {
      return <a href={props.href}>{props.children}</a>;
    } else if (RegexUtil.isHashtag(props.children)) {
      return <LinkOrAnchor href={props.href}>{props.children}</LinkOrAnchor>;
    } else if (EDSRichTextRendererUtil.isMentionLink(props.href)) {
      return <a href={props.href} className="EDSRichTextRenderer__mention" target="_blank" rel="noopener noreferrer">{props.children}</a>;
    }
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>;
  }
  return (
    <Markdown
      source={newScrubbedSource}
      className="EDSRichTextRenderer__Markdown qa-test-EDS-RichTextRenderer-Markdown"
      renderers={{
        link: LinkRenderer,
      }}
      plugins={plugins}
      parserOptions={{ commonmark: true }}
      stripListLeaders={true}
    />
  );

};

export default MarkdownRenderer;
