import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import ActionConstants from "../constants/actions/ActionConstants";
import * as BaseAPI from "../apis/BaseAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';


export const updateBaseBannerConfig = (data) => ({
    type: ActionConstants.UPDATE_BASE_CONFIG,
    payload: data,
});

export const fetchBaseConfig = (type) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BaseAPI.getBaseConfig(type)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        let baseConfig = res.data.data;
                        dispatch(updateBaseBannerConfig(baseConfig));
                        return resolve(baseConfig);
                    }
                    return reject(res.data.msg);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                    } else {
                        dispatch(showErrorGlobalAlert('router-fetch-error'));
                    }
                    return reject('err');
                });
        });
    };
}