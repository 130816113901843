export default {
  UNDO               : 'undo',
  REDO               : 'redo',
  MOVE_BLOCK         : 'move-block',
  SPLIT_BLOCK        : 'split-block',
  ADJUST_DEPTH       : 'adjust-depth',
  APPLY_ENTITY       : 'apply-entity',
  REMOVE_RANGE       : 'remove-range',
  INSERT_FRAGMENT    : 'insert-fragment',
  DELETE_CHARACTER   : 'delete-character',
  CHANGE_BLOCK_DATA  : 'change-block-data',
  CHANGE_BLOCK_TYPE  : 'change-block-type',
  INSERT_CHARACTERS  : 'insert-characters',
  SPELLCHECK_CHANGE  : 'spellcheck-change',
  CHANGE_INLINE_STYLE: 'change-inline-style',
  BACKSPACE_CHARACTER: 'backspace-character',
};
