import React from 'react';
import RDSPopover from './RDSPopover';
import PropTypes from 'prop-types';

const RDSPopoverEnableCtrl = ({
  isOpen = false,
  toggle,
  children,
  ...props
}) => {

  const enableCtrl = {
    isOpen: isOpen,
    toggle: toggle
  }

  return (
    <RDSPopover enableCtrl={enableCtrl} {...props}>
      {children}
    </RDSPopover>
  );
};

// type Placement =
// | 'auto'
// | 'auto-start'
// | 'auto-end'
// | 'top'
// | 'top-start'
// | 'top-end'
// | 'bottom'
// | 'bottom-start'
// | 'bottom-end'
// | 'right'
// | 'right-start'
// | 'right-end'
// | 'left'
// | 'left-start'
// | 'left-end';
RDSPopover.propTypes = {
  children   : PropTypes.node, // inner nodes, must be an array
  qaSelector : PropTypes.string,
  mask       : PropTypes.bool,
  className  : PropTypes.string,
  hasArrow   : PropTypes.bool,
  bgClassName: PropTypes.string,
  placement  : PropTypes.string,
  modifiers  : PropTypes.array,
  enableCtrl : PropTypes.object,
  isOpen     : PropTypes.bool,
  toggle     : PropTypes.func,
  disabled   : PropTypes.bool
};


export default RDSPopoverEnableCtrl;

