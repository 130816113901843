import React from 'react';
import Colors from '../constants/ColorConstants';

const SubIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
  <svg viewBox="0 0 1024 1024" p-id="4921" width={size} height={size}  {...props}>
  <path d="M170.666667 469.333333h682.666666v85.333334H170.666667z" fill={color} p-id="4922"></path>
</svg>
);

export default SubIcon