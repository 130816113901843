
import React from 'react';
import Colors from '../constants/ColorConstants';

const LogisticsIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg width={size} height={size} fill="none" >
    <path d="M18 0H0v18h18V0Z" fillOpacity="0"></path>
    <path d="M18 0H0v18h18V0Z" fillOpacity="0"></path>
    <path d="M4.5 14.625a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM13.125 14.625a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"></path>
    <path d="M3 13.125H.75v-9h10.875v9H6M11.625 13.125V6.75h3.214l2.411 3.188v3.187h-2.32" stroke="currentColor" strokeWidth="1.2" strokeLinecap="square" strokeLinejoin="round"></path>
  </svg>
);

export default LogisticsIcon;

