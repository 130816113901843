let PagePathConstants = {
  ENTRANCE_PAGE: "/",
  PRODUCT_DETAIL: "/product-detail/:id",
  HOME_PAGE: "/home",
  LOGIN_PAGE: "/login",
  REGISTER_PAGE: "/register",
  SHOPPING_CART: "/cart",
  CHECKOUT_PAGE: "/checkout",
  PRODUCT_CATEGORY: "/product-category/:id",
  ORDER_CALLBACK: "/order-callback",
  ABOUT_US: "/about-us",
  PRIVACY_POLICY: "/privacy-policy",
  SHIPPING_POLICY: "/shipping-policy",
  ORDER_SEARCH: "/order-search",
  ORDER_DETAIL: "/order-detail",
};

export const updateRouterConstants = (config) => {
  if (config.cartPage) PagePathConstants.SHOPPING_CART = `/${config.cartPage}`;
  if (config.categoryPage) PagePathConstants.PRODUCT_CATEGORY = `/${config.categoryPage}/:id`;
  if (config.checkoutPage) PagePathConstants.CHECKOUT_PAGE = `/${config.checkoutPage}`;
  if (config.homePage) PagePathConstants.HOME_PAGE = `/${config.homePage}`;
  if (config.loginPage) PagePathConstants.LOGIN_PAGE = `/${config.loginPage}`;
  if (config.productDetailPage) PagePathConstants.PRODUCT_DETAIL = `/${config.productDetailPage}/:id`;
  if (config.registerPage) PagePathConstants.REGISTER_PAGE = `/${config.registerPage}`;
  // if (config.orderCallbackPage) PagePathConstants.ORDER_CALLBACK = config.orderCallbackPage;
  if (config.aboutUsPage) PagePathConstants.ABOUT_US = `/${config.aboutUsPage}`;
  if (config.provacyPolicyPage) PagePathConstants.PRIVACY_POLICY = `/${config.provacyPolicyPage}`;
  if (config.shippingPolicyPage) PagePathConstants.SHIPPING_POLICY = `/${config.shippingPolicyPage}`;
}

export default PagePathConstants;