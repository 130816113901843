
import React from 'react';
import Colors from '../constants/ColorConstants';

const ShoppingCartIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="9911" width={size} height={size} {...props}>
    <path d="M832 704H277.333333c-10.666667 0-19.2-6.4-21.333333-17.066667L121.6 149.333333H85.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333333h53.333334c10.666667 0 19.2 6.4 21.333333 17.066666L187.733333 234.666667H938.666667c6.4 0 12.8 2.133333 17.066666 8.533333s6.4 12.8 4.266667 19.2l-106.666667 426.666667c-2.133333 8.533333-10.666667 14.933333-21.333333 14.933333z m-537.6-42.666667h520.533333l96-384H198.4l96 384zM277.333333 917.333333c-46.933333 0-85.333333-38.4-85.333333-85.333333s38.4-85.333333 85.333333-85.333333 85.333333 38.4 85.333334 85.333333-38.4 85.333333-85.333334 85.333333z m0-128c-23.466667 0-42.666667 19.2-42.666666 42.666667s19.2 42.666667 42.666666 42.666667 42.666667-19.2 42.666667-42.666667-19.2-42.666667-42.666667-42.666667zM832 917.333333c-46.933333 0-85.333333-38.4-85.333333-85.333333s38.4-85.333333 85.333333-85.333333 85.333333 38.4 85.333333 85.333333-38.4 85.333333-85.333333 85.333333z m0-128c-23.466667 0-42.666667 19.2-42.666667 42.666667s19.2 42.666667 42.666667 42.666667 42.666667-19.2 42.666667-42.666667-19.2-42.666667-42.666667-42.666667z" fill={color} p-id="9912"></path>
  </svg>
);

export default ShoppingCartIcon;

