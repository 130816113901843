import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from '@material-ui/lab/Pagination';
import NonRecordIcon from '../icons/NonRecordIcon';
import * as productActionCreator from '../actions/productActionCreator';
import * as categoryActionCreator from '../actions/categoryActionCreator';
import * as tagActionCreator from '../actions/tagActionCreator';
import Loading from '../components/common/LoadingIndicator';
import GuaranteeComponent from '../components/common/GuaranteeComponent';
import * as ProductDetailSelectors from '../selectors/ProductDetailSelectors'
import * as CategorySelectors from '../selectors/CategorySelectors'
import StarIcon from '../icons/StarIcon';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/scss/pagination';

import PagePathConstants from '../constants/router/PagePathConstants';
import classNames from 'classnames';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';
import * as FBUtil from '../utils/FbUtil';
import tracker from '../tracker';
import ProductHelper from '../helpers/ProductHelper';

class ProductCategoryPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoading: false,
            queryParam: {
                page: 1,
                perPage: 18,
            },
            totalNum: 0,
            selectGridView: '4',
            categoryProductList: [],
            productTagList: []
        }
        this.toggleLoading = this.toggleLoading.bind(this);
        this.setThumbsSwiper = this.setThumbsSwiper.bind(this);
        this.handleSelectSku = this.handleSelectSku.bind(this);
        this.updatePerPage = this.updatePerPage.bind(this);
        this.updateGridView = this.updateGridView.bind(this);
        this.fetchProductTagList = this.fetchProductTagList.bind(this);
        this.fetchCategoryDetail = this.fetchCategoryDetail.bind(this);
        this.routeToProductDetailPage = this.routeToProductDetailPage.bind(this);

        this.descContainerRef = React.createRef();
    }

    componentDidMount() {
        const { location, categoryActions, productActions } = this.props;
        const categoryId = location.pathname.split(PagePathConstants.PRODUCT_CATEGORY.replace(':id', ''))[1];

        this.fetchCategoryDetail(categoryId);
        this.fetchProductListByCategoryId(categoryId);
        this.fetchProductTagList();
        const queryParams = queryString.parse(this.props.location.search);
        // FBUtil.trackFBEvent(() => {
        //     window.fbq && window.fbq('trackCustom', 'TrackCategoryPage', { url: window.location.href, ad: queryParams.ad, categoryTitle: queryParams.cTitle });
        // });
        tracker.trackCategoryPage({ categoryTitle: queryParams.cTitle });

    }

    fetchProductTagList() {
        const { tagActions, productActions } = this.props;
        tagActions && tagActions.fetchProductTagList(0)
            .then((tagList) => {
                this.setState({ productTagList: tagList });
                tagList.map((tag, index) => {
                    productActions && productActions.fetchProductsByTagId(tag.id)
                        .then((productList) => {
                            let { productTagList } = this.state;
                            if (productList.length > 0 && productTagList[index]) {
                                productTagList[index]['productList'] = productList;
                                this.setState({ productTagList }, () => {
                                });
                            }

                        })
                })
            })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            const categoryId = nextProps.location.pathname.split(PagePathConstants.PRODUCT_CATEGORY.replace(':id', ''))[1];
            this.fetchProductListByCategoryId(categoryId);
        }
    }

    fetchCategoryDetail(categoryId) {
        const { categoryActions } = this.props;
        categoryActions && categoryActions.fetchCategoryDetailById(categoryId)
            .then(data => {
                categoryActions && categoryActions.selectCurrentCategory(data);
            });
    }

    fetchProductListByCategoryId(categoryId) {
        const { productActions } = this.props;
        productActions && productActions.fetchProductsByCategoryId(categoryId, this.state.queryParam)
            .then(data => {
                this.setState({
                    categoryProductList: data.list,
                    totalNum: data.total
                });
            })
    }

    handlePageChange(e, page) {
        let { queryParam } = this.state;
        queryParam.page = page;
        this.fetchProductListByCategoryId(queryParam)
            .then(() => {
                this.setState({ queryParam });
            });
    }


    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    setThumbsSwiper(swiper) {
        this.setState({ thumbsSwiper: swiper });
    }

    handleSelectSku(incomingSku, incomingItem) {
        const { productActions, currentProduct } = this.props;
        let newProductInfo = Object.assign({}, currentProduct);
        if (newProductInfo.skuData) {
            newProductInfo.skuData = newProductInfo.skuData.map((sku) => {
                if (sku.id === incomingSku.id) {
                    sku.selectedValue = incomingItem;
                }
                return sku;
            })
        }
        productActions && productActions.updateCurrentProductSkuData(newProductInfo);
    }

    updatePerPage(perPage) {
        let { queryParam } = this.state;
        queryParam.perPage = perPage;
        this.setState({ queryParam });
    }

    updateGridView(gridView) {
        this.setState({ selectGridView: gridView.toString() });
    }

    routeToProductDetailPage(id) {
        const { history, location } = this.props;
        history && history.push({ pathname: PagePathConstants.PRODUCT_DETAIL.replace(':id', id), search: location.search });
    }

    render() {
        const { showLoading, categoryProductList, selectGridView, queryParam, productTagList, totalNum } = this.state;
        const { currentCategory } = this.props;
        const formatCategoryTitle = currentCategory && currentCategory.title && currentCategory.title.slice(0, 1).toUpperCase() + currentCategory.title.slice(1).toLowerCase();
        const formatProductTagList = productTagList && productTagList.filter(tag => tag.productList && tag.productList.length > 0);
        const categoryImg = currentCategory && (BASE_URL + currentCategory.bannerImage);
        const total = Math.ceil(totalNum / queryParam.perPage);

        return (
            <div className='phone:tw-mt-0 SFNS-font'>
                {showLoading && <Loading />}

                <img src={categoryImg} alt="category_img" className='tw-w-full tw-mb-[20px]' />
                {/* <div className='tw-w-full tw-h-[474px] tw-mb-[40px] tw-pt-[220px] tw-pb-[100px] tw-text-[78px] phone:tw-text-[36px] phone:tw-h-[167px] phone:tw-pt-[75px] phone:tw-pb-[15px] !phone:tw-bg-[#ffc0cb] tw-text-white tw-text-center ' style={{ backgroundImage: `url(${categoryImg})`, backgroundSize: '100%', backgroundPosition: 'center' }}>
                    {formatCategoryTitle}
                </div> */}
                <GuaranteeComponent />
                <div className='tw-mt-[50px] pad:tw-px-[20px] phone:tw-mt-[30px] tw-w-[1340px] phone:tw-w-full pad:tw-w-full tw-h-auto tw-mx-auto tw-flex tw-justify-center tw-items-start'>

                    {formatProductTagList && formatProductTagList.length > 0 && <div className='tw-w-[275px] tw-mr-[50px] phone:tw-hidden'>
                        <div className='tw-text-[16px] tw-mb-[20px] tw-font-[600]'>{formatProductTagList[0].title}</div>
                        <div className='tw-full'>
                            {formatProductTagList && formatProductTagList[0] && formatProductTagList[0].productList && formatProductTagList[0].productList.map(item => {
                                const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);

                                const currentPrice = parseFloat(item.currentPrice);

                                return (<div className='tw-flex tw-justify-between tw-pt-[15px] tw-pb-[15px] tw-border-b' onClick={() => this.routeToProductDetailPage(item.id)}>
                                    <div className='tw-w-[65px] tw-h-[98px]' style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                    <div className='tw-w-[195px]'>
                                        <div className='tw-mb-[8px] tw-line-clamp-2'>{item.title}</div>
                                        <div className='tw-text-[16px] tw-text-[#ED2353] tw-font-[600]'>${currentPrice}</div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>}
                    <div className='phone:tw-w-full pad:tw-w-full tw-w-[1025px] tw-h-auto tw-px-[15px]'>
                        <div className='phone:tw-hidden tw-text-[16px] tw-flex tw-justify-end tw-items-center tw-mb-[20px]'>
                            <div className='tw-flex tw-h-[38px] tw-items-center tw-text-[18px] tw-mr-[20px]'>
                                <span className='tw-mr-[20px] tw-font-[600]'>Show:</span>
                                <span className={classNames('tw-px-[10px] tw-text-[#bbb]', { '!tw-text-[#444]': queryParam.perPage === 9 })} onClick={() => this.updatePerPage(9)}>9</span>/
                                <span className={classNames('tw-px-[10px] tw-text-[#bbb]', { '!tw-text-[#444]': queryParam.perPage === 12 })} onClick={() => this.updatePerPage(12)}>12</span>/
                                <span className={classNames('tw-px-[10px] tw-text-[#bbb]', { '!tw-text-[#444]': queryParam.perPage === 18 })} onClick={() => this.updatePerPage(18)}>18</span>
                            </div>
                            <div className='tw-ml-[20px] tw-flex tw-justify-between tw-items-center  tw-text-[#ccc]'>
                                <div className={classNames('tw-text-[180%] hs-icon gridview-2 tw-mr-[20px]', { 'tw-text-[#777]': selectGridView === '2' })} onClick={() => this.updateGridView(2)}></div>
                                <div className={classNames('tw-text-[180%] hs-icon gridview-3 tw-mr-[20px]', { 'tw-text-[#777]': selectGridView === '3' })} onClick={() => this.updateGridView(3)}></div>
                                <div className={classNames('tw-text-[180%] hs-icon gridview-4', { 'tw-text-[#777]': selectGridView === '4' })} onClick={() => this.updateGridView(4)}></div>
                            </div>
                        </div>
                        <div className='tw-flex tw-justify-between tw-flex-wrap'>
                            {categoryProductList && categoryProductList.map((item, index) => {
                                const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);

                                const originalPrice = parseFloat(item.originalPrice);
                                const currentPrice = parseFloat(item.currentPrice);
                                const discount = Math.floor(((originalPrice - currentPrice) / originalPrice) * 100);
                                return (<div className={classNames('tw-mb-[30px] phone:!tw-w-[48%]', { 'tw-w-[48%]': selectGridView === '2' }, { 'tw-w-[32%]': selectGridView === '3' }, { 'tw-w-[24%] phone:tw-w-[46%] phone:tw-mb-[20px]': selectGridView === '4' })} key={index} onClick={() => this.routeToProductDetailPage(item.id)}>
                                    <div className={classNames('tw-w-full tw-relative tw-pb-[100%] product-img')} style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                        <div className={classNames('tw-absolute  tw-font-[600] tw-text-white tw-font-[20px] tw-px-[10px] tw-py-[3px] tw-rounded-[8px] tw-bg-brand-primary phone:!tw-top-[10px] phone:!tw-left-[10px]', { 'tw-top-[20px] tw-left-[20px]': selectGridView === '2' }, { 'tw-top-[15px] tw-left-[15px]': selectGridView === '3' }, { 'tw-top-[10px] tw-left-[10px]': selectGridView === '4' })}>{discount}% OFF</div>
                                    </div>
                                    <div className={classNames('tw-px-[10px] tw-py-[10px] tw-flex tw-flex-col tw-justify-between tw-items-center phone:!tw-text-[14px]', { 'tw-text-[20px]': selectGridView === '2' }, { 'tw-text-[16px]': selectGridView === '3' }, { 'tw-text-[14px]': selectGridView === '4' })}>
                                        <div className='tw-h-[45px] tw-line-clamp-2 tw-opacity-[0.8] tw-text-[15px]'>{item.title}</div>
                                        <div className='tw-text-[#777]'>{formatCategoryTitle}</div>
                                        <div className='tw-flex tw-items-center tw-my-[2px]'>
                                            {new Array(item.star).fill(undefined).map(() => {
                                                return <StarIcon className="tw-mr-[2px]" size={20} />
                                            })}
                                        </div>
                                        <div className='tw-w-full  tw-flex tw-justify-between tw-items-center'>
                                            <div className={classNames('tw-text-[#777] tw-line-through phone:!tw-text-[14px]', { 'tw-text-[20px]': selectGridView === '2' }, { 'tw-text-[18px]': selectGridView === '3' }, { 'tw-text-[14px]': selectGridView === '4' })}>${originalPrice}</div>
                                            <div className={classNames('tw-text-[#ED2353] phone:!tw-text-[18px]', { 'tw-text-[26px]': selectGridView === '2' }, { 'tw-text-[22px]': selectGridView === '3' }, { 'tw-text-[18px]': selectGridView === '4' })}>${currentPrice}</div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                            <div className='tw-w-full tw-flex tw-justify-center tw-mb-[50px]'>
                                <Pagination count={total} page={queryParam.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
                            </div>
                            {categoryProductList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
                                <NonRecordIcon size={200} />
                                <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>No records</div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        currentProduct: ProductDetailSelectors.selectCurrentProduct(state),
        currentCategory: CategorySelectors.selectCurrentCategory(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActionCreator, dispatch),
        categoryActions: bindActionCreators(categoryActionCreator, dispatch),
        tagActions: bindActionCreators(tagActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoryPage);
