import { generateRandomString } from '../utils/RandomGeneratorUtils';
import GlobalAlertConstants from '../constants/GlobalAlertConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const showGlobalAlert = (translationKey, translationFallback, alertType, timeout, insertions, parseHtml) => ({
  type    : ActionConstants.SHOW_GLOBAL_ALERT,
  payload : {
    key: generateRandomString(),
    translationKey,
    translationFallback,
    insertions,
    parseHtml,
    alertType,
    timeout,
  },
});

export const showSuccessGlobalAlert = (translationKey, translationFallback, timeout, insertions, parseHtml) => (
  showGlobalAlert(translationKey, translationFallback, GlobalAlertConstants.SUCCESS, timeout, insertions, parseHtml)
);

export const showErrorGlobalAlert = (translationKey, translationFallback, timeout, insertions, parseHtml) => (
  showGlobalAlert(translationKey, translationFallback, GlobalAlertConstants.ERROR, timeout, insertions, parseHtml)
);

export const showInfoGlobalAlert = (translationKey, translationFallback, timeout, insertions, parseHtml) => (
  showGlobalAlert(translationKey, translationFallback, GlobalAlertConstants.INFO, timeout, insertions, parseHtml)
);

export const showWarningGlobalAlert = (translationKey, translationFallback, timeout, insertions) => (
  showGlobalAlert(translationKey, translationFallback, GlobalAlertConstants.WARNING, timeout, insertions)
);

export const dispatchGlobalAlert = (translationKey, translationFallback, alertType) => {
  return function (dispatch, getState) {
    dispatch(showGlobalAlert(translationKey, translationFallback, alertType));
  }
}