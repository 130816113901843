
import React from 'react';
import Colors from '../constants/ColorConstants';

const SearchIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="3361" width={size} height={size} {...props}>
    <path d="M743.232 210.837333c144.896 144.896 149.781333 376.789333 14.656 527.573334l160.32 160.298666a8.533333 8.533333 0 0 1 0 12.074667l-33.173333 33.173333a8.533333 8.533333 0 0 1-12.074667 0l-161.557333-161.557333c-150.762667 120.746667-371.477333 111.253333-511.232-28.501333-149.973333-149.973333-149.973333-393.109333 0-543.061334 149.973333-149.973333 393.088-149.973333 543.061333 0z m-497.813333 45.248c-124.970667 124.970667-124.970667 327.594667 0 452.565334 124.970667 124.949333 327.594667 124.949333 452.565333 0 124.949333-124.970667 124.949333-327.594667 0-452.565334-124.970667-124.970667-327.594667-124.970667-452.565333 0z" fill={color} p-id="3362"></path>
  </svg>
);

export default SearchIcon;