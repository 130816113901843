import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import ActionConstants from "../constants/actions/ActionConstants";
import * as RouterAPI from "../apis/RouterAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';
import { updateRouterConstants } from "../constants/router/PagePathConstants";

export const updateRouterConfig = (data) => ({
    type: ActionConstants.UPDATE_ROUTER_CONFIG,
    payload: data,
});

export const fetchRouterConfig = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            RouterAPI.getRouterBaseConfig()
                .then((res) => {
                    if (res.status === 200) {
                        let routerConfig = res.data.data.router;
                        dispatch(updateRouterConfig(routerConfig))
                        updateRouterConstants(routerConfig);
                        return resolve('success');
                    }
                    return resolve(res);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                    } else {
                        dispatch(showErrorGlobalAlert('router-fetch-error'));
                    }
                    return reject('err');
                });
        });
    };
}