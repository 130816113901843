import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Thumbs } from 'swiper';
import QuantityController from '../components/common/quantity/index';
import VisaIcon from '../icons/payment/visaIcon';
import PlaneIcon from '../icons/PlaneIcon';
import CopyIcon from '../icons/CopyIcon';
import AmericanExpressIcon from '../icons/payment/americanExpressIcon';
import ApplePayIcon from '../icons/payment/applePayIcon';
import ExpressShippingIcon from '../icons/payment/expressShippingIcon';
import HundredPerQualityGuaranteeIcon from '../icons/payment/hundredPerQualityGuaranteeIcon';
import GooglePayIcon from '../icons/payment/googlePayIcon';
import MoneyBackIcon from '../icons/payment/moneyBackIcon';
import DiscoverCardIcon from '../icons/payment/discoverCardIcon';
import DinersClubIcon from '../icons/payment/dinersClubIcon';
import SslSecureIcon from '../icons/payment/sslSecureIcon';
import PaypalIcon from '../icons/payment/paypalIcon';
import MasterCardIcon from '../icons/payment/masterCardIcon';
import * as productActionCreator from '../actions/productActionCreator';
import * as tagActionCreator from '../actions/tagActionCreator';
import * as shoppingCartActionCreator from '../actions/shoppingCartActionCreator';
// import * as productCommentActionCreator from '../actions/productCommentActionCreator';
import Loading from '../components/common/LoadingIndicator';
import * as ProductDetailSelectors from '../selectors/ProductDetailSelectors';
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors';
import * as ShoppingCartSelectors from '../selectors/ShoppingCartSelectors';
import MobileDetectUtil from '../utils/MobileDetectUtil';
import StarIcon from '../icons/StarIcon';
import ShoppingCartModal from '../components/common/shoppingCartModal/index';
import CouponImg from '../images/coupon/coupon.webp';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/scss/pagination';

import PagePathConstants from '../constants/router/PagePathConstants';
import ProductHelper from '../helpers/ProductHelper';
import XIcon from '../icons/XIcon';
import RDSButton from '../components/common/RDSButton';
import { isMobileScreen, isTabletScreen } from '../utils/ResponsivePageUtils';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';
import * as FBUtil from '../utils/FbUtil';
import tracker from '../tracker';
import DefaultAvatarImg from '../images/default-avatar.jpeg';

class ProductDetailPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoading: false,
            showCouponModal: false,
            thumbsSwiper: null,
            marginWidth: 0,
            showShoppingCartModal: false,
            productTagList: [],
            productCommentList: [],
            productCommentTotal: 0,
            activeCommentTab: 'all',
            showCommentImage: false,
            commentImg: '',
            productId: 6,
        }
        this.toggleLoading = this.toggleLoading.bind(this);
        this.setThumbsSwiper = this.setThumbsSwiper.bind(this);
        this.handleSelectSku = this.handleSelectSku.bind(this);
        this.renderWhiteArrowBtn = this.renderWhiteArrowBtn.bind(this);
        this.toggleShoppingCartModal = this.toggleShoppingCartModal.bind(this);
        this.addProductToCart = this.addProductToCart.bind(this);
        this.fetchProductTagList = this.fetchProductTagList.bind(this);
        this.handleReceiveCoupon = this.handleReceiveCoupon.bind(this);
        this.handleCheckoutCurrentProduct = this.handleCheckoutCurrentProduct.bind(this);
        this.routeToProductDetailPage = this.routeToProductDetailPage.bind(this);
        this.fetchProductAndCategory = this.fetchProductAndCategory.bind(this);
        this.handleChangeCommentTab = this.handleChangeCommentTab.bind(this);
        this.closeCommentImg = this.closeCommentImg.bind(this);
        this.handleViewOrderPage = this.handleViewOrderPage.bind(this);
        this.descContainerRef = React.createRef();
        this.quantityRef = React.createRef();
    }

    handleViewOrderPage() {
        const { history, location } = this.props;
        history && history.push({ pathname: PagePathConstants.ORDER_SEARCH, search: location.search });
    }

    componentDidMount() {
        const { location } = this.props;
        const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];

        this.fetchProductAndCategory(productId);
    }

    handleChangeCommentTab(activeCommentTab) {
        this.setState({ activeCommentTab });
    }

    closeCommentImg() {
        this.setState({ showCommentImage: false });
    }

    fetchProductAndCategory(id) {
        const { productActions } = this.props;
        this.toggleLoading();
        productActions && productActions.fetchSingleProductById(id)
            .then(this.toggleLoading)
            .catch(this.toggleLoading)

        if (this.descContainerRef) {
            let offsetWidth = this.descContainerRef.current.offsetWidth;
            let marginWidth = (offsetWidth - 850) / 2;
            this.setState({ marginWidth });
        }

        this.renderWhiteArrowBtn();
        this.fetchProductTagList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location, currentProduct } = nextProps;
        const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];

        if (nextProps.location.pathname !== this.props.location.pathname) {
            const { location, productActions } = nextProps;
            const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];
            this.fetchProductAndCategory(productId);
        }
        if (nextProps.currentProduct !== this.props.currentProduct) {
            FBUtil.trackFBEvent('ViewContent', {
                content_ids: nextProps.currentProduct?.id,
                content_category: nextProps.currentProduct && nextProps.currentProduct.categorylist && nextProps.currentProduct.categorylist.title,
                content_name: nextProps.currentProduct?.title,
                content_type: 'product',
                contents: [nextProps.currentProduct],
                currency: 'USD',
                value: currentProduct.currentPrice
            });
            tracker.trackProductDetailPage({ productId: productId, productTitle: currentProduct.title });
        }
    }


    handlePreviewImg(imgUrl) {
        this.setState({ showCommentImage: true, commentImg: imgUrl });
    }

    fetchProductTagList() {
        const { tagActions, productActions } = this.props;
        tagActions && tagActions.fetchProductTagList(0)
            .then((tagList) => {
                this.setState({ productTagList: tagList });
                tagList.map((tag, index) => {
                    productActions && productActions.fetchProductsByTagId(tag.id)
                        .then((productList) => {
                            let { productTagList } = this.state;
                            if (productList.length > 0 && productTagList[index]) {
                                productTagList[index]['productList'] = productList;
                                this.setState({ productTagList });
                            }
                        })
                })
            })
    }

    handleReceiveCoupon() {
        const { baseConfig } = this.props;
        if (navigator.clipboard) {
            this.setState({ showCouponModal: true });
            navigator.clipboard.writeText(baseConfig.couponCode);
            setTimeout(() => {
                this.setState({ showCouponModal: false })
            }, 3000);
        }

    }

    renderWhiteArrowBtn() {
        let nextBtns = document.querySelectorAll('.swiper-button-next')
        let prevBtns = document.querySelectorAll('.swiper-button-prev')
        for (let i = 0; i < nextBtns.length; i++) {
            nextBtns[i].classList.add('btn-white');
            prevBtns[i].classList.add('btn-white');
        }
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    setThumbsSwiper(swiper) {
        this.setState({ thumbsSwiper: swiper });
    }

    handleSelectSku(index, incomingItem) {
        const { productActions, currentProduct } = this.props;
        let newProductInfo = Object.assign({}, currentProduct);
        if (newProductInfo.skulist) {
            newProductInfo.skulist[index].selectedValue = incomingItem;
        }
        productActions && productActions.updateCurrentProductSkuData(newProductInfo);
        let formatSkuList = newProductInfo.skulist && newProductInfo.skulist.map(item => {
            return {title: item.selectedValue?.title, content: item.selectedValue?.content};
        });
        FBUtil.trackFBEvent('CustomizeProduct', {selectedSku: formatSkuList});
    }

    toggleShoppingCartModal() {
        this.setState({ showShoppingCartModal: !this.state.showShoppingCartModal }, () => {
            if (this.state.showShoppingCartModal) {
                document.getElementById('root').classList.add('overflow-y-hidden')
            } else {
                document.getElementById('root').classList.remove('overflow-y-hidden')
            }
        })
    }

    componentWillUnmount() {
        document.getElementById('root').classList.remove('overflow-y-hidden');
    }

    addProductToCart() {
        let { currentProduct, shoppingCartActions, location } = this.props;
        currentProduct['quantity'] = 0;
        if (this.quantityRef && this.quantityRef.current) {
            currentProduct['quantity'] = this.quantityRef.current.state.quantity
        }
        let originalPrice = 0, currentPrice = 0;
        currentProduct && currentProduct.skulist && currentProduct.skulist.map(item => {
            originalPrice += parseFloat(item.selectedValue.originalPrice);
            currentPrice += parseFloat(item.selectedValue.currentPrice);
        });
        currentProduct.originalPrice = originalPrice;
        currentProduct.currentPrice = currentPrice;
        shoppingCartActions && shoppingCartActions.addProductToShoppingCart(currentProduct);
        
        FBUtil.trackFBEvent('AddToCart', { 
            content_ids: currentProduct.id,
            content_name: currentProduct.title,
            content_type: 'product',
            contents: [currentProduct],
            currency: 'USD',
            value: currentProduct.currentPrice
        });
        tracker.addToCart({ content_ids: currentProduct.id, content_name: currentProduct.title, content_type: 'product', contents: currentProduct, currency: 'USD', value: currentProduct.currentPrice });
        this.toggleShoppingCartModal();
    }

    handleCheckoutCurrentProduct() {
        const { currentProduct, history, location } = this.props;
        currentProduct['quantity'] = this.quantityRef.current.state.quantity;
        let originalPrice = 0, currentPrice = 0;
        currentProduct && currentProduct.skulist && currentProduct.skulist.map(item => {
            originalPrice += parseFloat(item.selectedValue.originalPrice);
            currentPrice += parseFloat(item.selectedValue.currentPrice);
        });
        currentProduct.originalPrice = originalPrice;
        currentProduct.currentPrice = currentPrice;
        ProductHelper.saveCheckoutProductList(false, currentProduct);
        history && history.push({ pathname: PagePathConstants.CHECKOUT_PAGE, search: location.search });
    }

    routeToProductDetailPage(id) {
        const { history, location } = this.props;
        history && history.push({ pathname: PagePathConstants.PRODUCT_DETAIL.replace(':id', id), search: location.search });
    }


    renderProductDesc() {
        const { currentProduct } = this.props;

        const mockScoreArr = [4.8, 4.9, 5.0];
        const mockScore = mockScoreArr[2];

        return (<div className='phone:tw-w-full pad:tw-w-full tw-w-full pad:tw-px-[50px] tw-flex tw-flex-col tw-items-center tw-text-body-text-light-grey SFNS-font tw-my-[50px] '>
            <div className='tw-my-[10px] tw-text-brand-primary tw-my-[20px] tw-font-[600] phone:tw-text-center'>30 Day Money Back Guarantee Secure checkout by</div>
            <div className='tw-w-full tw-flex tw-justify-center tw-flex-wrap hs-payment-icon-container'>
                <VisaIcon />  <AmericanExpressIcon /> <ApplePayIcon /> <ExpressShippingIcon /> <HundredPerQualityGuaranteeIcon /> <GooglePayIcon />
                <MoneyBackIcon /> <DiscoverCardIcon /> <DinersClubIcon /> <SslSecureIcon /> <PaypalIcon /> <MasterCardIcon />
            </div>


            <div className=" tw-leading-[35px] phone:tw-w-full pad:tw-w-full tw-w-full tw-mt-[60px]">
                

                <div className=' tw-text-brand-primary hs-product-description' dangerouslySetInnerHTML={{ __html: currentProduct && currentProduct.description }}></div>
                <div className='tw-w-full tw-h-[1px] tw-bg-gray-line tw-mt-[30px]'></div>
            </div>
            <div className='tw-h-[1px] tw-bg-gray-line'></div>

        
            {currentProduct && currentProduct.commentList_yes && currentProduct.commentList_yes.length > 0 && <div className='tw-w-[70%] pad:tw-w-[90%] tw-mt-[50px] phone:tw-mt-[30px] phone:tw-w-full phone:tw-px-[20px]'>
                <div className='tw-flex tw-flex-col tw-items-center'>
                    <div className='tw-w-full tw-text-center tw-pb-[30px]'>
                        <div className='tw-text-[22px] tw-mb-[30px] tw-font-[600]'>Customer Reviews</div>
                        <div className='tw-text-[12px]'>Here are what our customers say.</div>
                        <div className='tw-flex tw-justify-center tw-items-center tw-mt-[20px] '>
                            <div className='tw-text-[38px]'>{mockScore.toFixed(1)}</div>
                            <div className='tw-ml-[20px]'>
                                <div className='tw-flex tw-items-center'>
                                    {new Array(Math.floor(mockScore)).fill(undefined).map(item => <StarIcon size={20} className="tw-mr-[2px]" />)}
                                </div>
                                <div className='tw-text-[16px] tw-mt-[10px]'>Recently Reviewed: {currentProduct.commentList_yes.length}</div>
                            </div>
                        </div>
                    </div>
                    <div className='tw-w-full  phone:tw-w-full phone:tw-mt-[10px]'>
                        {<div className=''>
                            {currentProduct && currentProduct.commentList_yes && currentProduct.commentList_yes.map(item => {
                                return <div className='tw-border-b tw-py-[32px] '>
                                    <div className='tw-text-[14px] tw-font-[600] tw-mb-[20px] tw-flex tw-items-center'>
                                        <div className='tw-w-[40px] tw-h-[40px] tw-rounded-full tw-overflow-hidden' style={{ backgroundImage: `url(${DefaultAvatarImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                        <div className='tw-ml-[10px]'>{item.email}</div>
                                    </div>
                                    <div className='tw-w-full tw-flex tw-items-center tw-mb-[20px]'>{item && item.star ? new Array(item.star).fill(undefined).map(item => <StarIcon size={18} className="tw-mr-[2px]" />) : ''}</div>
                                    <div className='product-comment-container' dangerouslySetInnerHTML={{ __html: item && item.content }}></div>

                                </div>
                            })}
                        </div>}

                    </div>
                </div>
            </div>}
        </div>)
    }

    render() {
        const { showLoading, thumbsSwiper, marginWidth, productTagList, productCommentList, productCommentTotal, showShoppingCartModal, showCouponModal, activeCommentTab, showCommentImage, commentImg, showCustomerWindow, conversationArr } = this.state;
        const { currentProduct, baseConfig } = this.props;
        const formatProductMap = currentProduct && currentProduct.image && JSON.parse(currentProduct.image);
        const productMap = formatProductMap;
        const isMobile = isMobileScreen();
        const isTablet = isTabletScreen();

        let originalPrice = 0, currentPrice = 0;
        currentProduct && currentProduct.skulist && currentProduct.skulist.map(item => {
            originalPrice = parseFloat(originalPrice) + parseFloat(item.selectedValue.originalPrice);
            currentPrice = parseFloat(currentPrice) + parseFloat(item.selectedValue.currentPrice);
        });
        let save;
        if (currentProduct) {
            save = (1 - ((parseFloat(currentProduct.currentPrice) / parseFloat(currentProduct.originalPrice)).toFixed(2))) * 100
            save = Math.round(save * 100) / 100;
        }
        const formatProductTagList = productTagList && productTagList.filter(tag => tag.productList && tag.productList.length > 0);
        return (
            <div className='phone:tw-mt-0  phone:tw-h-auto pad:tw-h-auto'>
                {showLoading && <Loading />}
                {showCommentImage && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-flex tw-items-center tw-justify-center tw-bg-[rgba(0,0,0,0.7)] tw-z-[1000000]'>
                    <div className='tw-relative'>
                        <img src={commentImg} className='tw-h-[500px] phone:tw-hidden' alt='comment_img' />
                        <div className='tw-h-[500px] tw-w-[350px] tw-hidden phone:tw-block' style={{ backgroundImage: `url(${commentImg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />
                        <div onClick={this.closeCommentImg} className='tw-absolute tw-right-[-18px] tw-top-[-18px] tw-bg-[rgba(0,0,0,0.2)] tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-w-[36px] tw-h-[36px]'>
                            <XIcon size={24} color='white' />
                        </div>
                    </div>
                </div>}
                {showCouponModal && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.5)] tw-flex tw-justify-center tw-items-center tw-z-[1000]'>
                    <div className='tw-w-[428px] tw-h-[260px] phone:tw-w-[300px] phone:tw-h-[182px] tw-relative tw-flex' style={{ backgroundImage: `url(${CouponImg})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                        <div className='tw-w-[332px] tw-h-[40px] phone:tw-w-[232px] tw-mx-[50px] tw-absolute tw-bottom-[30px] phone:tw-bottom-[10px] phone:tw-mx-[30px]  tw-text-[18px] phone:tw-text-[14px] tw-text-[#f86d45] tw-text-center'>Copy successfully, use at checkout</div>
                    </div>
                </div>}
                <ShoppingCartModal fromPage='productDetail' showModal={showShoppingCartModal} toggleShoppingCartModal={this.toggleShoppingCartModal} {...this.props} />
                <div className='tw-mt-[20px] tw-w-full phone:tw-h-auto pad:tw-h-auto tw-overflow-hidden tw-mx-auto tw-flex tw-justify-center phone:tw-flex-wrap pad:tw-flex-wrap '>
                    <div className='phone:tw-w-full pad:tw-w-[485px] tw-w-[600px] phone:tw-h-auto tw-h-[650px] tw-flex phone:tw-flex-col-reverse phone:tw-justify-start pad:tw-justify-start pad:tw-flex-col-reverse  tw-items-center ' style={{ marginLeft: (isMobile || isTablet ? '0px' : marginWidth + 'px') }}>
                        <Swiper
                            direction={(isMobile || isTablet) ? 'horizontal' : 'vertical'}
                            onSwiper={this.setThumbsSwiper}
                            slidesPerView={6}
                            className="phone:tw-w-full pad:tw-w-full tw-w-[100px] phone:tw-h-[50px] pad:tw-h-[100px] tw-h-[500px] phone:tw-mt-[20px] pad:tw-mt-[20px] phone:tw-px-[30px]">
                            {productMap && productMap.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div key={item} className='tw-w-[72px] tw-h-[72px] phone:tw-w-[50px] phone:tw-h-[50px] tw-rounded-[10px] tw-relative' style={{ backgroundImage: `url(${BASE_URL + item})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>

                        <Swiper
                            className='tw-w-[485px] phone:tw-w-full phone:tw-max-w-[600px]'
                            // navigation
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[Thumbs]}>
                            {productMap && productMap.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div className='tw-w-[485px] tw-h-[485px] phone:tw-w-full phone:tw-max-w-[600px] phone:tw-max-h-[600px] phone:tw-h-0 phone:tw-pb-[100%] tw-relative tw-bg-white' style={{ backgroundImage: `url(${BASE_URL + item})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>
                    </div>
                    <div className='phone:tw-w-[calc(100%-60px)] pad:tw-w-[calc(100%-485px)] tw-w-[calc(100%-600px)] tw-h-full phone:tw-h-auto tw-overflow-y-scroll scrollbar-hidden phone:tw-mt-[20px] phone:tw-mx-[30px]' ref={this.descContainerRef}>
                        <div className='phone:tw-w-full pad:tw-w-[calc(100%-40px)] tw-w-[calc(1440px-670px)] tw-min-w-[450px] phone:tw-min-w-full phone:tw-pl-0 pad:tw-pl-[40px] tw-pl-[80px]  SFNS-font tw-text-body-text-1 '>
                            <div className='tw-text-[26px] tw-text-brand-primary tw-font-[400]'>{currentProduct && currentProduct.title}</div>
                            <div className='tw-text-[18px] tw-text-brand-primary tw-opacity-[0.7] tw-mt-[15px]'>{currentProduct && currentProduct.miaosu}</div>
                            <div className='tw-text-[24px] tw-mt-[15px] tw-flex tw-items-center tw-text-brand-primary'>
                                <div className='SFNS-font tw-font-[600]'>${currentProduct && currentPrice}</div>
                                <div className='tw-ml-[15px] tw-text-body-text-4 tw-text-[16px] tw-line-through SFNS-font'>${currentProduct && originalPrice}</div>
                                <div className='tw-ml-[15px] tw-h-[30px] tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-bg-brand-primary tw-bg-opacity-[0.8] tw-text-white tw-font-[300] tw-px-[10px] SFNS-font'>Sale</div>
                            </div>
                            {baseConfig && baseConfig.coupon && <div className='tw-mt-[20px] tw-rounded-[10px] tw-w-[404px] phone:tw-w-[100%] tw-h-[90px] tw-bg-[#ffefde] tw-flex tw-justify-center tw-items-center tw-relative SFNS-font'>
                                <div className='tw-rounded-[10px] tw-w-[396px] phone:tw-w-full tw-h-[80px] tw-text-white product-coupon-container tw-flex tw-relative'>
                                    <div className='tw-w-[60%] tw-border-r-[2px] tw-border-dashed tw-border-[#ffefde] tw-px-[24px] tw-flex tw-flex-col tw-justify-center'>
                                        <div className='tw-text-[22px] tw-font-[300]'>{baseConfig.coupon}% OFF</div>
                                        <div className='tw-text-[14px] tw-font-[300'>Extra</div>

                                    </div>
                                    <div className='tw-absolute tw-top-[-3px] tw-left-[calc(60%-4px)] tw-w-[6px] tw-h-[6px] tw-bg-[#ffefde] tw-rotate-45'></div>
                                    <div className='tw-absolute tw-bottom-[-3px] tw-left-[calc(60%-4px)] tw-w-[6px] tw-h-[6px] tw-bg-[#ffefde] tw-rotate-45'></div>
                                    <div className='tw-w-[40%] tw-flex tw-flex-col tw-justify-center tw-items-center'>
                                        <div className='tw-text-[12px]'>Copy code</div>
                                        <div className='tw-mt-[5px] tw-px-[10px] tw-py-[5px] tw-bg-[#FEE7CD] tw-rounded-full tw-flex tw-items-center tw-text-light-red'>{baseConfig.couponCode} <CopyIcon onClick={this.handleReceiveCoupon} size={18} className="tw-ml-[10px]" /></div>
                                    </div>
                                </div>
                                <svg className="app-coupon-triangle app-coupon-triangle-0" xmlns="http://www.w3.org/2000/svg" width="106" height="96" viewBox="0 0 106 96" fill="none"><path d="M102.929 68.555c6.833 12.054-1.946 26.985-15.802 26.875l-68.312-.542C4.959 94.778-3.582 79.71 3.441 67.766l34.625-58.89C45.09-3.067 62.41-2.93 69.242 9.125l33.687 59.43z" fill="#fff" fillOpacity=".11"></path></svg>
                                <svg className="app-coupon-triangle app-coupon-triangle-1" xmlns="http://www.w3.org/2000/svg" width="106" height="96" viewBox="0 0 106 96" fill="none"><path d="M102.929 68.555c6.833 12.054-1.946 26.985-15.802 26.875l-68.312-.542C4.959 94.778-3.582 79.71 3.441 67.766l34.625-58.89C45.09-3.067 62.41-2.93 69.242 9.125l33.687 59.43z" fill="#fff" fillOpacity=".11"></path></svg>
                                <svg className="app-coupon-triangle app-coupon-triangle-2" xmlns="http://www.w3.org/2000/svg" width="106" height="96" viewBox="0 0 106 96" fill="none"><path d="M102.929 68.555c6.833 12.054-1.946 26.985-15.802 26.875l-68.312-.542C4.959 94.778-3.582 79.71 3.441 67.766l34.625-58.89C45.09-3.067 62.41-2.93 69.242 9.125l33.687 59.43z" fill="#fff" fillOpacity=".11"></path></svg>
                            </div>}

                            <div className='tw-mt-[20px] SFNS-font tw-text-body-text-5 '>
                                {currentProduct && currentProduct.skulist && currentProduct.skulist.map((sku, index) => {
                                    return <div className='tw-mb-[20px]' key={sku.title}>
                                        <div className='tw-text-[18px] tw-flex tw-items-center tw-font-[600] tw-text-brand-primary'>{sku.title}</div>
                                        <div className='tw-flex tw-flex-wrap tw-mt-[10px]'>{sku.data.map(item => {
                                            return <div key={item} className={`tw-min-w-[118px] tw-max-w-[500px] tw-min-h-[50px] tw-h-auto tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-border tw-border-brand-primary tw-rounded-[10px] tw-px-[15px] tw-py-[10px] tw-mr-[20px] tw-mb-[10px] tw-text-[16px] tw-text-brand-primary tw-text-center ${sku.selectedValue.skuid === item.skuid ? 'tw-bg-brand-primary tw-text-white' : ''}`} onClick={() => this.handleSelectSku(index, item)}>
                                                {item.content}
                                            </div>
                                        })}</div>
                                    </div>
                                })}
                            </div>
                            <div className='tw-mt-[20px] SFNS-font tw-text-brand-primary'>
                                <div className='tw-text-[16px]'>Quantity:</div>
                                <div className='tw-mt-[8px]'>
                                    <QuantityController ref={this.quantityRef} />
                                </div>
                            </div>
                            <div className='tw-mt-[24px]'>
                                <RDSButton className="SFNS-font tw-w-[400px] phone:tw-w-full tw-mt-[24px] tw-border tw-border-brand-primary tw-text-brand-primary tw-h-[50px] tw-font-[300] !tw-text-[18px] tw-text-white tw-rounded-[6px]" color="primary" onClick={this.addProductToCart}>Add to cart</RDSButton>
                                <RDSButton className="SFNS-font tw-w-[400px] phone:tw-w-full tw-mt-[24px] tw-border tw-border-brand-primary tw-text-brand-primary  tw-h-[50px] tw-font-[300] !tw-text-[18px] tw-rounded-[6px]" color="white" onClick={this.handleCheckoutCurrentProduct}>Buy it now</RDSButton>
                            </div>

                        </div>

                        {/*  */}
                        {/* {!isTablet && this.renderProductDesc()} */}

                    </div>
                </div>
                <div className='tw-mt-[20px] tw-px-[100px] phone:tw-px-[30px] tw-w-full phone:tw-h-auto pad:tw-h-auto tw-overflow-hidden tw-mx-auto  '>
                    {this.renderProductDesc()}

                    <div className='tw-w-[1240px] phone:tw-w-full pad:tw-w-full tw-mx-auto  phone:tw-h-auto SFNS-font tw-relative'>
                        {formatProductTagList && formatProductTagList.map((tag, index) => {
                            return (<React.Fragment>
                                <div className='tw-w-full SFNS-font tw-text-[32px] tw-mb-[15px]'>{tag.title}</div>
                                <div className='tw-w-full tw-h-auto tw-mb-[132px] phone:tw-mb-[50px]'>
                                    <div className='tw-w-full tw-h-full tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                        {tag.productList && tag.productList.map((item, index) => {
                                            const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);

                                            const originalPrice = parseFloat(item.originalPrice);
                                            const currentPrice = parseFloat(item.currentPrice);
                                            const discount = Math.floor(((originalPrice - currentPrice) / originalPrice) * 100);

                                            return (<div className="tw-w-[20%] phone:tw-w-[47%] tw-overflow-hidden tw-mb-[30px]" key={item.id} onClick={() => this.routeToProductDetailPage(item.id)}>
                                                <div className='tw-overflow-hidden tw-w-full tw-rounded-[15px] '>
                                                    <div className='tw-w-full tw-pb-[100%] tw-relative product-img' style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} onMouseEnter={this.handleProductEnter}>
                                                        <div className='tw-absolute tw-top-[10px] tw-left-[10px] tw-font-[600] tw-text-white tw-font-[20px] tw-px-[10px] tw-py-[3px] tw-rounded-[8px] tw-bg-brand-primary'>{discount}% OFF</div>
                                                    </div>
                                                </div>

                                                <div className='tw-mt-[10px] tw-h-[50px] tw-line-clamp-2 tw-opacity-[0.8] tw-text-[15px]'>{item.title}</div>
                                                <div className='tw-flex tw-items-center tw-my-[5px]'>
                                                    {new Array(item.star).fill(undefined).map((item, i) => {
                                                        return <StarIcon className="tw-mr-[2px]" size={20} key={i} />
                                                    })}
                                                </div>
                                                <div className='tw-flex tw-justify-between tw-items-center'>
                                                    <div className='tw-line-through'>${originalPrice}</div>
                                                    <div className='tw-text-light-red tw-text-[20px]'>${currentPrice}</div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </React.Fragment>)
                        })}
                    </div>
                </div>

            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        currentProduct: ProductDetailSelectors.selectCurrentProduct(state),
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
        shoppingCartArr: ShoppingCartSelectors.selectShoppingCartData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActionCreator, dispatch),
        tagActions: bindActionCreators(tagActionCreator, dispatch),
        shoppingCartActions: bindActionCreators(shoppingCartActionCreator, dispatch),
        // commentActions: bindActionCreators(productCommentActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
