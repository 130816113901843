import React from 'react';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import markdownToDraft from './rich_text_renderer/markdown-to-draft';
import SvgIcon from '../../common/SvgIcon';
import EDSRichTextRendererUtil from './rich_text_renderer/EDSRichTextRendererUtil';
import I18N from '../../../i18n';

const SVG_ICON_SIZE = 20;

const createRichTextEditorState = (initialText, decorator, options = {
  preserveNewlines: true,
  blockStyles: { del_open: 'STRIKETHROUGH'}
  }) => {
  if (initialText) {
    if(EDSRichTextRendererUtil.isDraftData(initialText)){
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(initialText)),
        decorator
      )
    }else{
      return EditorState.createWithContent(
        convertFromRaw(markdownToDraft(initialText.replace(/\n\s{4}/g, `\n&nbsp;&nbsp;&nbsp;&nbsp;`), options)),
        decorator
      )
    }
  }

  return EditorState.createEmpty(decorator);
}

const convertToRawString = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return JSON.stringify(convertToRaw(contentState))
}

const addQaTest = () => {
  const placeholderNodes = document.getElementsByClassName('public-DraftEditor-content');
  if (placeholderNodes.length > 0) {
    placeholderNodes[0].classList += ' qa-test-richEditer-placeholder'
  }
}

const toolbarItemFontSize = {
  label: <SvgIcon name="header-icon" size={SVG_ICON_SIZE} />,
  children: [
    {
      label:  (<h1>{I18N.getText('rich-text-large-title')}</h1>),
      style: 'header-one',
      qaLocator: "qa-test-rich-text-large-title",
    },
    {
      label: (<h2>{I18N.getText('rich-text-medium-title')}</h2>),
      style: 'header-two',
      qaLocator: "qa-test-rich-text-medium-title",
    },
    {
      label: (<p>{I18N.getText('rich-text-regular-text')}</p>),
      style: 'unstyled',
      qaLocator: "qa-test-rich-text-regular-text",
    },
  ],
  qaLocator: "qa-test-rich-text-title-icon",
};

const toolbarItemBold = {
  label: <SvgIcon name="bold-icon" size={SVG_ICON_SIZE} />,
  style: 'BOLD',
  qaLocator: "qa-test-rich-text-bold",
};

const toolbarItemItalic = {
  label: <SvgIcon name="italic-icon" size={SVG_ICON_SIZE} />,
  style: 'ITALIC',
  qaLocator: "qa-test-rich-text-italic",
};

const toolbarItemStrikeThrough = {
  label: <SvgIcon name="strike-through-icon" size={SVG_ICON_SIZE} />,
  style: 'STRIKETHROUGH',
  qaLocator: "qa-test-rich-text-strick-through",
};

const toolbarItemUnorderedList = {
  label: <SvgIcon name="unordered-list-icon" size={SVG_ICON_SIZE} />,
  style: 'unordered-list-item',
  qaLocator: "qa-test-rich-text-unordered-list",
};

const toolbarItemOrderedList = {
  label: <SvgIcon name="ordered-list-icon" size={SVG_ICON_SIZE} />,
  style: 'ordered-list-item',
  qaLocator: "qa-test-rich-text-ordered-list",
};

const toolbarItemCodeBlock = {
  label: <SvgIcon name="code-block-icon" size={SVG_ICON_SIZE} />,
  style: 'code-block',
  qaLocator: "qa-test-rich-text-code-block",
};

const toolbarItemMathBlock = {
  label: <SvgIcon name="math-block-icon" size={SVG_ICON_SIZE} />,
  style: 'math-block',
  qaLocator: "qa-test-rich-text-math-block",
};

const toolbarItemLink = {
  label: <SvgIcon name="link-icon" size={SVG_ICON_SIZE} />,
  style: 'add-link',
  qaLocator: "qa-test-rich-text-link",
};

const splitItem = {
  label: <span className='split-item'>|</span>,
  style: ''
}

const defaultToolbar = [
  toolbarItemFontSize,
  splitItem,
  toolbarItemBold,
  toolbarItemItalic,
  toolbarItemStrikeThrough,
  splitItem,
  toolbarItemUnorderedList,
  toolbarItemOrderedList,
  splitItem,
  toolbarItemCodeBlock,
  toolbarItemMathBlock,
  toolbarItemLink,
];

const demoString = `# Fibonacci Sequence
In mathematics, the **Fibonacci numbers**, commonly denoted Fn, form a sequence, called the **Fibonacci sequence**, such that each number is the sum of the two preceding ones, starting from 0 and 1
This post is about #computerenginerring and #algorithms. Please remember that the #1 thing to remember is to always test your code
    test 4 spaces
this \\*\\*not markdown\\*\\* and \\*also not markdown\\*

~~This is a ruby implementation~~
\`code\`
_italic_

\`\`\`
#include
# not a title
def fib(n)
  first_num, second_num = [0, 1]
  (n - 1).times do
    first_num, second_num = second_num, first_num + second_num
  end
  # just pring the numbers
  puts first_num
end
\`\`\`
If you want to check all available implementations, please go to [my github](https://nasa.gov)

Things to pay close attention:
- The fibonnaci sequence starts in 0
- 1 is the only number in the sequence that repeats itself
- the reason for that is that 0 + 1 = 1
    - an indented item

https://google.com/#/rock
    
~~#testnothashtagwithstyle1~~
**#testnothashtagwithstyle2**

For more info on the fibonacci sequence, please check:
https://en.wikipedia.org/wiki/Fibonacci_number`;

const hashTags = [
  {
    name: 'matthew',
    number: '15,000+'
  },
  {
    name: 'julian',
    number: '99,000+'
  },
  {
    name: 'jyoti',
    number: '1,000+'
  },
  {
    name: 'nik',
    number: '2,000+'
  },
  {
    name: 'pascal',
    number: '12+'
  },
  {
    name: 'mvvvaaa',
    number: '15,000+'
  },
  {
    name: 'mqqbb',
    number: '99,000+'
  },
  {
    name: 'mzzdd',
    number: '1,000+'
  },
  {
    name: 'mffvv',
    number: '2,000+'
  },
  {
    name: 'hello',
    number: '12+'
  },
  {
    name: 'mzzaaaa',
    number: '12+'
  },
];



export {
  convertToRawString,
  createRichTextEditorState,
  demoString,
  hashTags,
  toolbarItemFontSize,
  toolbarItemBold,
  toolbarItemItalic,
  toolbarItemStrikeThrough,
  toolbarItemUnorderedList,
  toolbarItemOrderedList,
  toolbarItemCodeBlock,
  toolbarItemMathBlock,
  toolbarItemLink,
  defaultToolbar,
  addQaTest
}
