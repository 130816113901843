//    ____ _____ ___  ____
//   / ___|_   _/ _ \|  _ \
//   \___ \ | || | | | |_) |
//    ___) || || |_| |  __/
//   |____/ |_| \___/|_|
//
//  This component is part of the Syllabus (EDS) project
//  Before making any updates to it, make sure you read the "Contributing to Syllabus" section in http://guide.clubmodo.com/syllabus/
//

import React from 'react';
import classnames from 'classnames';

import { RDSPopover, RDSPopoverBtn } from '../../popover/index'
// === Utils === //
import isEmpty from 'lodash/isEmpty';
import './EDSRichTextInputToolbar.scss'

const StyleButton = ({ onToggle, active, label, style, disabled, qaLocator }) => {

  const className = classnames('RichEditor-styleButton', 'qa-test-richEditor-styleButton', { 'RichEditor-activeButton': active }, qaLocator)
  return (
    <button
      className={className}
      disabled={disabled}
      onMouseDown={e => {
        e.preventDefault();
        !disabled && onToggle && onToggle(style);
      }}
    >
      {label}
    </button>
  );
}

const EDSRichTextInputToolbar = ({ className, editorState, handleClick, toolbarItems, onMouseEnter }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className={classnames("RichEditor-toolbar", className)} onMouseEnter={onMouseEnter}>
      {
        toolbarItems.map((type, index) => {
          if (isEmpty(type.children)) {
            return <StyleButton
              key={index}
              disabled={type.disabled}
              active={type.style === blockType || currentStyle.has(type.style)}
              label={type.label}
              onToggle={handleClick}
              style={type.style}
              qaLocator={type.qaLocator}
            />
          }

          const isActive = blockType !== 'unstyled' && type.children.map(child => child.style).includes(blockType);
          const classNameButton = classnames(`${type.qaLocator} RichEditor-headerButton`, { 'RichEditor-activeButton': isActive });

          return (
            <RDSPopover key={index} strategy='fixed' buttonAs='button' disabled={type.disabled} classNameBtn={classNameButton} className='tw-inline-block'>
              {type.label}
              <div role="menu">
                {
                  type.children.map((item, dropdownIndex) => (
                    <RDSPopoverBtn as='button' className={`dropdown-item ${item.qaLocator}`} onClick={() => { handleClick(item.style) }} key={dropdownIndex}>
                      {item.label}
                    </RDSPopoverBtn>
                  ))
                }
              </div>
            </RDSPopover>
          )
        })
      }
    </div>
  );
}

export default EDSRichTextInputToolbar;
