//    ____ _____ ___  ____
//   / ___|_   _/ _ \|  _ \
//   \___ \ | || | | | |_) |
//    ___) || || |_| |  __/
//   |____/ |_| \___/|_|
//
//  This component is part of the Syllabus (EDS) project
//  Before making any updates to it, make sure you read the "Contributing to Syllabus" section in http://guide.clubmodo.com/syllabus/
//
//  Documentation on this component can be found at: http://guide.clubmodo.com/storybook/
//

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  EditorState,
  RichUtils,
  Modifier,
  CompositeDecorator,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
// import createMentionPlugin, {
//   defaultSuggestionsFilter,
// } from '@draft-js-plugins/mention';
import classnames from 'classnames';

// === Utils === //
import noop from 'lodash/noop';
import toUpper from 'lodash/toUpper';
import get from 'lodash/get';
import {
  getEntityRange,
  getSelectionEntity,
  addNewLineWithoutStyle,
  isFocusOnEndOfBlock,
  getCurrentBlock,
  findLinkEntities,
  getSelectionText,
  removeInlineStyles,
} from './DraftJSUtil';
import flattenDeep from 'lodash/flattenDeep';

// === Components === //
import EDSRichTextInputToolbar from './EDSRichTextInputToolbar';
// import HashTagEntry from './HashTagEntry';
import { defaultToolbar, convertToRawString, createRichTextEditorState, addQaTest, hashTags } from '../Util';
import LinkItem from './EDSLinkItem';
import AddLinkModal from './AddLinkModal';

// === Styles === //
import 'draft-js/dist/Draft.css';
// import mentionsStyles from './MentionsStyles.module.css';
import './EDSRichTextInput.scss';

// === Constants === //
import DraftjsBlockConstants from '../../../../constants/DraftjsBlockConstants';
import DraftjsCommandConstants from '../../../../constants/DraftjsCommandConstants';
import DraftjsEditorChangeTypeConstants from '../../../../constants/DraftjsEditorChangeTypeConstants';

const toolbarPositionValues = ['top', 'bottom'];

const toggleBlockTypeArray = [
  DraftjsBlockConstants.ORDERED_LIST_ITEM,
  DraftjsBlockConstants.UNORDERED_LIST_ITEM,
  DraftjsBlockConstants.CODE_BLOCK,
  DraftjsBlockConstants.MATH_BLOCK,
  DraftjsBlockConstants.HEADER_ONE,
  DraftjsBlockConstants.HEADER_TWO,
  DraftjsBlockConstants.UNSTYLED
];
const toggleInlineStyleArray = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH'];

class EDSRichTextInput extends Component {
  constructor(props) {
    super(props);

    // Method binding need to come first since we use some bound methods
    // when creating the local state
    this.focus                 = this.focus.bind(this);
    this.onChange              = this.onChange.bind(this);
    this.onFocus              = this.onFocus.bind(this);
    this.handleAddLink         = this.handleAddLink.bind(this);
    this.handleEditLink        = this.handleEditLink.bind(this);
    this.handleRemoveLink      = this.handleRemoveLink.bind(this);
    this.handlePastedText      = this.handlePastedText.bind(this);
    this.createEditorState     = this.createEditorState.bind(this);
    this.handleKeyCommand      = this.handleKeyCommand.bind(this);
    this.handleToolbarClick    = this.handleToolbarClick.bind(this);
    this.toggleAddLinkModal    = this.toggleAddLinkModal.bind(this);
    this.mapKeyToEditorCommand = this.mapKeyToEditorCommand.bind(this);
    this.shouldHidePlaceholder = this.shouldHidePlaceholder.bind(this);
    // this.onSearchHashTagChange = this.onSearchHashTagChange.bind(this);
    this.showToolbar           = this.showToolbar.bind(this);
    this.refEditor             = createRef();

    this.linkDecorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: LinkItem,
        props: {
          onEdit               : this.handleEditLink,
          onRemove             : this.handleRemoveLink,
        }
      },
    ]);
    this.hashtagPlugin = createHashtagPlugin({theme: {hashtag:'tw-text-brand-primary'}});
    this.linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
    // this.mentionPlugin = createMentionPlugin({
    //   mentionPrefix: '#',
    //   mentionTrigger: '#',
    //   entityMutability: 'IMMUTABLE',
    //   theme: mentionsStyles,
    // });
    this.plugins = [this.linkifyPlugin];

    // const { MentionSuggestions } = this.mentionPlugin;
    if (this.props.enableHashtags){
      this.plugins.push(this.hashtagPlugin);
      // nextPlugins.push(this.mentionPlugin);
    }

    const currentEditorState = createRichTextEditorState(props.value, this.linkDecorator);

    this.state = {
      isAddLinkModalOpen : false,
      addLinkText        : '',
      addLinkUrl         : '',
      currentEditorState,
      currentEntity      : getSelectionEntity(currentEditorState),
      hashTags           : props.hashTags,
      isShowToolbar      : false,
      isMentionOpen      : false,
      suggestions        : hashTags,
    };
  }

  handleToolbarClick(blockType) {
    if(!blockType) return;
    const { currentEditorState, currentEntity } = this.state;
    const { onChange } = this;
    if (toggleBlockTypeArray.includes(blockType)) {
      let newEditorState = currentEditorState;
      const currentStyle = currentEditorState.getCurrentInlineStyle();
      if (currentStyle.has('BOLD')) newEditorState = RichUtils.toggleInlineStyle(currentEditorState, 'BOLD');
      if (currentStyle.has('ITALIC')) newEditorState = RichUtils.toggleInlineStyle(newEditorState ? newEditorState : currentEditorState, 'ITALIC');
      newEditorState = RichUtils.toggleBlockType(newEditorState, blockType);
      onChange(removeInlineStyles(newEditorState));
    } else if (toggleInlineStyleArray.includes(blockType)) {
      const newEditorState = RichUtils.toggleInlineStyle(currentEditorState, blockType);
      onChange(newEditorState);
    } else if (blockType === DraftjsCommandConstants.ADD_LINK) {
      let url = '';
      let currentSelectText = '';
      const contentState = currentEditorState.getCurrentContent();

      if (currentEntity) {
        const entity = contentState.getEntity(currentEntity);
        const entityRange = getEntityRange(currentEditorState, currentEntity);
        currentSelectText = entityRange.text;
        url = get(entity.get('data'), 'url', '');
      } else {
        currentSelectText = getSelectionText(currentEditorState);
      }
      this.handleEditLink({ decoratedText: currentSelectText, url });
    }

    // Focus on input again after click on toobar
    this.focus();
  }

  toggleAddLinkModal() {
    this.setState({ isAddLinkModalOpen: !this.state.isAddLinkModalOpen });
  }

  handleKeyCommand(command, editorState) {
    const { onChange } = this;
    const selection = editorState.getSelection();
    const currentBlock = getCurrentBlock(editorState);
    const type = currentBlock.getType();
    // in 'code-block' and 'math-block, user couldn't use any inline style
    if ((type === DraftjsBlockConstants.CODE_BLOCK || type === DraftjsBlockConstants.MATH_BLOCK) && toggleInlineStyleArray.includes(toUpper(command))) {
      return 'handled';
    }

    if (command === DraftjsCommandConstants.ADD_NEWLINE) {
      const nextEditorState = RichUtils.insertSoftNewline(editorState);
      onChange(nextEditorState);
      return 'handled';
    } else if (command === DraftjsCommandConstants.RESET_TO_UNSTYLE) {
      const nextEditorState = addNewLineWithoutStyle(editorState);
      onChange(nextEditorState);
      return 'handled';
    } else if (command === DraftjsCommandConstants.CODE_BLOCK_TAB) {
      let contentState = editorState.getCurrentContent();
      let indentation = '    ';
      let newContentState;

      if (selection.isCollapsed()) {
        newContentState = Modifier.insertText(
          contentState,
          selection,
          indentation
        );
      } else {
        newContentState = Modifier.replaceText(
          contentState,
          selection,
          indentation
        );
      }
      onChange(EditorState.push(
        editorState,
        newContentState,
        DraftjsEditorChangeTypeConstants.INSERT_CHARACTERS
      ));
      return 'handled';
    }

    const nextState = RichUtils.handleKeyCommand(editorState, command);
    if (nextState) {
      onChange(nextState);
      return 'handled';
    }
    return 'not-handled';
  }

  mapKeyToEditorCommand(e) {
    const { currentEditorState } = this.state;
    const { onChange } = this;
    const currentBlock = getCurrentBlock(currentEditorState);
    const currentBlockType = currentBlock.getType();
    const selection = currentEditorState.getSelection();

    if (e.keyCode === 9) { // TAB
      if (currentBlockType === DraftjsBlockConstants.CODE_BLOCK) {
        return DraftjsCommandConstants.CODE_BLOCK_TAB;
      }
      const nextEditorState = RichUtils.onTab(e, currentEditorState, 1);

      if (nextEditorState !== currentEditorState) {
        onChange(nextEditorState);
      }
      return false;
    } else if (e.keyCode === 13) { // Enter
      if (currentBlockType.includes('header')) {
        return DraftjsCommandConstants.RESET_TO_UNSTYLE;
      }
      if (currentBlockType === DraftjsBlockConstants.CODE_BLOCK || currentBlockType === DraftjsBlockConstants.MATH_BLOCK) {
        return DraftjsCommandConstants.ADD_NEWLINE;
      }
    } else if (e.keyCode === 85) { // U
      // We don't support underline, so disable keyboard command for it
      return null;
    } else if (e.keyCode === 40) { // Down
      if (currentBlockType === DraftjsBlockConstants.CODE_BLOCK || currentBlockType === DraftjsBlockConstants.MATH_BLOCK) {
        if(isFocusOnEndOfBlock(selection, currentBlock)){
          return DraftjsCommandConstants.RESET_TO_UNSTYLE;
        }
      }
    }

  }

  shouldHidePlaceholder() {
    // If the user changes block type before entering any text,
    // we hide the
    let { currentEditorState } = this.state;

    let contentState = currentEditorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== DraftjsBlockConstants.UNSTYLED) {
        return true;
      }
    }
    return false;
  }

  getBlockStyle(block) {
    switch (block.getType()) {
      case DraftjsBlockConstants.CODE_BLOCK:
      case DraftjsBlockConstants.MATH_BLOCK:
        return 'RichEditor-pre';
      case DraftjsBlockConstants.HEADER_ONE:
        return 'RichEditor-H1';
      case DraftjsBlockConstants.HEADER_TWO:
        return 'RichEditor-H2';
      default:
        return null;
    }
  }

  focus() {
    // Hacky: Wait to focus the editor so we don't lose selection.
    setTimeout(() => {
      this.refEditor.current ? this.refEditor.current.focus() : this.focus();
    }, 50);
  }

  handlePastedText (text, html, editorState) {
    const currentBlock = getCurrentBlock(editorState);
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    if (currentBlock.getType() === DraftjsBlockConstants.CODE_BLOCK) {
      const newContentState = Modifier.replaceText(
        contentState,
        selection,
        text
      );
      this.onChange(EditorState.push(
        editorState,
        newContentState,
        DraftjsEditorChangeTypeConstants.INSERT_CHARACTERS
      ))
      return 'handled'
    }
    return 'not-handled'
  }

  handleAddLink(linkTitle, linkTarget) {
    const { currentEditorState } = this.state;
    const { onChange } = this;
    const { currentEntity } = this.state;
    let selection = currentEditorState.getSelection();

    if (currentEntity) {
      const entityRange = getEntityRange(currentEditorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
    }
    const entityKey = currentEditorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', {
        url: linkTarget,
      })
      .getLastCreatedEntityKey();

    let contentState = Modifier.replaceText(
      currentEditorState.getCurrentContent(),
      selection,
      `${linkTitle}`,
      currentEditorState.getCurrentInlineStyle(),
      entityKey
    );
    let newEditorState = EditorState.push(
      currentEditorState,
      contentState,
      'insert-characters'
    );

    // insert a blank space after link
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get('anchorOffset') + linkTitle.length,
      focusOffset: selection.get('anchorOffset') + linkTitle.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    this.setState({
      isAddLinkModalOpen: false,
      addLinkText: '',
      addLinkUrl: ''
    }, this.focus);
    onChange(
      EditorState.push(newEditorState, contentState, 'insert-characters')
    );
  }

  handleRemoveLink() {
    const { currentEditorState, currentEntity } = this.state;
    const { onChange } = this;
    let selection = currentEditorState.getSelection();
    if (currentEntity) {
      const entityRange = getEntityRange(currentEditorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
      onChange(RichUtils.toggleLink(currentEditorState, selection, null));
    }
  }

  handleEditLink({ decoratedText: text, url }) {
    this.setState({
      isAddLinkModalOpen: true,
      addLinkText: text,
      addLinkUrl: url
    })
  }

  onChange(nextEditorState) {
    const { onChange } = this.props;
    const decorators = flattenDeep(this.plugins.map((plugin) => plugin.decorators));
    const decorator = new CompositeDecorator(decorators.concat(
      {
        strategy: findLinkEntities,
        component: LinkItem,
        props: {
          onEdit               : this.handleEditLink,
          onRemove             : this.handleRemoveLink,
        }
      },
    ));
    this.setState({
      currentEditorState : EditorState.set(nextEditorState, {decorator}),
      currentEntity      : getSelectionEntity(nextEditorState)
    },() => {
      if (onChange) {
        if(!nextEditorState.getCurrentContent().hasText()){
          onChange('')
        }else{
          onChange(convertToRawString(nextEditorState));
        }
      }
    });

    if (onChange && nextEditorState.getCurrentContent().hasText()) {
      if(!nextEditorState.getCurrentContent().hasText()){
        onChange('')
      }else{
        onChange(convertToRawString(nextEditorState));
      }
    }
  }

  createEditorState(markdownValue = null) {
    return createRichTextEditorState(markdownValue, this.linkDecorator);
  }

  onFocus(params) {
    this.showToolbar()
    this.props.onFocus && this.props.onFocus(params)
  }

  showToolbar() {
    this.props.hideToolbarOnBlur && this.setState({isShowToolbar: true})
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { value: prevValue } = prevProps;
    const { currentEditorState } = this.state;

    // If parent component updates the value, we need to create a new EditorState.
    // This can happen for example after a API call returns with the value that needs to be
    // inserted in the input
    if (value !== prevValue && value !== convertToRawString(currentEditorState)) {
      this.setState({
        currentEditorState : this.createEditorState(value),
      })
    }
  }

  // onSearchHashTagChange({ value }) {
  //   if (value || value === '') {
  //     this.setState({
  //       suggestions: defaultSuggestionsFilter(value, hashTags)
  //     });
  //   }
  // }

  componentDidMount() {
    if(this.props.autoFocus){
      this.focus()
    }
    addQaTest();
  }

  render() {
    const {
      toolbarItems,
      className,
      qaSelector,
      placeholder,
      customStyleMap,
      showBorder,
      toolbarPosition,
      enableHashtags,
      enablePureHashTag,
      hideToolbarOnBlur,
      readOnly,
      ...editorProps
    } = this.props;
    const { currentEditorState, isAddLinkModalOpen, addLinkText, addLinkUrl, isShowToolbar, /*isMentionOpen, suggestions*/ } = this.state;
    const editorClassName = classnames(
      'RichEditor-editor qa-test-RichEditor qa-test-EDS-RichTextRenderer-Markdown',
      { 'RichEditor-hidePlaceholder': this.shouldHidePlaceholder() }
    );

    const isToolbarOnTop = toolbarPosition === toolbarPositionValues[0];

    const currentBlockType = RichUtils.getCurrentBlockType(currentEditorState);
    const hasFocus = currentEditorState.getSelection().getHasFocus();
    const nextToolbarItems = toolbarItems.map(item => {
      const { style } = item;
      if (style === DraftjsCommandConstants.ADD_LINK) {
        return {
          ...item,
          disabled: !hasFocus || currentBlockType === DraftjsBlockConstants.CODE_BLOCK || currentBlockType === DraftjsBlockConstants.MATH_BLOCK || readOnly
        }
      }
      return {
        ...item,
        disabled: (currentBlockType === DraftjsBlockConstants.CODE_BLOCK && style !== DraftjsBlockConstants.CODE_BLOCK) || (currentBlockType === DraftjsBlockConstants.MATH_BLOCK && style !== DraftjsBlockConstants.MATH_BLOCK) || readOnly,
      }
    });
    // if hideToolbarOnBlur === true and not focus. Hide the toolbar
    const toolbar = (hideToolbarOnBlur && !isShowToolbar)
      ? null
      : <EDSRichTextInputToolbar
        editorState={currentEditorState}
        toolbarItems={nextToolbarItems}
        handleClick={this.handleToolbarClick}
      />
    ;

    return (
      <div className={classnames('RichEditor-root', qaSelector, className, { 'RichEditor-border': showBorder })}>
        { isToolbarOnTop && toolbar }

        <div className={editorClassName}>
          <Editor
            {...editorProps}
            blockStyleFn={this.getBlockStyle}
            placeholder={placeholder}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            customStyleMap={customStyleMap}
            editorState={currentEditorState}
            handlePastedText={this.handlePastedText}
            onChange={this.onChange}
            onFocus={this.onFocus}
            ref={this.refEditor}
            plugins={this.plugins}
            readOnly={readOnly}
          />
        </div>
        {/* {
          enableHashtags &&
          <MentionSuggestions
            open={isMentionOpen}
            onOpenChange={(open) => this.setState({isMentionOpen: open})}
            onSearchChange={this.onSearchHashTagChange}
            suggestions={suggestions}
            entryComponent={HashTagEntry}
          />
        } */}
        { !isToolbarOnTop && toolbar }
        <AddLinkModal
          defaultText={addLinkText}
          defaultUrl={addLinkUrl}
          onSubmit={this.handleAddLink}
          toggle={this.toggleAddLinkModal}
          isOpen={isAddLinkModalOpen}
        />
      </div >
    );
  }
}


EDSRichTextInput.propTypes = {
  className: PropTypes.string,
  qaSelector: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
  toolbarPosition: PropTypes.oneOf(toolbarPositionValues),
  customStyleMap: PropTypes.object,
  toolbar: PropTypes.array,
  hideToolbarOnBlur: PropTypes.bool,
  autoFocus:PropTypes.bool
}

EDSRichTextInput.defaultProps = {
  className: '',
  toolbarItems: defaultToolbar,
  placeholder: '',
  value: '',
  toolbarPosition: toolbarPositionValues[0],
  onChange: noop,
  showBorder: true,
  customStyleMap: {},
  hideToolbarOnBlur: false,
};

export default EDSRichTextInput;
