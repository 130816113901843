import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const registerUser = (params) => {
    return api.post(
        `${ApiEndpointConstants.REGISTER}`,
        params,
    );
}

export const loginUser = (params) => {
    return api.post(
        `${ApiEndpointConstants.LOGIN}`,
        params,
    );
}

export const logout = (params) => {
    return api.post(
        `${ApiEndpointConstants.LOGOUT}`,
    );
}

// export const getUserInfoById = (id) => {
//     return api.post(
//         `${ApiEndpointConstants.USER}/getOtherInfo`, {
//         params: {
//             id: id
//         },
//     });
// }

// export const verifyAccount = (account) => {
//     return api.post(`${ApiEndpointConstants.USER}/verifyAccount`, { account });
// }

export const loginUserWithAccessToken = () => {
    return api.post(ApiEndpointConstants.USERINFO);
}

// export const  fetInvitedMember = (params) => {
//     return api.post(
//         `${ApiEndpointConstants.MEMBER}`,
//         params,
//     );
// }

// export const sendEmailVerificationCode = (email) => {
//     return api.post(
//         `${ApiEndpointConstants.EMAIL_VERIFICATION}`,
//         {email, event: 'register'},
//     );
// }

// export const sendPhoneVerificationCode = (mobile) => {
//     return api.post(
//         `${ApiEndpointConstants.PHONE_VERIFICATION}`,
//         {mobile, event: 'register'},
//     );
// }

// export const updateUserProfile = (avatar) => {
//     return api.post(
//         `${ApiEndpointConstants.USER_PROFILE}`,
//         {avatar},
//     );
// }