import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';
import * as UserAPI from "../apis/UserAPI";
import I18N from '../i18n';
import ActionConstants from '../constants/actions/ActionConstants';


export const updateCurrentUser = (data) => ({
    type: ActionConstants.UPDATE_CURRENT_USER,
    payload: data,
});

export const logoutAction = () => ({
    type: ActionConstants.LOGOUT,
    payload: null,
});

export const loginSuccessAction = (userinfo) => ({
    type: ActionConstants.LOGIN_SUCCESS,
    payload: userinfo,
});

export const clearCurrentUserAction = () => ({
    type: ActionConstants.CLEAR_CURRENT_USER,
    payload: null,
});


export const registerUser = (params, showTip = true) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            return UserAPI.registerUser(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        showTip && dispatch(showInfoGlobalAlert('registered_successfully_tip'));
                        return resolve(res.data);
                    }
                    return reject(res.data.msg);
                })
                .catch(err => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                        return reject(err);
                    }
                    return reject(err);
                });
        })
    }
}

export const loginUser = (account, password) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.loginUser({ account, password })
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        let userInfo = res.data.data.userinfo;
                        dispatch(loginSuccessAction(userInfo));
                        dispatch(updateCurrentUser(userInfo));
                        return resolve('success');
                    }
                    return reject(res.data.msg);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                        return reject(err.response.data.msg);
                    }
                    return reject(I18N.getText('user-login-error'));
                });
        });
    };
}

export const signInUserWithToken = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            return UserAPI.loginUserWithAccessToken()
                .then((res) => {
                    if (res.status === 200) {
                        let userInfo = res.data.data.userinfo;
                        dispatch(loginSuccessAction(userInfo));
                        dispatch(updateCurrentUser(userInfo));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        dispatch(showErrorGlobalAlert(err.response.data.msg));
                    } else {
                        dispatch(showErrorGlobalAlert('user-login-error'));
                    }
                    return reject(err);
                })
        })

    };
};


export const logout = () => {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            return (
                UserAPI.logout()
                    .then(res => {
                        dispatch(logoutAction());
                        dispatch(clearCurrentUserAction());
                        return resolve('success');
                    })
                    .catch(err => {
                        dispatch(logoutAction());
                        dispatch(clearCurrentUserAction());
                        if (err && err.response && err.response.data) {
                            dispatch(showErrorGlobalAlert(err.response.data.msg));
                        } else {
                            dispatch(showErrorGlobalAlert('user-logout-error'));
                        }
                        return reject(err);
                    })
            );
        })
    }
}