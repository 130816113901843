

import React from 'react';
import Colors from '../../constants/ColorConstants';

const DinersClubIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={0.7 * size} viewBox="0 0 115 80" fill="none" {...props}>
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" fill="url(#paint0_linear)" />
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" stroke="#E8E8E8" />
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" stroke="#EDEDED" />
        <path d="M22.4233 51.6549C22.4233 50.279 21.6968 50.3696 21.0001 50.3547V49.957C21.6038 49.9865 22.2228 49.9865 22.8278 49.9865C23.4783 49.9865 24.3615 49.957 25.5082 49.957C29.5189 49.957 31.7032 52.6025 31.7032 55.3115C31.7032 56.8264 30.8055 60.6348 25.3218 60.6348C24.5321 60.6348 23.8033 60.6048 23.0756 60.6048C22.3789 60.6048 21.6968 60.6198 21 60.6348V60.2378C21.9288 60.1454 22.3789 60.1148 22.4233 59.0743V51.6549H22.4233ZM23.9418 58.8302C23.9418 60.0081 24.7946 60.1454 25.5533 60.1454C28.9 60.1454 29.9989 57.6517 29.9989 55.3721C29.9989 52.5125 28.1396 50.4475 25.1506 50.4475C24.5147 50.4475 24.2224 50.4925 23.9419 50.5088L23.9418 58.8302Z" fill="#211E1F" />
        <path d="M32.2467 60.2371H32.5401C32.9732 60.2371 33.2836 60.2371 33.2836 59.7309V55.5861C33.2836 54.9138 33.0515 54.8202 32.4782 54.5161V54.2708C33.2059 54.055 34.0745 53.7664 34.1352 53.7202C34.2445 53.6591 34.3369 53.6429 34.4146 53.6429C34.4912 53.6429 34.5233 53.7352 34.5233 53.8582V59.7309C34.5233 60.2371 34.8641 60.2371 35.2978 60.2371H35.5608V60.6347C35.0335 60.6347 34.4912 60.6042 33.9348 60.6042C33.3772 60.6042 32.819 60.6192 32.2468 60.6347V60.2371H32.2467ZM33.9037 51.2876C33.5005 51.2876 33.1451 50.9206 33.1451 50.5229C33.1451 50.1397 33.5169 49.7871 33.9037 49.7871C34.3053 49.7871 34.663 50.1098 34.663 50.5229C34.663 50.9362 34.3211 51.2876 33.9037 51.2876Z" fill="#211E1F" />
        <path d="M37.0325 55.6789C37.0325 55.1133 36.8612 54.9609 36.1347 54.6701V54.3798C36.8005 54.1657 37.4347 53.9666 38.1786 53.6446C38.2249 53.6446 38.2704 53.6758 38.2704 53.7975V54.7924C39.1548 54.1657 39.914 53.6445 40.9532 53.6445C42.2683 53.6445 42.7329 54.5933 42.7329 55.7862V59.7326C42.7329 60.2387 43.0737 60.2387 43.5079 60.2387H43.7868V60.6363C43.2432 60.6363 42.7014 60.6058 42.145 60.6058C41.5867 60.6058 41.0285 60.6208 40.4715 60.6363V60.2387H40.7497C41.1845 60.2387 41.4927 60.2387 41.4927 59.7326V55.7717C41.4927 54.8986 40.9532 54.4709 40.0688 54.4709C39.5738 54.4709 38.7835 54.8686 38.2703 55.2056V59.7324C38.2703 60.2386 38.6128 60.2386 39.0472 60.2386H39.3254V60.6363C38.7835 60.6363 38.2405 60.6057 37.683 60.6057C37.1259 60.6057 36.5672 60.6207 36.0101 60.6363V60.2386H36.2895C36.7232 60.2386 37.0324 60.2386 37.0324 59.7324V55.6789H37.0325Z" fill="#211E1F" />
        <path d="M44.9645 56.4141C44.9323 56.5521 44.9323 56.7812 44.9645 57.3017C45.0558 58.7543 46.0032 59.9473 47.2405 59.9473C48.0932 59.9473 48.7607 59.4885 49.333 58.9229L49.5492 59.1376C48.8362 60.0702 47.9535 60.8654 46.684 60.8654C44.2204 60.8654 43.7247 58.5091 43.7247 57.5309C43.7247 54.5321 45.7693 53.6445 46.8529 53.6445C48.1084 53.6445 49.4568 54.4243 49.4715 56.0459C49.4715 56.1388 49.4715 56.2294 49.4568 56.3224L49.3166 56.4141H44.9645ZM47.707 55.9242C48.0932 55.9242 48.139 55.7251 48.139 55.5422C48.139 54.7618 47.659 54.1345 46.7911 54.1345C45.8471 54.1345 45.1965 54.8219 45.0107 55.9242H47.707Z" fill="#211E1F" />
        <path d="M49.828 60.2387H50.2465C50.6789 60.2387 50.9887 60.2387 50.9887 59.7326V55.4348C50.9887 54.9609 50.4166 54.8686 50.1834 54.7463V54.5177C51.3143 54.0427 51.9344 53.6445 52.0753 53.6445C52.1671 53.6445 52.2126 53.6907 52.2126 53.8442V55.2206H52.2454C52.6317 54.6245 53.2835 53.6445 54.2274 53.6445C54.6149 53.6445 55.11 53.9042 55.11 54.4554C55.11 54.8686 54.8166 55.2368 54.383 55.2368C53.9008 55.2368 53.9008 54.8686 53.3595 54.8686C53.0959 54.8686 52.2291 55.2206 52.2291 56.1388V59.7326C52.2291 60.2387 52.5383 60.2387 52.9725 60.2387H53.8393V60.6364C52.9872 60.6207 52.339 60.6058 51.6715 60.6058C51.0368 60.6058 50.3856 60.6208 49.8281 60.6364V60.2387H49.828Z" fill="#211E1F" />
        <path d="M55.792 58.509C55.9937 59.519 56.6127 60.376 57.7442 60.376C58.656 60.376 58.9973 59.8255 58.9973 59.2899C58.9973 57.4847 55.6213 58.0658 55.6213 55.6033C55.6213 54.7462 56.3191 53.6445 58.0224 53.6445C58.5175 53.6445 59.1831 53.783 59.7869 54.0895L59.8968 55.6483H59.5397C59.3854 54.6857 58.8436 54.1346 57.8511 54.1346C57.2315 54.1346 56.6436 54.4867 56.6436 55.1439C56.6436 56.9353 60.237 56.383 60.237 58.7844C60.237 59.7938 59.4157 60.8655 57.5723 60.8655C56.9527 60.8655 56.225 60.6514 55.6832 60.3461L55.5126 58.5865L55.792 58.509Z" fill="#211E1F" />
        <path d="M74.2245 52.7258H73.8381C73.5435 50.9368 72.2577 50.2171 70.5235 50.2171C68.7408 50.2171 66.1539 51.3944 66.1539 55.0655C66.1539 58.1577 68.386 60.3749 70.7707 60.3749C72.3038 60.3749 73.5751 59.3349 73.8848 57.7283L74.2402 57.8195L73.8848 60.0529C73.2342 60.4517 71.4838 60.8644 70.4603 60.8644C66.8365 60.8644 64.5447 58.5542 64.5447 55.1122C64.5447 51.9762 67.3788 49.7266 70.4147 49.7266C71.669 49.7266 72.8772 50.1259 74.0701 50.5391L74.2245 52.7258Z" fill="#211E1F" />
        <path d="M74.7833 60.2381H75.0767C75.511 60.2381 75.822 60.2381 75.822 59.7319V51.2125C75.822 50.2176 75.5893 50.1869 75.0001 50.019V49.7743C75.6197 49.5758 76.2702 49.2999 76.5958 49.1158C76.7647 49.0247 76.8897 48.9473 76.9353 48.9473C77.0301 48.9473 77.061 49.0397 77.061 49.1625V59.7319C77.061 60.2381 77.4012 60.2381 77.8354 60.2381H78.0968V60.6357C77.5724 60.6357 77.0301 60.6051 76.4718 60.6051C75.9149 60.6051 75.3572 60.6202 74.7832 60.6357V60.2381H74.7833Z" fill="#211E1F" />
        <path d="M84.7293 59.7921C84.7293 60.0686 84.8988 60.0836 85.1618 60.0836C85.3482 60.0836 85.5797 60.0686 85.7819 60.0686V60.3906C85.1151 60.4512 83.845 60.7727 83.5503 60.8639L83.4726 60.8176V59.5798C82.5445 60.3288 81.8308 60.8639 80.7302 60.8639C79.8938 60.8639 79.0265 60.3288 79.0265 59.0447V55.1272C79.0265 54.7296 78.9651 54.3469 78.0989 54.2708V53.9799C78.6571 53.9649 79.8939 53.8731 80.0961 53.8731C80.268 53.8731 80.268 53.9799 80.268 54.3164V58.2621C80.268 58.7221 80.268 60.0373 81.6146 60.0373C82.1412 60.0373 82.8379 59.6397 83.4879 59.1058V54.9887C83.4879 54.684 82.745 54.516 82.1886 54.3624V54.0872C83.5808 53.9948 84.4499 53.873 84.6037 53.873C84.7292 53.873 84.7292 53.9798 84.7292 54.1489V59.7921H84.7293Z" fill="#211E1F" />
        <path d="M87.8116 54.7456C88.4305 54.2256 89.2668 53.6439 90.1196 53.6439C91.9169 53.6439 93 55.1906 93 56.8567C93 58.8605 91.5136 60.8648 89.2972 60.8648C88.1516 60.8648 87.5478 60.4971 87.1445 60.3297L86.6805 60.6806L86.3561 60.5133C86.4935 59.6107 86.5723 58.7231 86.5723 57.7905V51.2125C86.5723 50.2176 86.3385 50.1869 85.7505 50.019V49.7743C86.3719 49.5758 87.0206 49.2999 87.3462 49.1158C87.5169 49.0246 87.6401 48.9473 87.6881 48.9473C87.7805 48.9473 87.8114 49.0397 87.8114 49.1625V54.7456H87.8116V54.7456ZM87.8116 58.9061C87.8116 59.4878 88.3685 60.4678 89.4066 60.4678C91.0641 60.4678 91.7608 58.8605 91.7608 57.4985C91.7608 55.8468 90.492 54.4704 89.2832 54.4704C88.7087 54.4704 88.2294 54.838 87.8115 55.1906V58.9061H87.8116Z" fill="#211E1F" />
        <path d="M22.4233 51.6549C22.4233 50.279 21.6968 50.3696 21.0001 50.3547V49.957C21.6038 49.9865 22.2228 49.9865 22.8278 49.9865C23.4783 49.9865 24.3615 49.957 25.5082 49.957C29.5189 49.957 31.7032 52.6025 31.7032 55.3115C31.7032 56.8264 30.8055 60.6348 25.3218 60.6348C24.5321 60.6348 23.8033 60.6048 23.0756 60.6048C22.3789 60.6048 21.6968 60.6198 21 60.6348V60.2378C21.9288 60.1454 22.3789 60.1148 22.4233 59.0743V51.6549H22.4233ZM23.9418 58.8302C23.9418 60.0081 24.7946 60.1454 25.5533 60.1454C28.9 60.1454 29.9989 57.6517 29.9989 55.3721C29.9989 52.5125 28.1396 50.4475 25.1506 50.4475C24.5147 50.4475 24.2224 50.4925 23.9419 50.5088L23.9418 58.8302Z" fill="#211E1F" />
        <path d="M32.2467 60.2371H32.5401C32.9732 60.2371 33.2836 60.2371 33.2836 59.7309V55.5861C33.2836 54.9138 33.0515 54.8202 32.4782 54.5161V54.2708C33.2059 54.055 34.0745 53.7664 34.1352 53.7202C34.2445 53.6591 34.3369 53.6429 34.4146 53.6429C34.4912 53.6429 34.5233 53.7352 34.5233 53.8582V59.7309C34.5233 60.2371 34.8641 60.2371 35.2978 60.2371H35.5608V60.6347C35.0335 60.6347 34.4912 60.6042 33.9348 60.6042C33.3772 60.6042 32.819 60.6192 32.2468 60.6347V60.2371H32.2467ZM33.9037 51.2876C33.5005 51.2876 33.1451 50.9206 33.1451 50.5229C33.1451 50.1397 33.5169 49.7871 33.9037 49.7871C34.3053 49.7871 34.663 50.1098 34.663 50.5229C34.663 50.9362 34.3211 51.2876 33.9037 51.2876Z" fill="#211E1F" />
        <path d="M37.0325 55.6789C37.0325 55.1133 36.8612 54.9609 36.1347 54.6701V54.3798C36.8005 54.1657 37.4347 53.9666 38.1786 53.6446C38.2249 53.6446 38.2704 53.6758 38.2704 53.7975V54.7924C39.1548 54.1657 39.914 53.6445 40.9532 53.6445C42.2683 53.6445 42.7329 54.5933 42.7329 55.7862V59.7326C42.7329 60.2387 43.0737 60.2387 43.5079 60.2387H43.7868V60.6363C43.2432 60.6363 42.7014 60.6058 42.145 60.6058C41.5867 60.6058 41.0285 60.6208 40.4715 60.6363V60.2387H40.7497C41.1845 60.2387 41.4927 60.2387 41.4927 59.7326V55.7717C41.4927 54.8986 40.9532 54.4709 40.0688 54.4709C39.5738 54.4709 38.7835 54.8686 38.2703 55.2056V59.7324C38.2703 60.2386 38.6128 60.2386 39.0472 60.2386H39.3254V60.6363C38.7835 60.6363 38.2405 60.6057 37.683 60.6057C37.1259 60.6057 36.5672 60.6207 36.0101 60.6363V60.2386H36.2895C36.7232 60.2386 37.0324 60.2386 37.0324 59.7324V55.6789H37.0325Z" fill="#211E1F" />
        <path d="M44.9645 56.4141C44.9323 56.5521 44.9323 56.7812 44.9645 57.3017C45.0558 58.7543 46.0032 59.9473 47.2405 59.9473C48.0932 59.9473 48.7607 59.4885 49.333 58.9229L49.5492 59.1376C48.8362 60.0702 47.9535 60.8654 46.684 60.8654C44.2204 60.8654 43.7247 58.5091 43.7247 57.5309C43.7247 54.5321 45.7693 53.6445 46.8529 53.6445C48.1084 53.6445 49.4568 54.4243 49.4715 56.0459C49.4715 56.1388 49.4715 56.2294 49.4568 56.3224L49.3166 56.4141H44.9645ZM47.707 55.9242C48.0932 55.9242 48.139 55.7251 48.139 55.5422C48.139 54.7618 47.659 54.1345 46.7911 54.1345C45.8471 54.1345 45.1965 54.8219 45.0107 55.9242H47.707Z" fill="#211E1F" />
        <path d="M49.828 60.2387H50.2465C50.6789 60.2387 50.9887 60.2387 50.9887 59.7326V55.4348C50.9887 54.9609 50.4166 54.8686 50.1834 54.7463V54.5177C51.3143 54.0427 51.9344 53.6445 52.0753 53.6445C52.1671 53.6445 52.2126 53.6907 52.2126 53.8442V55.2206H52.2454C52.6317 54.6245 53.2835 53.6445 54.2274 53.6445C54.6149 53.6445 55.11 53.9042 55.11 54.4554C55.11 54.8686 54.8166 55.2368 54.383 55.2368C53.9008 55.2368 53.9008 54.8686 53.3595 54.8686C53.0959 54.8686 52.2291 55.2206 52.2291 56.1388V59.7326C52.2291 60.2387 52.5383 60.2387 52.9725 60.2387H53.8393V60.6364C52.9872 60.6207 52.339 60.6058 51.6715 60.6058C51.0368 60.6058 50.3856 60.6208 49.8281 60.6364V60.2387H49.828Z" fill="#211E1F" />
        <path d="M55.792 58.509C55.9937 59.519 56.6127 60.376 57.7442 60.376C58.656 60.376 58.9973 59.8255 58.9973 59.2899C58.9973 57.4847 55.6213 58.0658 55.6213 55.6033C55.6213 54.7462 56.3191 53.6445 58.0224 53.6445C58.5175 53.6445 59.1831 53.783 59.7869 54.0895L59.8968 55.6483H59.5397C59.3854 54.6857 58.8436 54.1346 57.8511 54.1346C57.2315 54.1346 56.6436 54.4867 56.6436 55.1439C56.6436 56.9353 60.237 56.383 60.237 58.7844C60.237 59.7938 59.4157 60.8655 57.5723 60.8655C56.9527 60.8655 56.225 60.6514 55.6832 60.3461L55.5126 58.5865L55.792 58.509Z" fill="#211E1F" />
        <path d="M74.2245 52.7258H73.8381C73.5435 50.9368 72.2577 50.2171 70.5235 50.2171C68.7408 50.2171 66.1539 51.3944 66.1539 55.0655C66.1539 58.1577 68.386 60.3749 70.7707 60.3749C72.3038 60.3749 73.5751 59.3349 73.8848 57.7283L74.2402 57.8195L73.8848 60.0529C73.2342 60.4517 71.4838 60.8644 70.4603 60.8644C66.8365 60.8644 64.5447 58.5542 64.5447 55.1122C64.5447 51.9762 67.3788 49.7266 70.4147 49.7266C71.669 49.7266 72.8772 50.1259 74.0701 50.5391L74.2245 52.7258Z" fill="#211E1F" />
        <path d="M74.7833 60.2381H75.0767C75.511 60.2381 75.822 60.2381 75.822 59.7319V51.2125C75.822 50.2176 75.5893 50.1869 75.0001 50.019V49.7743C75.6197 49.5758 76.2702 49.2999 76.5958 49.1158C76.7647 49.0247 76.8897 48.9473 76.9353 48.9473C77.0301 48.9473 77.061 49.0397 77.061 49.1625V59.7319C77.061 60.2381 77.4012 60.2381 77.8354 60.2381H78.0968V60.6357C77.5724 60.6357 77.0301 60.6051 76.4718 60.6051C75.9149 60.6051 75.3572 60.6202 74.7832 60.6357V60.2381H74.7833Z" fill="#211E1F" />
        <path d="M84.7293 59.7921C84.7293 60.0686 84.8988 60.0836 85.1618 60.0836C85.3482 60.0836 85.5797 60.0686 85.7819 60.0686V60.3906C85.1151 60.4512 83.845 60.7727 83.5503 60.8639L83.4726 60.8176V59.5798C82.5445 60.3288 81.8308 60.8639 80.7302 60.8639C79.8938 60.8639 79.0265 60.3288 79.0265 59.0447V55.1272C79.0265 54.7296 78.9651 54.3469 78.0989 54.2708V53.9799C78.6571 53.9649 79.8939 53.8731 80.0961 53.8731C80.268 53.8731 80.268 53.9799 80.268 54.3164V58.2621C80.268 58.7221 80.268 60.0373 81.6146 60.0373C82.1412 60.0373 82.8379 59.6397 83.4879 59.1058V54.9887C83.4879 54.684 82.745 54.516 82.1886 54.3624V54.0872C83.5808 53.9948 84.4499 53.873 84.6037 53.873C84.7292 53.873 84.7292 53.9798 84.7292 54.1489V59.7921H84.7293Z" fill="#211E1F" />
        <path d="M87.8116 54.7456C88.4305 54.2256 89.2668 53.6439 90.1196 53.6439C91.9169 53.6439 93 55.1906 93 56.8567C93 58.8605 91.5136 60.8648 89.2972 60.8648C88.1516 60.8648 87.5478 60.4971 87.1445 60.3297L86.6805 60.6806L86.3561 60.5133C86.4935 59.6107 86.5723 58.7231 86.5723 57.7905V51.2125C86.5723 50.2176 86.3385 50.1869 85.7505 50.019V49.7743C86.3719 49.5758 87.0206 49.2999 87.3462 49.1158C87.5169 49.0246 87.6401 48.9473 87.6881 48.9473C87.7805 48.9473 87.8114 49.0397 87.8114 49.1625V54.7456H87.8116V54.7456ZM87.8116 58.9061C87.8116 59.4878 88.3685 60.4678 89.4066 60.4678C91.0641 60.4678 91.7608 58.8605 91.7608 57.4985C91.7608 55.8468 90.492 54.4704 89.2832 54.4704C88.7087 54.4704 88.2294 54.838 87.8115 55.1906V58.9061H87.8116Z" fill="#211E1F" />
        <path d="M60.8817 46.3261C69.8297 46.3684 77.9968 39.1207 77.9968 30.3035C77.9968 20.6615 69.8297 13.9968 60.8817 14H53.1809C44.1258 13.9968 36.6725 20.6635 36.6725 30.3035C36.6725 39.1226 44.1258 46.3685 53.1809 46.3261H60.8817Z" fill="#0079BE" />
        <path d="M53.2175 15.3359C44.9431 15.3385 38.2382 21.9609 38.2362 30.1342C38.2382 38.3061 44.9431 44.9278 53.2175 44.9304C61.4938 44.9278 68.2001 38.3061 68.2013 30.1342C68.2001 21.9609 61.4938 15.3385 53.2175 15.3359ZM43.7213 30.1342C43.7291 26.1402 46.2553 22.7345 49.8176 21.3811V38.8853C46.2553 37.5326 43.729 34.1287 43.7213 30.1342ZM56.6161 38.8891V21.3805C60.1797 22.7306 62.7098 26.1383 62.7163 30.1342C62.7098 34.1313 60.1797 37.5364 56.6161 38.8891Z" fill="white" />
        <path d="M60.8817 46.3261C69.8297 46.3684 77.9968 39.1207 77.9968 30.3035C77.9968 20.6615 69.8297 13.9968 60.8817 14H53.1809C44.1258 13.9968 36.6725 20.6635 36.6725 30.3035C36.6725 39.1226 44.1258 46.3685 53.1809 46.3261H60.8817Z" fill="#0079BE" />
        <path d="M53.2175 15.3359C44.9431 15.3385 38.2382 21.9609 38.2362 30.1342C38.2382 38.3061 44.9431 44.9278 53.2175 44.9304C61.4938 44.9278 68.2001 38.3061 68.2013 30.1342C68.2001 21.9609 61.4938 15.3385 53.2175 15.3359ZM43.7213 30.1342C43.7291 26.1402 46.2553 22.7345 49.8176 21.3811V38.8853C46.2553 37.5326 43.729 34.1287 43.7213 30.1342ZM56.6161 38.8891V21.3805C60.1797 22.7306 62.7098 26.1383 62.7163 30.1342C62.7098 34.1313 60.1797 37.5364 56.6161 38.8891Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear" x1="57" y1="5" x2="57" y2="80" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F5F5F5" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
        </defs>
    </svg>
);

export default DinersClubIcon