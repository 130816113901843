import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const RDSTextButton = ({
  qaSelector,
  customClassNames,
  innerRef,
  disabled,
  children,
  className,
  onClick,
  ...props
}) => {
  function handleClick(e) {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick && onClick();
  }

  const classes = classNames(
    className,
    "tw-inline-flex tw-items-center tw-justify-center tw-text-sm tw-text-brand-primary tw-outline-none focus:tw-outline-none focus:tw-ring-0",
    {
      "tw-int-disabled": disabled,
    },
    qaSelector,
    customClassNames
  );

  return (
    <button
      {...props}
      {...props.dataSet}
      className={classes}
      ref={innerRef}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

RDSTextButton.propTypes = {
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  children: PropTypes.node,
  qaSelector: PropTypes.string,
  customClassNames: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default RDSTextButton;
