

import React from 'react';
import Colors from '../../constants/ColorConstants';

const EtcIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 50 50" {...props}>
        <g id="Icons-Crypto/Ethereum-Classic" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M25,0 C38.8071187,0 50,11.1928813 50,25 C50,38.8071187 38.8071187,50 25,50 C11.1928813,50 0,38.8071187 0,25 C0,11.1928813 11.1928813,0 25,0 Z M34.6532258,26.3985709 L25.115962,31.5558463 C23.1214364,30.4835531 20.6958118,29.1813537 18.2130902,27.8511315 L18.2130902,27.8511315 L16.8554638,27.12399 C16.4025328,26.8814921 15.9499622,26.6392813 15.5,26.3985709 C18.3559781,30.6368022 21.9036073,35.8791047 25.115962,40.63 L25.115962,40.63 L25.1201218,40.6238029 L25.1729526,40.5451444 L25.1729526,40.5451444 L25.1985108,40.5074206 C28.1190558,36.1703719 30.7719439,32.2060551 33.845767,27.6062762 C34.1270008,27.1858314 34.3973357,26.7815053 34.6532258,26.3985709 L34.6532258,26.3985709 Z M25.1263889,19.9958971 L15.8668035,24.9732398 L25.1316024,29.9246993 L34.2654444,24.9416047 L34.254,24.942 L34.2654444,24.9367282 L25.1263889,19.9958971 Z M25.0740062,9.38 L15.5,23.5005186 L25.115962,18.4012618 L34.6532258,23.5321537 L25.0740062,9.38 Z" id="Combined-Shape" fill="#38B238" />
        </g>
    </svg>
);

export default EtcIcon