
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShoppingCartModal from '../components/common/shoppingCartModal';
import * as tagActionCreator from '../actions/tagActionCreator';
import * as shoppingCartActionCreator from '../actions/shoppingCartActionCreator';
import * as productActionCreator from '../actions/productActionCreator';
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors';
import * as ShoppingCartSelectors from '../selectors/ShoppingCartSelectors';

class CartPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }
    render() {
        return (
            <div className='tw-w-full '>
                <div className='tw-w-[1440px] tw-px-[80px] tw-min-h-[600px] tw-mx-auto phone:tw-w-full phone:tw-px-[15px]'>
                    <ShoppingCartModal fromPage="cart" showModal={true} {...this.props}/>
                </div>

            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
        shoppingCartArr: ShoppingCartSelectors.selectShoppingCartData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActionCreator, dispatch),
        tagActions: bindActionCreators(tagActionCreator, dispatch),
        shoppingCartActions: bindActionCreators(shoppingCartActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
