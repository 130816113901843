import React from "react";
import Colors from '../constants/ColorConstants';

const PlaneIcon = ({
  size = 32,
  viewBox = "0 0 32 32",
  color =  Colors.brand.primary,
  className,
  ...props
}) => (
//   <svg
//     width={size}
//     height={size}
//     viewBox="0 0 1024 1024"
//     p-id="4217"
//     className={className}
//     fill="none"
//     {...props}
//   >
//     <path d="M512 1024C229.230592 1024 0 794.769408 0 512S229.230592 0 512 0s512 229.230592 512 512-229.230592 512-512 512z m-42.667008-554.667008H256c-23.564288 0-42.667008 19.10272-42.667008 42.667008s19.10272 42.667008 42.667008 42.667008h213.332992V768c0 23.564288 19.10272 42.667008 42.667008 42.667008s42.667008-19.10272 42.667008-42.667008V554.667008H768c23.564288 0 42.667008-19.10272 42.667008-42.667008S791.564288 469.332992 768 469.332992H554.667008V256c0-23.564288-19.10272-42.667008-42.667008-42.667008S469.332992 232.435712 469.332992 256v213.332992z" fill={color} p-id="4218"></path>  
//   </svg>
  <svg viewBox="0 0 1024 1024"  p-id="2991"  width={size} height={size} {...props}><path d="M0 1024l106.496-474.112 588.8-36.864-588.8-39.936-106.496-473.088 1024 512z" p-id="2992" fill={color}></path></svg>
);

export default PlaneIcon;
