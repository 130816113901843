import isString from 'lodash/isString';

// === Constants === //
import RegexConstants from '../constants/RegexConstants';
// import WhitelistedDomainConstants from '../constants/WhitelistedEmbedDomainConstants';
// import WhitelistedEmbedAttributeConstants from '../constants/WhitelistedEmbedAttributeConstants';

export const isIframe = str => str && RegexConstants.IFRAME.test(str);
export const isMp4 = str => str && RegexConstants.MP4_URL.test(str);
export const isMov = str => str && RegexConstants.MOV_URL.test(str);
export const isM4v = str => str && RegexConstants.M4V_URL.test(str);
export const isExpires = str => str && RegexConstants.EXPIRES.test(str);
export const isEmail = str => str && RegexConstants.EMAIL.test(str);

export const isHashtag = str => str && RegexConstants.HASHTAG.test(str);

