import React from 'react';
import PropTypes from 'prop-types'
import {capitalize} from 'humanize-plus/';
import Loadable from 'react-loadable';
import XIcon from '../../icons/XIcon';
import camelCase from 'lodash/camelCase';

const SvgIcon = (props) => {
  const iconClassName = capitalize(camelCase(props.name || ''));
  const Icon = Loadable({
    loader  : () => import (`../../icons/${iconClassName}`),
    loading : (props) => (props.error ? <XIcon {...props}/> : null)
  });

  return (
    <Icon {...props}/>
  )
};

SvgIcon.propTypes = {
  name  : PropTypes.string.isRequired,
  color : PropTypes.string,
  size  : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SvgIcon;
