
import React from 'react';
import Colors from '../constants/ColorConstants';

const ArrowDownIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="3966" wiidth={size} height={size} {...props}>
    <path d="M930.666 323.731c0 14.331-5.512 27.677-15.529 37.657l-365.99 365.34c-1.306 1.336-2.417 2.379-3.607 3.234l-2.723 2.16c-10.703 10.653-23.296 15.887-36.627 15.887-13.571 0-26.26-5.351-35.729-15.053l-363.953-367.853c-9.813-9.951-15.222-23.238-15.222-37.401 0-13.849 5.25-26.931 14.769-36.832 9.549-9.841 22.867-15.506 36.518-15.506 13.484 0 26.259 5.365 35.969 15.134l328.283 331.846 336.964-336.081c9.666-9.607 22.296-14.915 35.619-14.915 13.958 0 27.055 5.673 36.876 15.937 9.271 9.768 14.381 22.734 14.381 36.444z" fill={color} p-id="3967"></path>
  </svg>
);

export default ArrowDownIcon;

