import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';
import * as UserAPI from "../apis/UserAPI";
import I18N from '../i18n';
import ActionConstants from '../constants/actions/ActionConstants';


export const addProductToCartAction = (product) => ({
    type: ActionConstants.ADD_PRODUCT_TO_CART,
    payload: product,
});

export const removeProductAction = (index) => ({
    type: ActionConstants.REMOVE_PRODUCT_FROM_CART,
    payload: index,
});

export const updateProductQuantityAction = (quantity, index) => ({
    type: ActionConstants.UPDATE_PRODUCT_QUANTITY,
    payload: {quantity, index},
});


export const addProductToShoppingCart = (product) => {
    return function (dispatch, getState) {
        dispatch(addProductToCartAction(product));
    }
}

export const removeProductFromShoppingCart = (index) => {
    return function (dispatch, getState) {
        dispatch(removeProductAction(index));
    }
}

export const updateProductQuantity = (quantity, index) => {
    return function (dispatch, getState) {
        dispatch(updateProductQuantityAction(quantity, index));
    }
}