import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import * as OrderAPI from "../apis/OrderAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';

export const submitProductOrder = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.submitProductOrder(data)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            dispatch(showInfoGlobalAlert('order_successfully_tip'));
            return resolve(res.data);
          }
          return reject(res.data.msg);
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
            return reject(err);
          }
          return reject(err);
        });
    });
  };
};

export const queryOrderData = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.queryProductOrder(data)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            // dispatch(showInfoGlobalAlert('order_successfully_tip'));
            return resolve(res.data);
          }
          return reject(res.data.msg);
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
            return reject(err);
          }
          return reject(err);
        });
    });
  };
};

export const queryOrderDetail = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      OrderAPI.queryProductDetail(data)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            // dispatch(showInfoGlobalAlert('order_successfully_tip'));
            return resolve(res.data);
          }
          return reject(res.data.msg);
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
            return reject(err);
          }
          return reject(err);
        });
    });
  };
};