// import UrlUtil from '../../../../utils/UrlUtil';
// import * as RegexUtil from '../../../../utils/RegexUtil';
const LinkifyIt = require('linkify-it')();

class EDSRichTextRendererUtil {

  static isDraftData(text) {
    try {
      return text?.includes('{"blocks":[') && JSON.parse(text)
    } catch (error) {
      return false
    }
  }

  static hasLatexCode(text) {
    return text && text.includes('[math]') && text.includes('[/math]');
  }

  static preprocessLatexCode(text) {
    // [math][/math] should be replaced with empty string '' (instead of $$)
    return text && (
      text.replace(/\[math\]\[\/math\]/gi, '').replace(/\[math\]/gi, '$').replace(/\[\/math\]/gi, '$')
    );
  }

  static isMailToLink = href => (
    href.match(/mailto:/)
  );

  static isMentionLink = href => (
    href.match(/\/profile\/*/)
  );

  static addLineBreak(text) {
    let strArray = (text && text.split(/(```.*?```)/sg)) || [] // Line breaks in Code Blocks in Markdown do not use <br/> instead
    strArray = strArray.map(elem => {
      if (!/^```.*```$/s.test(elem)) {
        let noCodeStr = elem
        if (noCodeStr && noCodeStr.match(/(?:(\r\n\r\n)|\r\r|\n\n)/g)) {
          while (noCodeStr.match(/(?:(\r\n\r\n)|\r\r|\n\n)/g)) {
            if (noCodeStr.match(/(?:(\r\n\r\n))/g)) {
              noCodeStr = noCodeStr.replace(/(?:(\r\n\r\n))/, '\r\n&zwnj;\r\n');
            } else if (noCodeStr.match(/(?:\r\r)/g)) {
              noCodeStr = noCodeStr.replace(/(?:\r\r)/, '\r&zwnj;\r');
            } else {
              noCodeStr = noCodeStr.replace(/(?:\n\n)/, '\n&zwnj;\n');
            }
          }
        }
        return noCodeStr
      }
      return elem
    })
    return strArray.join('');
  }

  static parseLinks(text, mentions, options, isStudent ) {
    let returnText = text;


    // auto linking of www. and other fuzzy links
    // we need to first filter out links already in markdown syntax
    // CTN-701
    // eslint-disable-next-line no-useless-escape
    const filterRegex = new RegExp(/\[([^\[]+)\](\(.*\))/gm);
    const markdownLinkSanitizedText = returnText.replace(filterRegex, (markdownLink) => {
      // we use start and end index to replace the raw link with markdown formatted link,
      // thus we need to preserve the proper length of the text to re-insert updated links
      return '-'.repeat(markdownLink.length);
    });

    LinkifyIt.add('ftp:', null);
    LinkifyIt.add('//', null);
    const links = LinkifyIt.match(markdownLinkSanitizedText);

    links && links.reverse().forEach(link => {
      if (link.url.substring(link.url.length - 2) === '**' &&
          returnText.substring(link.index - 2, link.index) === '**') {
        const url = link.url.replace(/(\*\*)/g, '');
        returnText = returnText.substring(0, link.index) + url + '**' + returnText.substring(link.lastIndex);
      } else {
        returnText = returnText.substring(0, link.index) + `[${link.text}](${link.url})` + returnText.substring(link.lastIndex);
      }
    });

    // add proper markdown link for mentions. requires mentions object to be passed along to renderer to build profile URLS
    // ex: @[John Doe] becomes [John Doe](/profile/9656787654567)
    if (options.enableMentions && mentions) {
      const mentionRegex = new RegExp(/@\[([^[\]@]*)\]/gm);
      returnText = returnText.replace(mentionRegex, (mention) => {
        const userNameKey = mention.replace(/\[|\]|@/g, '');
        const userInfo = mentions.filter(mention => mention.key === userNameKey)[0];

        if (userInfo) {
          // const url = UrlUtil.makeReactProfileUrl(userInfo.user_id);
          // return `[${userNameKey}](${url})`;
        }
        return mention;
      });
    }

    if (options.enableHashtags && !isStudent) {
      const hashtagRegex = new RegExp(/(^|\s)#[0-9a-zA-Z\u0600-\u06FF\u4e00-\u9fa5]+/g); // support Number, English, Arabic, Chinese
      returnText = returnText.replace(hashtagRegex, (hashtag) => {

        const searchQuery = hashtag.split('#')[1];
        if (!searchQuery) {
          return hashtag;
        }
        let url = '';
        if (options.hashtagLinkUrl && options.hashtagLinkUrl.includes('%{hashtag}')) {
          url = options.hashtagLinkUrl.replace('%{hashtag}', searchQuery);
        } else {
          // url = UrlUtil.makeHomeHashtagUrl(searchQuery);
        }

        // if (!RegexUtil.numberOnlyHashtag(hashtag)) {
        //   return `[${hashtag.toLowerCase()}](${url})`
        // } else {
        //   return `${hashtag}`;
        // }
      });
    }
    return returnText;
  }

}

export default EDSRichTextRendererUtil;
