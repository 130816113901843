import React, { Component } from 'react';
import PlaneIcon from '../../../icons/PlaneIcon';
import DialogAvatar1 from '../../../images/dialog/1.jpeg';
import PalmImg from '../../../images/dialog/palm.png';
import ArrowDownIcon from '../../../icons/ArrowDown2Icon';
import EllipsisGif from '../../../images/dialog/ellipsis.gif';
import SearchIcon from '../../../icons/SearchIcon';
import EmailImg from '../../../images/dialog/email.png';

import ShieldIcon from '../../../icons/ShieldIcon';
import trustpilotImg from '../../../images/certifiedWindow/trustpoilt.png';
import creditcardImg from '../../../images/certifiedWindow/creditcard.jpg';
import * as FBUtil from '../../../utils/FbUtil';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class CustomerFloatWindow extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showCustomerWindow: false,
            conversationArr: [],
        }

        this.toggleCustomerWindow = this.toggleCustomerWindow.bind(this);
        this.handleSendDialogue = this.handleSendDialogue.bind(this);
    }

    toggleCustomerWindow() {
        this.setState({ showCustomerWindow: !this.state.showCustomerWindow, showCertifiedWindow: false });
    }

    handleSendDialogue() {
        let { conversationArr } = this.state;
        let { baseConfig } = this.props;
        conversationArr.push({ role: 'user', type: 1 }, { role: 'system', text: 'Loading' });
        this.setState({ conversationArr }, () => {
            setTimeout(() => {
                conversationArr[conversationArr.length - 1] = { role: 'system', text: `Our customer service email address is <a class="tw-text-[#007dfc]">${baseConfig.partnerEmail}</a>, if you have any questions, please feel free to email us` }
                this.setState({ conversationArr });
            }, 5000);
        });
        FBUtil.trackFBEvent('Contact', 'The customer clicked on Contact Support');
    }

    render() {
        const { showCustomerWindow, conversationArr, showCertifiedWindow } = this.state;

        return (<React.Fragment>
            <div className='tw-fixed tw-bottom-[80px] tw-right-[20px] tw-bg-white tw-w-[60px] tw-h-[60px] tw-z-[100001] tw-rounded-full tw-overflow-hidden tw-flex tw-justify-center tw-items-center' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} onClick={this.toggleCustomerWindow}><PlaneIcon size="28" color="#007dfc" className="tw-ml-[5px]" /></div>
            {showCustomerWindow && <div className='tw-fixed tw-bottom-[70px] tw-right-[30px] tw-w-[370px] tw-h-[580px] tw-z-[10000] tw-bg-white tw-rounded-[30px] tw-overflow-hidden ' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                <div className='tw-w-full tw-h-[150px]  tw-py-[24px] ' style={{ background: 'linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%)' }}>
                    <div className='tw-w-full tw-h-[52px] tw-flex tw-items-center tw-justify-between tw-px-[28px]'>
                        <div className='tw-h-[52px] tw-flex tw-items-center'>
                            <div className='tw-w-[52px] tw-h2px] tw-relative'>
                                <img src={DialogAvatar1} alt='avatar1' className='tw-w-[50px] tw-h-[50px] tw-rounded-full tw-absolute tw-left-0 tw-top-0' />
                            </div>
                            <div className='tw-ml-[20px] tw-text-[24px] tw-text-white tw-flex tw-items-center'>Hi there! <img src={PalmImg} className='tw-ml-[5px] tw-w-[31px] tw-h-[31px]' alt='palm' /> </div>
                        </div>
                        <ArrowDownIcon color='white' size="26" onClick={this.toggleCustomerWindow} />
                    </div>
                    <div className='tw-mt-[18px] tw-px-[28px] hs-dialog-online-status tw-relative tw-w-full tw-pt-[14px] tw-pb-[20px] tw-flex tw-items-center tw-text-[18px] tw-text-white tw-font-[300]' style={{ backgroundImage: 'linear-gradient(135deg, rgba(42, 39, 218, 0.72) 0%, rgba(0, 204, 255, 0.72) 100%)' }}>
                        <div className='tw-w-[8px] tw-h-[8px] tw-rounded-full tw-overflow-hidden tw-bg-[#cfd1d5] tw-mr-[10px]'></div>
                        <span>We are offline</span>
                    </div>
                </div>
                <div className='tw-w-full tw-px-[28px] tw-pt-[10px] tw-mt-[10px] tw-h-[calc(100%-150px)] tw-overflow-auto'>
                    {/* tw-border tw-border-[#cfd1d5] tw-border-[0.5px] */}
                    <div className='tw-w-[284px] tw-rounded-t-[20px] tw-overflow-hidden'>
                        <div className='tw-px-[16px] tw-py-[9px] tw-text-[15px] tw-flex tw-items-center tw-flex-wrap tw-break-all tw-bg-[#f0f2f7]'>
                            Hi! What brings you here today? We'll be happy to assist you!
                        </div>
                        <div className='tw-bg-white tw-py-[8px] tw-px-[16px] tw-flex tw-items-center tw-justify-center tw-text-[#007dfc] tw-border-b  tw-border-[0.5px]' onClick={this.handleViewOrderPage}>
                            <SearchIcon size={20} color='#2a27da' className="tw-mr-[5px]" /> Track the order
                        </div>
                        <div className='tw-rounded-b-[20px] tw-bg-white tw-py-[8px] tw-px-[16px] tw-flex tw-items-center tw-justify-center tw-text-[#007dfc] tw-border-b  tw-border-[0.5px]' onClick={this.handleSendDialogue}>
                            <img src={EmailImg} alt='email-img' className='tw-h-[22px] tw-mr-[5px]' /> Contact Support
                        </div>
                    </div>

                    <div className='tw-mt-[20px]'>{conversationArr.map(item => {
                        const { role } = item;
                        if (role === 'user') {
                            return (<div className='tw-w-full tw-flex tw-justify-end tw-mb-[15px] tw-text-[14px]'>
                                <div className='tw-max-w-[200px] tw-h-auto tw-px-[16px] tw-py-[10px] tw-rounded-[20px] tw-flex tw-items-center tw-justify-center tw-text-white' style={{ background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))' }}>{
                                    item.type === 1 ? <React.Fragment><img src={EmailImg} alt='email-img' className='tw-h-[22px] tw-mr-[5px]' /> Contact Support</React.Fragment> : ''
                                }</div>
                            </div>)
                        } else {
                            return (<div className='tw-w-full tw-text-left tw-mb-[15px] tw-text-[14px]'>
                                <div className='tw-px-[16px] tw-py-[10px] tw-rounded-[20px] tw-w-auto tw-max-w-[260px] tw-rounded-[20px] tw-bg-[#f0f2f7] tw-break-all'>
                                    {item.text === 'Loading' ? <div className='tw-w-[60px] tw-h-[20px]' style={{ backgroundImage: `url(${EllipsisGif})`, backgroundSize: 'contain', backgroundPosition: 'cener', backgroundRepeat: 'no-repeat' }} /> : <div dangerouslySetInnerHTML={{ __html: item.text }}></div>}
                                </div>
                            </div>)
                        }
                    })}
                    </div>
                </div>
            </div>}

            <div id="popover_certified" className='tw-fixed tw-bottom-[180px] tw-right-[20px] tw-bg-white tw-w-[60px] tw-h-[60px] tw-z-[100001] tw-rounded-full tw-overflow-hidden tw-flex tw-justify-center tw-items-center' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} >
                <ShieldIcon size="28" color="#007dfc" />
            </div>
            <UncontrolledPopover
                placement="left"
                target={`popover_certified`}
                trigger="click">
                <PopoverBody>
                    <div><img src={trustpilotImg} alt='trustpilot-img' className='tw-w-[150px]' /></div>
                    <div className='tw-mt-[0px]'><img src={creditcardImg} alt='creditcard-img' className='tw-h-[50px]' /></div>
                    <div className='tw-mt-[10px] tw-text-[12px] tw-flex tw-items-center'><ShieldIcon size={15} className="tw-flex-shrink-0 tw-mr-[10px]" /> We are committed to protecting the security of your personal data. We will regularly review and update security measures to ensure data security.</div>
                </PopoverBody>
            </UncontrolledPopover>
        </React.Fragment>
        );
    }
}

export default CustomerFloatWindow;