import React from 'react';
import Colors from '../constants/ColorConstants';

const GreenShieldIcon = ({
  size = 24,
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg width={size} height={size} viewBox="0 0 1024 1024" p-id="3661" {...props}>
    <path d="M926.72 579.584V148.992l-45.056-1.024c-1.024 0-60.928-2.048-165.376-41.984-107.008-40.96-176.128-87.552-176.64-88.064L511.488 0l-27.136 18.432c-0.512 0.512-69.632 47.104-176.64 88.064C203.264 146.432 143.36 148.48 142.336 148.48l-45.056 0.512v430.08c0 150.016 178.176 305.152 302.08 386.56 54.784 36.352 99.328 57.856 112.128 57.856s57.344-21.504 112.128-57.856c121.856-79.872 295.424-231.424 302.08-378.368 0 0 0.512 0 0.512 0.512 0.512-2.56 0.512-5.632 0.512-8.192zM328.704 481.792l124.416 98.816 236.032-253.952 69.632 56.32-295.424 318.976-193.536-154.624 58.88-65.536z" fill="#35C21C" p-id="3662"></path>
  </svg>
);

export default GreenShieldIcon;
