import React from 'react';
import Colors from '../constants/ColorConstants';

const StarIcon = ({
  size = 24,
  color = '#ffb74d',
  viewBox = '0 0 24 24',
  ...props
}) => (

  <svg width={size} height={size} {...props} viewBox="0 0 25 25" >
    <polygon points="" style={{ fill: `url("https://www.sony.co.uk/store/product/xqdq54c0b.euk/Xperia-1-V-New-Exmor-T-for-mobile-sensor-and-4K-HDR-OLED-display#bv_inline_ratings_star_filled_2_99.99_QBZhAiFp5K")!important` }}></polygon>
    <path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style={{ fill: 'fill: url(https://www.sony.co.uk/store/product/xqdq54c0b.euk/Xperia-1-V-New-Exmor-T-for-mobile-sensor-and-4K-HDR-OLED-display#bv_inline_ratings_star_filled_2_99.99_QBZhAiFp5K) !important' }} fill={color}></path>
    <defs><linearGradient id="bv_inline_ratings_star_filled_2_99.99_QBZhAiFp5K" x1="99.99%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{ stopColor: 'rgb(255, 183, 77)', stopOpacity: 1 }}></stop><stop offset="1%" style={{ stopColor: 'rgb(189, 189, 189)', stopOpacity: 1 }}></stop></linearGradient></defs></svg>
);

export default StarIcon